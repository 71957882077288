import { ON_ERROR, COUNTRYFLAGS_LISTS } from "../../constants/action-types";
import { getCountryFlagsHttp } from "../https";



export const getCountryFlagList = (payload: any, history: any) => {

  return (dispatch: any) => {
    return getCountryFlagsHttp(payload).then(
      (res: any) => {
        // console.log("project list res", res);
		
        if (res && res.items && res.total) {
          dispatch({ type: COUNTRYFLAGS_LISTS, payload: res });
          return true;
        }
      },
      (error) => {
        // console.log("project list error", error);
        dispatch({ type: ON_ERROR, payload: error });
      }
    );
  };
};
