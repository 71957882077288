import styled from "styled-components";
import {
  FontSize12,
  FontSize16,
  Light,
  Line,
  Neutral,
  Orange,
  Primary,
  White,
  SteelGrey
} from "../../../Styles";

const AqProjectWrapper = styled.div`
  height: 100vh;
  background-color: ${White};
  display: flex;

  .left-panel {
    width: 250px;
    border: 1px solid #dcdcdc;
    transition: all 0.2s ease-in-out;
    text-align: center;
    padding: 60px 4px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &.in-active {
      margin-left: -251px;
    }

    ul,
    li {
      padding: 0;
      margin: 0 auto;
      list-style: none;
      font-size: 14px;
      position: relative;
    }

    li {
      padding: 5px 5px 5px 30px;
      text-align: left;

      &:hover {
        text-decoration: underline;
      }
    }
    .user-img {
      .no-img {
        height: 64px;
        width: 64px;
        background-color: ${Light};
        border-radius: 50%;
        margin: 0 auto;
        color: ${White};
        line-height: 64px;
        text-align: center;
        text-transform: uppercase;
        font-size: x-large;
      }
    }

    .icon-user:before {
      content: url(${process.env.PUBLIC_URL + "/icons/user.svg"});
      position: absolute;
      left: 8px;
    }
    .icon-projects:before {
      content: url(${process.env.PUBLIC_URL + "/icons/projects.svg"});
      position: absolute;
      left: 8px;
    }
    .icon-help:before {
      content: url(${process.env.PUBLIC_URL + "/icons/help.svg"});
      position: absolute;
      left: 8px;
    }
    .icon-setting:before {
      content: url(${process.env.PUBLIC_URL + "/icons/setting.svg"});
      position: absolute;
      left: 8px;
    }
    .icon-logout:before {
      content: url(${process.env.PUBLIC_URL + "/icons/logout.svg"});
      position: absolute;
      left: 8px;
    }
    .user-links {
      flex: 1;
    }
    .left-panel-footer {
    }
  }

  .right-panel {
    flex: 1;
    padding: 24px 50px;
    background-color: #f5f7fd;
    overflow: auto;

    .aq-project-header {
      display: flex;
      align-items: center;

      .mobile-view-profile {
        display: none;
      }
      .aq-bread-menu {
        padding: 8px;
        cursor: pointer;
        margin-right: 30px;
        border-radius: 4px;

        &:active {
          background-color: ${Line};
        }

        div {
          width: 24px;
          margin-bottom: 3px;
          height: 3px;
          background-color: ${Primary};
        }
      }
    }
  }

  .aq-search {
    height: 36px;
    background-image: url(${process.env.PUBLIC_URL + "/icons/search.svg"});
    background-repeat: no-repeat;
    background-position: calc(100% - 14px) center;
    width: 370px;
    border-radius: 120px;
    padding: 0 36px 0 20px;
    border: 1px solid #dcdcdc;
    box-sizing: border-box;
    outline: none;
  }

  .aq-toolbar {
    display: flex;
    align-items: center;
    margin: 15px 0;
  }

  .aq-icon-dashboard {
    background-image: url(${process.env.PUBLIC_URL +
    "/icons/dashboard-view.svg"});
    background-repeat: no-repeat;
    height: 16px;
    width: 16px;
    margin: 0 16px;
  }
  .aq-icon-list {
    background-image: url(${process.env.PUBLIC_URL + "/icons/list-view.svg"});
    background-repeat: no-repeat;
    height: 16px;
    width: 16px;
  }

  .disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }

  .project-title {
    font-size: 24px;
    font-family: "Rubik-Medium";
    color: ${Primary};
  }

  .aq-vessel-cell {
    position: relative;
    cursor: pointer;
  }
  .aq-action-cell {
    position: relative;
    cursor: pointer;
  }

  .aq-vessel-action-wrapper {
  }

  .aq-action-wrapper {
    padding: 0;
    margin: 0;
    position: absolute;
    border: 1px solid ${Line};
    background: ${White};
    border-radius: 4px;
    width: 190px;
    z-index: 1;
    right: 0;
    box-shadow: 1px 1px 6px 1px ${Line};

    .popup-hanger {
      position: absolute;
      width: 10px;
      height: 10px;
      background: #ffffff;
      transform: rotate(45deg) translateX(-5px);
      left: auto;
      right: 5px;
      box-shadow: -1px -1px 2px 0px #dedede;
      z-index: -1;
    }

    li {
      list-style: none;
      padding: 8px 5px 8px 20px;
      font-size: ${FontSize12};
      cursor: pointer;

      &:hover {
        background-color: ${Line};
      }
      &:active {
        background-color: ${Light};
        color: ${White};
      }
    }

    &.left {
      right: auto;
      left: 50%;
      transform: translateX(-50%);

      .popup-hanger {
        left: 50%;
        right: auto;
      }

      li {
        padding: 8px;
      }
    }
  }

  .aq-icon-calender:before {
    content: url(${process.env.PUBLIC_URL + "/icons/date.png"});
    position: relative;
    left: -8px;
    top: 3px;
  }
  .aq-icon-upload:before {
    content: url(${process.env.PUBLIC_URL + "/icons/upload.svg"});
    position: relative;
    left: -8px;
    top: 3px;
  }
  .aq-icon-document:before {
    content: url(${process.env.PUBLIC_URL + "/icons/document.svg"});
    position: relative;
    left: -8px;
    top: 4px;
  }
  .aq-icon-clock:before {
    content: url(${process.env.PUBLIC_URL + "/icons/clock.svg"});
    position: relative;
    left: -8px;
    top: 5px;
  }
  .aq-icon-download:before {
    content: url(${process.env.PUBLIC_URL + "/icons/download-color.svg"});
    position: relative;
    left: -8px;
    top: 5px;
  }

  // .aq-ds-tbl {
  //   background-color: ${White};
  // }

  .aq-grid {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin: 0 -10px;

    .flex-between {
      display: flex;
      justify-content: space-between;
      margin: 1em 0;
    }

    .aq-grid-cell {
      border: 1px solid ${Line};
      background-color: ${White};
      border-radius: 6px;
      box-sizing: border-box;
      margin: 0 10px 20px;
      width: calc(33.333% - 20px);

      .header {
        padding: 12px 16px 6px 16px;
        background-color: ${SteelGrey}
      }
      
      .body {
        padding: 6px 16px 12px 16px;
      }
    }

    .aq-id-cell {
      border: 1px solid ${Primary};
      background-color: ${Primary};
      color: ${White};
      border-radius: 20px;
      padding: 4px 20px;
      font-size: 14px;
    }
    .meet-link {
      border: 1px solid ${Primary};
      background-color: ${SteelGrey};
      color: ${Primary};
      border-radius: 20px;
      padding: 2px 14px;
      font-size: 14px;
      text-decoration: none;
      white-space: nowrap;
      cursor: pointer;
      
      &:before {
        content: url(${process.env.PUBLIC_URL + "/icons/date.png"});
        position: relative;
        left: -5px;
        top: 2px;
      }
      &:hover {
        background-color: ${Primary};
        color: ${White};
      }
    }
    .aq-status {
      margin-right: 5px;

      &.s-open:before {
        content: " ";
        background-image: url(${process.env.PUBLIC_URL +
        "/icons/check-circle.svg"});
        background-size: 12px;
        background-repeat: no-repeat;
        width: 16px;
        height: 12px;
        display: inline-block;
      }
      &.s-close:before {
        content: " ";
        background-image: url(${process.env.PUBLIC_URL + "/icons/close.svg"});
        background-size: 12px;
        background-repeat: no-repeat;
        width: 16px;
        height: 12px;
        display: inline-block;
      }
    }
    .rt-sm-tool {
      position: relative;
    }

    .aq-grid-row-2 {
      font-size: 16px;
      font-family: "Rubik-Medium";
      color: ${Primary};
    }
  }

  .aq-grid-row-3 {
    margin: 30px 0;
    font-size: ${FontSize12};

    .aq-rect {
      width: 12px;
      height: 12px;
      background-color: ${Light};

      &.loan {
        background-color: ${Orange};
      }
      &.cost {
        background-color: ${Neutral};
      }
    }

    .aq-badge-title {
    }

    .aq-grid-col-value {
      font-family: "Rubik-Medium";

      &.highlight {
        color: ${Neutral};
        font-size: ${FontSize16};
      }
    }
  }

  .aq-actions-dot {
    top: -2px;
    position: relative;

    .aq-more {
      cursor: pointer;

      span {
        border-radius: 50%;
        height: 4px;
        width: 4px;
        background-color: ${Primary};
        display: inline-block;
        margin-right: 3px;
      }
    }
  }

  .tt {
    border: 1px solid ${Primary};
    background-color: ${Primary};
    color: ${White};
    border-radius: 20px;
    padding: 2px 20px;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  .title-margin {
    margin: 36px 0 24px 0;
  }

  @media screen and (max-width: 677px) {
    .left-panel {
      // display: none;
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      background: white;
      box-shadow: 2px 6px 10px #c5c5c5;
      transition: all 0.4s ease-in-out;
    }

    .right-panel {
      padding: 22px;

      .aq-project-header {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

        .aq-bread-menu {
          float: right;
          margin: 0;
        }
      }

      .aq-toolbar {
        .aq-icons-container {
          display: none;
        }
      }

      .aq-grid {
        margin: 0;
        .aq-grid-cell {
          width: auto;
          margin: 0 0 20px;
          flex: 1;
          min-width: 100%;
        }
      }

      .aq-search {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 1100px) {
    .aq-grid .aq-grid-cell {
      width: calc(50% - 20px);
    }
  }

  @media screen and (max-width: 677px) {
    [class*="span-"] {
      width: 100%;
    }
    .pr-1 {
      padding: 0;
    }
    .right-panel {
      .aq-project-header {
        flex-direction: column;
        > div {
          width: 100%;

          &.aq-bread-menu {
            align-self: flex-end;
            width: 25px;
          }
          &.mobile-view-profile {
            display: block;
            text-align: center;
            .user-img .no-img {
              height: 64px;
              width: 64px;
              background-color: #747474;
              border-radius: 50%;
              margin: 0 auto;
              color: ${White};
              line-height: 64px;
              text-align: center;
              text-transform: uppercase;
              font-size: x-large;
            }
          }
        }
      }
    }
  }
`;
export default AqProjectWrapper;
