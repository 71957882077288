import styled from 'styled-components';
import { BorderRadius, DarkBlue, H1Size, Light, Line, White } from '../../Styles';


export default styled.div`
  .signup-form {
    width: 100%;
    background-color: ${DarkBlue};
    text-align: center;
    height: 100%;
    padding-top: 100px;
    
    .aq-sub-title {
      margin-bottom: 10px;
    }
    
    .bg-1 {
      position: absolute;
      background-image: url(${process.env.PUBLIC_URL + '/icons/group_149.png'});
      background-repeat: no-repeat;
      width: 120px;
      height: 120px;
      left: 44px;
      top: -45px;
    }
    
    .bg-2 {
      position: absolute;
      background-image: url(${process.env.PUBLIC_URL + '/icons/group_148.png'});
      background-position: -85px -120px;
      background-repeat: no-repeat;
      width: 135px;
      height: 136px;
      left: -65px;
      top: 220px;
      } 
      
    .bg-3 {
      position: absolute;
      background-image: url(${process.env.PUBLIC_URL + '/icons/group_149.png'});
      background-repeat: no-repeat;
      width: 120px;
      height: 120px;
      right: -53px;
      bottom: 110px;
    }
    
    .bg-cover {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 90px 22px 55px;
      background-color: ${White};
      border-radius: 5px;
    }
    
    
    .icon-post {
      background-image: url(${process.env.PUBLIC_URL + '/icons/post.png'});
      display: inline-block;
      width: 20px;
      height: 13px;
      background-repeat: no-repeat;
      margin-right: 3px;
    }
    
    .panel {
      max-width: 405px;
      width: 100%;
      height: 600px;
      margin: 0 auto;
      background: ${White};
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: ${BorderRadius};
      position: relative;
      
      .h1 {
        font-size: ${H1Size};
      }
      
      .field-wrapper {
        &.flex {
          display: flex;
        }
        
        &.wrap {
          flex-wrap: wrap;
        }
      }
      
      .footer {
        border-top: 1px solid ${Line};
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        font-size: 16px;
        color: ${Light}
      }
      
      .footer-txt {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .safe-shield {
        background-image: url(${process.env.PUBLIC_URL + '/icons/vector.png'});
        background-repeat: no-repeat;
        width: 20px;
        height: 22px;
        display: inline-block;
        background-size: 20px;
        margin-right: 5px;
      }
    }
  }
  
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }
  
  @media screen and (max-width: 677px) {
    .signup-form {
      padding: 0;
      height: 100vh;
      
      .panel {
        height: 100%;
        width: 100%;
        max-width: 100%;
      }
      
      .bg-cover {
        padding: 22px;
      }
      
      .bg-1, .bg-2, .bg-3 {
        display: none;
      }
      
      .footer {
        background-color: #DFE6F4;
      }
    }
    
    
    .desktop {
      display: none;
    }
    .mobile {
      top: 0;
      left: 0;
      right: 0;
      margin-bottom: 75px;
      display: flex;
      
      .back-arrow {
        background-image: url(${process.env.PUBLIC_URL + '/icons/back-arrow.svg'});
        background-repeat: no-repeat;
        width: 20px;
        height: 22px;
        cursor: pointer;
        position: absolute;
      }
      
      .title {
        flex: 1;
        margin: 0;
      }
      
    }  
  }
`