import { IRootState, ISavedState } from "../models";

const RootState: IRootState = {
  vessels: [{ title: "app" }],
  companyExperience: {},
};

const VesselsState = {
  carriers: [],
  tankers: [],
  chemicals: [],
  containers: [],
  others: [],
  selected: {
    context: "",
  },
  progress: 0,
};

const DashboardState = {
  page: "type",
  header: {
    title: "We need few details about your company",
    actions: [],
    steps: [
      { label: "Company Info", selected: true, id: 1 },
      {
        label: "Project Details",
        id: 2,
        steps: [{ selected: true }, { selected: false }],
      },
      { label: "Business Info", id: 3 },
    ],
  },
  projects: {},
  users: {},
};
const SavedState: ISavedState = {
  vessels: [],
};

export { RootState, VesselsState, DashboardState, SavedState };
