import React, { PureComponent } from 'react';

import { AqButton, AqForm, AqInput } from '../seeds/index';
import SignupStyles from './signup.styles';

class OtpSignupComponent extends PureComponent<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      userEmail: sessionStorage.getItem('userEmail'),
      title: 'Verify',
      hasError: false,
      formState: undefined,
      timeToResend: 10,
      otpSentCount: 0,
      otpDuration: 60,
      otpMaxTry: 3,
      inputStyle: {marginRight: 10, textAlign: 'center'},
      initialValues: {},
      fields: {
        one: {name: 'one', required: true},
        two: {name: 'two', required: true},
        three: {name: 'three', required: true},
        four: {name: 'four', required: true},
        five: {name: 'five', required: true},
      }
    }
  }

  /**
   * Initiates the OTP
   */
  componentDidMount(): void {
    // this.triggerOtp();
  }

  /**
   * Triggers the OTP flow
   */
  triggerOtp = () => {
    this.setState({otpSentCount: this.state.otpSentCount + 1});
    const intervalRef = setInterval(() => {
      console.log('interval called ', intervalRef);
      let {timeToResend, otpSentCount, otpMaxTry} = this.state;
      if (timeToResend < 2) {
        clearTimeout(intervalRef);
        if (otpSentCount < otpMaxTry) {
          timeToResend = this.state.otpDuration;
          otpSentCount++;
          this.triggerOtp();
        } else {
          this.log(`[OTP failure] OTP has reached to max count: ${otpSentCount} retry`);
        }
      }
      this.setState({timeToResend: timeToResend - 1, otpSentCount})
    }, 1000)
  }


  log = (msg: string) => {
    console.log('[INFO]: ', msg);
  }

  /**
   * Handles the validations on fields
   * @param field
   * @param value
   */
  handleValidation = (field: any, value: any) => {
    if (field.required && !value) {
      this.setState({hasError: true});
      return {
        error: true
      }
    }
    this.setState({hasError: false});
    return undefined;
  }

  /**
   * handles form submit event
   * @param formValue
   */
  handleSubmit = (formValue: any) => {
    console.log('handle submit called ', formValue);
    this.props.history.push('/boarding');
  }


  handleChange = (data: any) => {
    const {value, fieldName} = data;
    if (value && value.length === 1) {
      const nextElm = this.getNxtInputId(fieldName);
      if (!!nextElm) {
        const elm = document.getElementById(nextElm);
        console.log('move to next ', document.getElementById(nextElm));
        if (elm) {
          elm.focus();
        }
      } else {
        const currElm = document.getElementById(fieldName);
        currElm && currElm.blur();
      }
    }
  }

  /**
   * Returns the next input id
   * @param id
   */
  getNxtInputId(id: string): string {
    switch (id) {
      case 'one':
        return 'two';
      case 'two':
        return 'three';
      case 'three':
        return 'four';
      case 'four':
        return 'five';
      default :
        return 'btn-submit-otp';

    }
  }

  /**
   * Populates and return the fields template
   * @param state
   */
  getFields = (state: any) => {
    const {formState, onFocus, onBlur, onChange} = state;
    const {fields, inputStyle} = this.state;
    const initialValues = formState;
    return <div className={'field-wrapper flex'}>
      {Object.keys(fields).map((key: any, index: number) => {
        if (!fields[key].hidden) {
          return (
            <AqInput key={`${index}_input`}
                     inputStyle={inputStyle}
                     maxLength={1}
                     name={fields[key].name || key}
                     placeholder={fields[key].placeholder}
                     meta={fields[key].meta}
                     value={initialValues[key] && initialValues[key].value}
                     error={initialValues[key] && initialValues[key]._errors}
                     onFocus={onFocus}
                     onBlur={onBlur}
                     type={fields[key].type}
                     onChange={onChange}/>)
        }
      })}
    </div>
  }

  /**
   * Custom renderer for Form component
   * @param state
   */
  renderer = (state: any = {}) => {
    const {formState, onSubmit} = state;
    if (!formState) {
      return null;
    }
    return <div>
      {this.getFields(state)}
      {this.state.hasError && <span className={'aq-error-txt'}>*Please enter OTP</span>}
      <AqButton label={'Verify'} onClick={onSubmit} id={'btn-submit-otp'}/>
      <p>Resend OTP in <span className={'aq-color-txt'}>{this.state.timeToResend}s</span></p>
    </div>
  }

  /**
   * Returns the form template
   */
  getForm() {
    const {initialValues, userEmail} = this.state;
    const fields = this.state.fields;
    return <div id={'verify'}>
      {this.getTitle()}
      <div className={'aq-sub-title'}><span className={'icon-post'}/>{userEmail}</div>
      <div className={'aq-sub-title'}>Please enter the verification code (OTP) sent to your mobile number</div>
      <AqForm
        submitTxt={'Verify'}
        onSubmit={this.handleSubmit}
        fields={fields}
        onFieldChange={this.handleChange}
        initialValues={initialValues}
        validator={this.handleValidation}
        renderer={this.renderer}
      /></div>
  }


  goBack = () => {
    this.props.history.goBack();
  }

  /**
   * Returns title elm
   */
  getTitle = () => {
    const {title} = this.state;
    return <>
      <div className={'mobile'}>
        <div className={'back-arrow'} onClick={this.goBack}></div>
        <h1 className={'title'}>{title}</h1>
      </div>
      <h1 className={'desktop'}>{title}</h1>
    </>
  };

  /**
   * Default render fn
   */
  render() {
    return <SignupStyles>
      <div className={'signup-form'}>
        <div className={'panel'}>
          <div className={'bg-1'}/>
          <div className={'bg-2'}/>
          <div className={'bg-3'}/>
          <div className={'bg-cover'}>
            {this.getForm()}
            <div className={'footer'}>
              <p className={'footer-txt'}>
                <span className={'safe-shield'}></span>Secure Login
              </p>
            </div>
          </div>
        </div>
      </div>
    </SignupStyles>
  }
}

export default OtpSignupComponent;
