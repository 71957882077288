import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import { AqFooter } from "../../../components/footer/aq-footer";
import AqDateComponent from "../../../components/seeds/aq-date.component";
import AqFormComponent from "../../../components/seeds/aq-form.component";
import AqInput from "../../../components/seeds/aq-input.component";
import AqRadioComponent from "../../../components/seeds/aq-radio.component";
import AqSelectComponent from "../../../components/seeds/aq-select.component";
import actionDispatcher from "../../../controller/actions";
import {
  addVesselEmploymentHttp,
  getVesselOpexHttp,
} from "../../../controller/https";
import vessels from "../../../controller/reducers/vessels";
import { getSelectedVessel } from "../../../helpers/utils";
import {
  BgDark,
  BgLight,
  FontSize18,
  FontSize12,
  Hint,
  Light,
  Line,
  NegativeChange,
  Neutral,
  Orange,
  White,
  Primary,
} from "../../../Styles";
import AqVesselEmploymentForm from "./aq-vessel-employment-form";
import AqVesselTblComponent from "./aq-vessel-tbl.component";
import {
  currancyToNumber,
  replaceDollarcurrancyToNumber,
  formatNumberWithCurrency,
  strictValidArrayWithLength,
} from "../../../controller/utils/commonUtils";

const ComponentWrapper = styled.div`
  text-align: left;
  margin: 0 auto;
  overflow-x: hidden;

  .aq-vessel-info-header {
    display: flex;
    background-color: ${BgDark};
    height: 188px;
    padding: 40px 20px;
    box-sizing: border-box;
    margin-top: 20px;
    .vessel-box-mob {
      display: none;
    }

    .imo-number-txt {
      font-family: "Rubik-Light";
    }

    [class^="vessel-box-"] {
      flex: 1;
      padding: 0 0 0 16px;
      text-align: left;
      line-height: 24px;
      font-family: "Rubik-Medium";
      font-size: 14px;

      div {
        font-size: 12px;
      }

      span {
        display: block;
        height: 10px;
        width: 10px;
      }
    }

    .vessel-box-1 {
      text-align: center;
    }

    .vessel-box-2 {
      border-left: 1px solid ${Hint};

      span {
        background-color: ${Neutral};
      }
    }

    .vessel-box-3 {
      span {
        background-color: ${Light};
      }
    }

    .vessel-box-4 {
      span {
        background-color: ${Orange};
      }
    }

    .vessel-box-5 {
      span {
        background-color: ${NegativeChange};
      }
    }

    [class^="aq-vessel-icon-"] {
      padding: 42px;
      background-position: center 5px;
      background-repeat: no-repeat;
      background-size: 80px;
    }

    .aq-vessel-icon-0 {
      background-image: url(${process.env.PUBLIC_URL +
      "/icons/ship/carrier.svg"});
    }

    .aq-vessel-icon-1 {
      background-image: url(${process.env.PUBLIC_URL +
      "/icons/ship/tanker.svg"});
    }

    .aq-vessel-icon-2 {
      background-image: url(${process.env.PUBLIC_URL +
      "/icons/ship/chemical.svg"});
    }

    .aq-vessel-icon-3 {
      background-image: url(${process.env.PUBLIC_URL +
      "/icons/ship/cargo.svg"});
    }

    .aq-vessel-icon-4 {
      background-image: url(${process.env.PUBLIC_URL +
      "/icons/ship/other.svg"});
    }

    @media screen and (max-width: 677px) {
      height: auto;
      padding: 10px;
      box-sizing: border-box;

      .aq-vessel-info-header {
        margin-top: 0;
      }

      [class^="vessel-box-"] {
        display: none;
        > div {
          line-height: initial;
        }
      }
      .vessel-box-mob {
        display: flex;
      }
      [class^="aq-vessel-icon-"] {
        padding: 1px 26px;
        margin-right: 11px;
        background-size: 48px;
        background-position: center;
      }
    }
  }

  .aq-action-cell {
    position: relative;
    cursor: pointer;
  }
  .aq-actions-dot {
    top: -2px;
    position: relative;

    .aq-more {
      cursor: pointer;

      span {
        border-radius: 50%;
        height: 4px;
        width: 4px;
        background-color: ${Primary};
        display: inline-block;
        margin-right: 3px;
      }
    }
  }
  .aq-action-wrapper {
    padding: 0;
    margin: 0;
    bottom: 100%;
    position: absolute;
    border: 1px solid ${Line};
    background: ${White};
    border-radius: 4px;
    width: 190px;
    z-index: 1;
    right: 0;
    box-shadow: 1px 1px 6px 1px ${Line};

    .popup-hanger {
      position: absolute;
      width: 10px;
      height: 10px;
      background: #ffffff;
      transform: rotate(225deg) translateX(-5px);
      left: auto;
      right: 5px;
      box-shadow: -1px -1px 2px 0px #dedede;
      z-index: -1;
      bottom: 0;
    }

    li {
      list-style: none;
      padding: 8px 5px 8px 30px;
      font-size: ${FontSize12};
      cursor: pointer;
      position: relative;

      &:hover {
        background-color: ${Line};
      }
      &:active {
        background-color: ${Light};
        color: ${White};
      }
      &.aq-icon span {
        position: absolute;
        left: 3px;
        top: 1px;
      }
    }

    &.left {
      right: auto;
      left: 50%;
      transform: translateX(-50%);

      .popup-hanger {
        left: 50%;
        right: auto;
      }

      li {
        padding: 8px;
      }
    }
  }

  .aq-vd-form-container {
    display: block;

    .aq-vd-opex-form {
      h2 {
        margin: 24px 0 16px;
      }

      .aq-vd-form-block-1 {
        display: flex;

        .aq-vd-left-block {
          flex: 1;
          margin-right: 20px;
        }

        .aq-vd-right-block {
          flex: 1;
          padding: 32px 20px 20px;

          .aq-vd-right-block-content {
            border: 1px solid ${Line};
            border-radius: 4px;
            padding: 20px 0 20px 20px;
            position: relative;
          }
        }
      }

      .aq-vd-form-block-2 {
        padding: 8px 0;

        .aq-vd-block-2-content {
          border: 1px solid ${Line};
          padding: 20px 0 20px 20px;
          box-sizing: border-box;
          border-radius: 4px;
          margin: 20px 0 0 0;
          position: relative;
        }
      }

      .aq-vd-form-block-3 {
        padding: 20px 1px;
      }
    }
  }

  .aq-vd-field-footer {
    padding: 10px 10px 10px 40px;
    background-color: ${BgLight};
    border-radius: 6px;
    background-image: url(${process.env.PUBLIC_URL +
    "/icons/exclamation-circle.svg"});
    background-repeat: no-repeat;
    background-position: 13px center;
    text-align: left;
    margin-bottom: 20px;
    margin-top: -10px;
    font-size: 12px;
    font-family: "Rubik-Light";
  }

  .aq-vd-rd {
    label {
      font-family: "Rubik-Light";
      font-size: 14px;
    }
  }

  .aq-vd-title {
    font-size: ${FontSize18};
    position: absolute;
    top: -9px;
    background-color: ${White};
    padding: 0 5px 0 1px;
  }

  .aq-vd-emp-form-overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.25);
  }

  @media screen and (max-width: 677px) {
    padding: 10px;
    padding-bottom: 55px;
    overflow: visible;

    .aq-vd-form-container {
      .aq-vd-opex-form {
        .aq-vd-form-block-1 {
          display: block;

          .aq-vd-left-block {
            margin: 0;
          }

          .aq-vd-right-block {
            padding: 0;

            .aq-vd-right-block-content {
              padding: 10px;
              width: 100%;
            }
          }
        }
        .aq-vd-form-block-2 .aq-vd-block-2-content {
          padding: 20px;
        }
      }
    }

    .aq-txt-l.flex,
    .aq-vd-right-block-content .flex,
    .aq-vd-block-2-content,
    .aq-vd-block-2-content .flex,
    .aq-vd-block3-content,
    .aq-vd-block3-content + br + .flex,
    .aq-tbl-header.flex,
    .aq-tbl-header.flex > .flexible.flex {
      flex-direction: column;
    }
  }
`;

class AqVesselDetailsComponent extends Component<any, any> {
  opexForm = {
    initialValues: { managementType: "Inhouse", vesselEmployment: "estimated" },
    fields: {
      opexAmount: {
        name: "opexAmount",
        label: "How much is the daily operating expense ?",
        placeholder: "OPEX Amount",
        meta: { icon: "currency", unit: "Per day" },
        min: 0,
        max: 12000,
        required: true,
        footer: "Daily OPEX to include insurance and management fee",
      },
      managementType: {
        type: "radio",
        name: "managementType",
        label: "Commercial Management",
        options: [
          { value: "Inhouse", label: "Inhouse", selected: true },
          {
            label: "Outsourced",
            value: "Outsourced",
          },
        ],
      },
      opexFee: {
        name: "opexFee",
        placeholder: "Fee Amount",
        label: "",
        min: 0,
        required: false,
        meta: { icon: "currency", unit: "Per day" },
      },
      implementationDate: {
        name: "implementationDate",
        label: "Date",
        type: "date",
        placeholder: "Select date",
        // required: true,
      },
      implementationBudget: {
        name: "implementationBudget",
        label: "Budget",
        meta: { icon: "currency" },
        // required: true,
      },
      intermediateSurveyDate: {
        name: "intermediateSurveyDate",
        label: "Date",
        type: "date",
        placeholder: "Select date",
        // required: true,
      },
      intermediateSurveyBudget: {
        name: "intermediateSurveyBudget",
        label: "Budget",
        meta: { icon: "currency" },
        // required: true,
      },
      specialSurveyDate: {
        name: "specialSurveyDate",
        type: "date",
        placeholder: "Select date",
        label: "Date",
        // required: true,
      },
      specialSurveyBudget: {
        name: "specialSurveyBudget",
        label: "Budget",
        meta: { icon: "currency" },
        // required: true,
      },
      vesselEmployment: {
        name: "vesselEmployment",
        label:
          "We need an estimate or detailed charter for vessel employment ?",
        type: "radio",
        options: [
          {
            value: "Detailed",
            label: "Yes, I already have detailed employment charter",
          },
          {
            value: "Estimate",
            label: "No, I currently only have an estimate",
          },
        ],
      },
      strategy: {
        name: "strategy",
        label: "Employment Strategy",
        type: "select",
        required: false,
        options: [
          { label: "Bareboat Charter", value: "Bareboat Charter" },
          { label: "Time Charter", value: "Time Charter" },
          { label: "Pool", value: "Pool" },
          { label: "Spot Charter", value: "Spot Charter" },
        ],
      },
      estimatedTime: {
        name: "estimatedTime",
        label: "Estimated Time Charter Equivalent (TCE/Day)",
        min: 1000,
        max: 350000,
        meta: { icon: "currency", unit: "Per day" },
        placeholder: "TCE Amount",
        required: false,
      },
    },
  };
  opexFormSubmit: any;
  projectId: any;
  vesselNo: any;

  constructor(props: any) {
    super(props);
    this.projectId = this.props.match.params.id;
    this.vesselNo = this.props.match.params.vesselNo;
    this.state = {
      showEmploymentTable: false,
      empTable: [],
    };
  }

  handleFooterAction = (ev: any) => {
    // console.log("continue btn ev", ev);
    if (ev === "back") {
      // this.props.history.goBack();
      this.props.history.push(`/projects/${this.projectId}/vessels/${this.vesselNo}/search`);
    } else {
      this.opexFormSubmit();
    }
  };

  componentDidMount(): void {
    const { vesselOpex, isValid } = this.props;
    if (isValid) {
      if (vesselOpex) {
        this.updateEmploymentTableToggle(
          vesselOpex.vesselEmployment === "Detailed"
        );
      }
      this.props.updatePage({
        name: "vessels",
        title: "Welcome: MSC - Global Shipping Company",
        showActions: true,
        step: 2,
        subStep: 2,
      });
    } else {
      // this.props.history.push('/profile/type');
    }
    getVesselOpexHttp(this.projectId, this.vesselNo).then(
      (response) => {
        if (response.error) {
          return this.setState({ error: response.error, fetched: true });
        }
        const vesselOpex = {
          opexAmount: response.opexAmountPerDay,
          managementType: response.commercialManagenentType || "Inhouse",
          opexFee: response.commercialManagenentFeeAmountPerDay,
          implementationDate:
            response.regulatoryImplementation.date &&
            new Date(response.regulatoryImplementation.date),
          implementationBudget: response.regulatoryImplementation.budget,
          intermediateSurveyDate:
            response.intermediateSurveyDryDock.date &&
            new Date(response.intermediateSurveyDryDock.date),
          intermediateSurveyBudget: response.intermediateSurveyDryDock.budget,
          specialSurveyDate:
            response.specialSurveyDryDock.date &&
            new Date(response.specialSurveyDryDock.date),
          specialSurveyBudget: response.specialSurveyDryDock.budget,
          vesselEmployment: response.charter?.type,
          strategy: response.charter?.employmentStrategy,
          estimatedTime: response.charter?.timeCharterEquivalentAmount,
        };
        this.setState({ vesselOpex, fetched: true }, () => {
          this.updateEmploymentTableToggle(
            vesselOpex.vesselEmployment === "Detailed"
          );
        });
      },
      (error) => {
        console.log("[OPEX ERROR ]", error);
      }
    );

    this.props.projectProgress(75);
  }

  getVesselInfoHeader = () => {
    const { vesselInfo, purposeInfo, selectedVesselCard } = this.props;
    if (vesselInfo && purposeInfo) {
      const { data } = vesselInfo;
      // console.log("purposeInfo", purposeInfo);
      return (
        <div className={"aq-vessel-info-header"}>
          <div className={"vessel-box-1"}>
            <label>{data.vesselName}</label>
            <div className={`aq-vessel-icon-${selectedVesselCard.type}`} />
            {data.imoNumber && (
              <div className={"imo-number-txt"}>
                IMO Number: {data.imoNumber}
              </div>
            )}
          </div>
          <div className={"vessel-box-2"}>
            <span />
            <div>{purposeInfo.data && purposeInfo.data.loanPurpouse}</div>
            <div>
              {purposeInfo &&
                purposeInfo.data &&
                formatNumberWithCurrency(purposeInfo.data.declaredValue)}
            </div>
          </div>
          <div className={"vessel-box-3"}>
            <span />
            <div>Built Yard</div>
            {data.buildYard}
          </div>
          <div className={"vessel-box-4"}>
            <span />
            <div>Flag Country</div>
            {data.flag}
          </div>
          <div className={"vessel-box-5"}>
            <span />
            <div>Build Date</div>
            {data.buildDate && moment(data.buildDate).format("DD-MMM-YYYY")}
          </div>
          <div className={"vessel-box-mob"}>
            <div className={`aq-vessel-icon-${selectedVesselCard.type}`} />
            <div className={`aq-vessel-label`}>
              <label>{data.Vesselname}</label>
              {data.imoNumber && (
                <div className={"imo-number-txt"}>
                  IMO Number: {data.imoNumber}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  /**
   * Handles the validations on fields
   * @param field
   * @param value
   */
  handleValidation = (field: any, value: any) => {
    if (/opexAmount|opexFee|estimatedTime/.test(field.name)) {
      let val = Number(currancyToNumber(value));
      if (val && (val > field.max || val < field.min)) {
        return {
          error: `Value must be between ${field.min} & ${field.max}`,
        };
      }
    }
    if (field.required && !value) {
      return {
        error: field.error || "field is required",
      };
    }
    return undefined;
  };

  onFormSubmit = (formData: any) => {
    console.log("formData", formData, this.state, this.props);
    const { showEmploymentTable, empTable } = this.state;
    const vesselOpex: any = {};
    Object.keys(formData.formState).forEach((key: string) => {
      vesselOpex[key] = formData.formState[key].value;
    });

    let currencyKeys = [
      "opexAmount",
      "opexFee",
      "implementationBudget",
      "intermediateSurveyBudget",
      "specialSurveyBudget",
      "estimatedTime",
    ];
    if (vesselOpex.managementType === "Inhouse") {
      vesselOpex.opexFee = "";
    }

    currencyKeys.forEach(
      (item) => (vesselOpex[item] = currancyToNumber(vesselOpex[item]))
    );

    if (showEmploymentTable && empTable && empTable.length === 0) {
      this.props.ShowError("Please add at least one Vessel Employment");
      return false;
    }

    let fixtypecastfspl = [
      "implementationBudget",
      "intermediateSurveyBudget",
      "specialSurveyBudget",
    ];

    fixtypecastfspl.forEach((item) => {
      if (vesselOpex[item]) {
      } else {
        vesselOpex[item] = null;
      }
    });

    this.props.submitVesselOpexForm(this.projectId, this.vesselNo, vesselOpex, this.props.history);
  };

  getOpexForm = () => {
    const opexForm = this.opexForm;
    const { vesselOpex, fetched } = this.state;
    if (!fetched) {
      return <div style={{ textAlign: "center" }}>Loading...</div>;
    }
    const initialValues = vesselOpex ? vesselOpex : opexForm.initialValues;

    return (
      <AqFormComponent
        id={"opexForm"}
        fields={opexForm.fields}
        initialValues={initialValues}
        onSubmit={this.onFormSubmit}
        validator={this.handleValidation}
        onFieldChange={this.onFieldChange}
        renderer={this.vesselFormFieldRenderer}
      />
    );
  };

  onFieldChange = (field: any) => {
    if (field.fieldName === "managementType") {
      this.opexForm.fields.opexFee.required =
        field.value.value === "Outsourced";
    } else if (field.fieldName === "vesselEmployment") {
      const val = field.value.value;
      const isDetailedSelected = val === "Detailed";
      this.updateRequireValue(!isDetailedSelected);
      this.updateEmploymentTableToggle(isDetailedSelected);
    }
  };

  updateEmploymentTableToggle = (val: boolean) => {
    this.setState({ showEmploymentTable: val });
  };

  updateRequireValue = (isRequired: boolean) => {
    this.opexForm.fields.strategy.required = isRequired;
    this.opexForm.fields.estimatedTime.required = isRequired;
  };

  vesselFormFieldRenderer = (state: any) => {
    const { formState, onFocus, onBlur, onChange, onSubmit } = state;
    if (!formState) {
      return null;
    }
    const { showEmploymentTable } = this.state;
    this.opexFormSubmit = onSubmit;
    const { fields } = this.opexForm;
    const initialValues: any = formState;
    const {
      opexAmount,
      estimatedTime,
      implementationBudget,
      implementationDate,
      intermediateSurveyBudget,
      intermediateSurveyDate,
      managementType,
      opexFee,
      specialSurveyBudget,
      specialSurveyDate,
      strategy,
      vesselEmployment,
    } = fields;
    return (
      <div className={"aq-vd-opex-form"}>
        <h2>OPEX and Employment</h2>
        <div className={"aq-vd-form-block-1"}>
          <div className={"aq-vd-left-block"}>
            <AqInput
              name={opexAmount.name}
              min={opexAmount.min}
              max={opexAmount.max}
              placeholder={opexAmount.placeholder}
              value={initialValues.opexAmount && initialValues.opexAmount.value}
              error={
                initialValues["opexAmount"] &&
                initialValues["opexAmount"]._errors
              }
              onFocus={onFocus}
              label={opexAmount.label}
              onBlur={onBlur}
              type={"number"}
              meta={opexAmount.meta}
              onChange={onChange}
              currancy
            />
            <div className={"aq-vd-field-footer"}>{opexAmount.footer}</div>

            <div className={"aq-txt-l flex"}>
              <label className={"flexible"}>{managementType.label}</label>
              <div className={"flex flexible"}>
                {managementType.options.map((option: any, i: number) => {
                  return (
                    <div key={`aq-vd-rd-${i}`} className={"flexible aq-vd-rd"}>
                      <AqRadioComponent
                        name={managementType.name}
                        value={option.value}
                        checked={
                          initialValues.managementType &&
                          initialValues.managementType.value === option.value
                        }
                        onChange={onChange}
                        label={option.label}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            {initialValues.managementType.value ===
              managementType.options[1].value && (
              <AqInput
                name={opexFee.name}
                min={opexFee.min}
                placeholder={opexFee.placeholder}
                value={initialValues.opexFee && initialValues.opexFee.value}
                // error={
                //   initialValues["opexFee"] && initialValues["opexFee"]._errors
                // }
                onFocus={onFocus}
                label={opexFee.label}
                onBlur={onBlur}
                type={"number"}
                meta={opexFee.meta}
                onChange={onChange}
                currancy
              />
            )}
          </div>
          <div className={"aq-vd-right-block flex"}>
            <div className={"aq-vd-right-block-content"}>
              <div className={"aq-vd-title"}>Regulatory Implementation</div>
              <div className={"flex"}>
                {initialValues.implementationDate && (
                  <AqDateComponent
                    className={"span-6"}
                    name={implementationDate.name}
                    label={implementationDate.label}
                    startDate={
                      initialValues.implementationDate &&
                      initialValues.implementationDate.value
                    }
                    minDate={new Date()}
                    placeholder={implementationDate.placeholder}
                    error={
                      initialValues.implementationDate &&
                      initialValues.implementationDate._errors
                    }
                    onBlur={onBlur}
                    onChange={onChange}
                  />
                )}
                <AqInput
                  name={implementationBudget.name}
                  className={"span-6"}
                  value={
                    initialValues.implementationBudget &&
                    initialValues.implementationBudget.value
                  }
                  error={
                    initialValues["implementationBudget"] &&
                    initialValues["implementationBudget"]._errors
                  }
                  onFocus={onFocus}
                  label={implementationBudget.label}
                  onBlur={onBlur}
                  type={"number"}
                  meta={implementationBudget.meta}
                  onChange={onChange}
                  currancy
                />
              </div>
            </div>
          </div>
        </div>

        <div className={"aq-vd-form-block-2"}>
          <label className={"big-label"}>
            Please provide maintenance schedule and budget
          </label>
          <div className={"flex aq-vd-block-2-content"}>
            <div className={"flex flexible"}>
              <div className={"aq-vd-title"}>Intermediate Survey/Dry Dock</div>
              {initialValues.intermediateSurveyDate && (
                <AqDateComponent
                  className={"span-6"}
                  name={intermediateSurveyDate.name}
                  label={intermediateSurveyDate.label}
                  startDate={
                    initialValues.intermediateSurveyDate &&
                    initialValues.intermediateSurveyDate.value
                  }
                  minDate={new Date()}
                  placeholder={intermediateSurveyDate.placeholder}
                  //   error={
                  //     initialValues.intermediateSurveyDate &&
                  //     initialValues.intermediateSurveyDate._errors
                  //   }
                  onBlur={onBlur}
                  onChange={onChange}
                />
              )}
              <AqInput
                name={intermediateSurveyBudget.name}
                className={"span-6"}
                value={
                  initialValues.intermediateSurveyBudget &&
                  initialValues.intermediateSurveyBudget.value
                }
                // error={
                //   initialValues["intermediateSurveyBudget"] &&
                //   initialValues["intermediateSurveyBudget"]._errors
                // }
                onFocus={onFocus}
                label={intermediateSurveyBudget.label}
                onBlur={onBlur}
                type={"number"}
                meta={intermediateSurveyBudget.meta}
                onChange={onChange}
                currancy
              />
            </div>
            <div className={"flex flexible"}>
              <div className={"aq-vd-title"}>Special Survey/Dry Dock</div>
              {initialValues.specialSurveyDate && (
                <AqDateComponent
                  name={specialSurveyDate.name}
                  className={"span-6"}
                  label={specialSurveyDate.label}
                  startDate={
                    initialValues.specialSurveyDate &&
                    initialValues.specialSurveyDate.value
                  }
                  minDate={new Date()}
                  placeholder={specialSurveyDate.placeholder}
                  //   error={
                  //     initialValues.specialSurveyDate &&
                  //     initialValues.specialSurveyDate._errors
                  //   }
                  onBlur={onBlur}
                  onChange={onChange}
                />
              )}
              <AqInput
                name={specialSurveyBudget.name}
                className={"span-6"}
                value={
                  initialValues.specialSurveyBudget &&
                  initialValues.specialSurveyBudget.value
                }
                // error={
                //   initialValues["specialSurveyBudget"] &&
                //   initialValues["specialSurveyBudget"]._errors
                // }
                onFocus={onFocus}
                label={specialSurveyBudget.label}
                onBlur={onBlur}
                type={"number"}
                meta={specialSurveyBudget.meta}
                onChange={onChange}
                currancy
              />
            </div>
          </div>
        </div>

        <div className={"aq-vd-form-block-3"}>
          <label className={"big-label"}>
            We need an estimate or detailed charter for vessel employment?
          </label>
          <div className={"flex aq-vd-block3-content"}>
            {vesselEmployment.options.map((option: any, i: number) => {
              // console.log("options", option, initialValues);
              return (
                <div key={`aq-vd-rd-bt-${i}`} className={"flexible aq-vd-rd"}>
                  <AqRadioComponent
                    name={vesselEmployment.name}
                    value={option.value}
                    checked={
                      initialValues.vesselEmployment &&
                      initialValues.vesselEmployment.value === option.value
                    }
                    onChange={onChange}
                    label={option.label}
                  />
                </div>
              );
            })}
          </div>
          <br />
          {!showEmploymentTable && (
            <div className={"flex"}>
              <AqSelectComponent
                name={strategy.name}
                className={"span-6"}
                value={initialValues.strategy && initialValues.strategy.value}
                error={
                  initialValues["strategy"] && initialValues["strategy"]._errors
                }
                options={strategy.options}
                onChange={onChange}
                label={strategy.label}
              />
              <AqInput
                name={estimatedTime.name}
                className={"span-6"}
                min={estimatedTime.min}
                max={estimatedTime.max}
                value={
                  initialValues.estimatedTime &&
                  initialValues.estimatedTime.value
                }
                error={
                  initialValues["estimatedTime"] &&
                  initialValues["estimatedTime"]._errors
                }
                onFocus={onFocus}
                label={estimatedTime.label}
                onBlur={onBlur}
                type={"number"}
                meta={estimatedTime.meta}
                onChange={onChange}
                currancy
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  addVesselEmployment = (formData: any, options: any = {}) => {
    if (formData.formState) {
      const fs = formData.formState;
      const payload = {
        vesselEmployment: fs.vesselEmployment.value,
        startDate: fs.startEndDate.value[0],
        endDate: fs.startEndDate.value[1],
        timeCharterEquivalentPerDayRate: Number(
          currancyToNumber(
            replaceDollarcurrancyToNumber(
              fs.timeCharterEquivalentPerDayRate.value
            )
          )
        ),
        charterer: fs.charterer.value,
        // contract: fs.contract.value,
        commissionPercent: fs.commissionPercent.value,
      };

      if (options.update) {
        addVesselEmploymentHttp(payload, options.employmentNo).then(
          (res) => {
            if (res.message) {
              this.onVesselFormCancel();
            } else if (res.error) {
            }
          },
          (error) => {
            console.log("[ERROR in EMPLOYMENT UPDATED] ", error);
          }
        );
      } else {
        this.props.addVesselEmployment(payload).then((res: any) => {
          this.onVesselFormCancel();
        });
      }
    }
  };

  enableVesselForm = (state: any) => {
    let empNo: any = 0;
    strictValidArrayWithLength(this.state.empTable) &&
      this.state.empTable.forEach((element: any) => {
        if (Number(element.employmentNo) > empNo) {
          empNo = Number(element.employmentNo);
        }
      });

    this.setState({
      showEmploymentForm: true,
      vesselEmploymentFormInitialValues: {
        employmentNo: empNo + 1,
      },
      ...state,
    });
  };

  onActionClick = (row: any, type: string) => {
    if (type === "delete") {
      // this.props.deleteVesselEmployment(row.id);
    } else if (type === "edit") {
      const formValue = {
        ...row,
        update: true,
        startEndDate: `${row.startDate} ${row.endDate}`,
      };
      this.enableVesselForm({ vesselEmploymentFormInitialValues: formValue });
    } else if (type === "contract") {
      console.log("CONTRACT action called ");
    }
  };

  getTableElm = () => {
    return (
      <AqVesselTblComponent
        getBackTabelData={(res: any) => this.setState({ empTable: res })}
        handleAction={this.onActionClick}
        handleAdd={this.enableVesselForm}
      />
    );
  };

  onVesselFormCancel = () => {
    this.setState({ showEmploymentForm: false });
  };

  getEmploymentForm = () => {
    if (!this.state.showEmploymentForm) {
      return null;
    }
    const { vesselEmploymentFormInitialValues = {} } = this.state;
    return (
      <div className={"aq-vd-emp-form-overlay"}>
        <AqVesselEmploymentForm
          id={vesselEmploymentFormInitialValues.id}
          update={vesselEmploymentFormInitialValues.id !== undefined}
          initialValues={vesselEmploymentFormInitialValues}
          onCancel={this.onVesselFormCancel}
          onSave={this.addVesselEmployment}
        />
      </div>
    );
  };

  render(): React.ReactNode {
    const { showEmploymentTable, showEmploymentForm } = this.state;
    if (this.props.isValid) {
      return (
        <ComponentWrapper>
          {this.getVesselInfoHeader()}
          <div className={"aq-vd-form-container"}>{this.getOpexForm()}</div>
          {showEmploymentTable && !showEmploymentForm && (
            <div>{this.getTableElm()}</div>
          )}
          <AqFooter progress={75} onButtonClick={this.handleFooterAction} />
          {this.getEmploymentForm()}
        </ComponentWrapper>
      );
    }
    return null;
  }
}

const mapStateToProps = (state: any) => {
  const selectedVessel = getSelectedVessel(state);
  return {
    ...selectedVessel,
    selectedVessel,
    companyInfo: state.vessel.companyInfo,
    vesselType: state.vessel.selected && state.vessel.selected.context,
    // isValid: state.vessel.selected && state.vessel.selected.id
    isValid: true,
  };
};
const mapDispatchToProps = { ...actionDispatcher };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AqVesselDetailsComponent);
