import React, { CSSProperties, PureComponent } from 'react';
import styled from 'styled-components';
import { BgLight2, FontSize14, LightDark, Line, Neutral, Orange, White } from '../../Styles';


interface IAqSliderProps {
  max: number;
  name: string;
  label?: string;
  min?: number;
  value?: number;
  isPercentage?: boolean;
  interval?: number;
  onChange: any;
  showTooltip?: boolean;
}

const SliderContainer = styled.div`
  position: relative;
  
  .aq-tooltip {
    width: 49px;
    height: 34px;
    background-color: ${LightDark};
    color: ${White};
    position: absolute;
    transform: translateX(-50%);
    top: -48px;
    border-radius: 8px;
    text-align: center;
    padding-top: 7px;
    box-sizing: border-box;
    font-size: ${FontSize14};
    transition: left 0.05s ease-in-out;
    
    .hanger:before {
      content: ' ';
      position: absolute;
      left: 21px;
      background-color: ${LightDark};
      width: 5px;
      height: 5px;
      bottom: -4px;
      border: 4px solid ${LightDark};
      border-top: 0;
      border-right: 0;
      transform: rotate(315deg);
    }
  }
  
  .aq-slider-value {
    color: ${Orange};
    margin-bottom: 3px;
    font-size: ${FontSize14};
  }
  
  .aq-slider-lane {
    position: relative;
    border: 1px solid ${Line};
    height: 12px;
    border-radius: 6px;
    
    .aq-slider-lane-interval {
      position: absolute;
      left: 0;
      height: 12px;
      right: 0;
      display: flex;
      z-index: 1;
      pointer-events: none;
      
      .aq-slider-divider {
        width: 1px;
        flex: 1;
        border-left: 1px solid ${Line};
        top: 0;
        border-left: 1px solid #DEDEDE;
        position: absolute;
        bottom: 0;
      }
    }
    
    .aq-slider-lane-labels {
      position: absolute;
      top: 20px;
      left: 0;
      right: 0;
      display: flex;
      
      .aq-slider-divider-label {
        flex: 1;
        margin-left: -8px;
        position: absolute;
        
        &:first-child {
          margin-left: 0;
        }
      }
      
      .aq-max-value {
        position: absolute;
        right: -2px;
      }
    }
    
    .aq-slider-lane-selected {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 0;
      border-radius: 6px;
      background-color: ${Neutral};
    }
    
    .aq-slider {
      position: absolute;
      z-index: 1;
      left: 0;
      top: -7px;
      width: 27px;
      height: 27px;
      border: 3px solid ${BgLight2};
      border-radius: 50%;
      box-sizing: border-box;
      background-color: ${Neutral};
      box-shadow: 0px 0px 2px 1px #b3b2b2;
      cursor: pointer;
      
      &:hover {
        transform: scale(1.15);
      }
      
      &:focus {
        outline: none;
      }
      &:active {
        outline: none;
        box-shadow: 1px 1px 5px 1px #949292;
        transform: scale(1.15);
      }
      
      .aq-slider-center {
        background: ${White};
        height: 4px;
        width: 4px;
        left: 3px;
        position: relative;
        border-radius: 50%;
      }
    }
   }
`;

class AqSliderComponent extends PureComponent<IAqSliderProps, any> {
  constructor(props: IAqSliderProps) {
    super(props);
    const max = props.max || 100;
    const min = props.min || 0;
    const perCenVal = this.getPercentageValue(props.value || 0, {max, min});
    this.state = {
      interval: props.interval || 10,
      value: props.value || 0,
      percentageValue: perCenVal,
      sliderActive: false,
      sliderLeft: props.value || 0,
      max: max,
      min: min,
      clickDownPageX: undefined,
      sliderRef: React.createRef(),
    };
  }

  getPercentageValue = (val: number, state?: any) => {
    const {min, max} = state || this.state;
    return Math.round((val - min) * (100 / (max - min)));
  }

  componentDidMount(): void {
    const {sliderRef} = this.state;
    const {value, max} = this.props;
    if (sliderRef && sliderRef.current && sliderRef.current.offsetWidth) {
      const perCenVal = this.getPercentageValue(value || 0);
      const sliderLeft = perCenVal * (sliderRef.current.offsetWidth / 100);
      this.setState({sliderLeft})
    }
  }

  handleMouseDown = (ev: any) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState({sliderActive: true, clickDownPageX: ev.clientX - this.state.sliderLeft}, () => {
      window.addEventListener('mousemove', this.handleMouseMove);
      window.addEventListener('mouseup', this.handleKeyUp);
    });
  }

  handleKeyUp = () => {
    window.removeEventListener('mousemove', this.handleMouseMove);
    this.setState({sliderActive: false}, () => {
      window.removeEventListener('mouseup', this.handleKeyUp);
    })
  }

  handleMouseMove = (ev: any) => {
    ev.preventDefault();
    ev.stopPropagation();
    const {sliderRef, sliderActive, max, min} = this.state;
    if (sliderActive) {
      let posX = ev.clientX - this.state.clickDownPageX;
      let value = this.state.value;
      if (sliderRef && sliderRef.current && posX > sliderRef.current.offsetWidth) {
        posX = sliderRef.current.offsetWidth;
      } else if (posX < 0) {
        posX = 0;
      }
      if (sliderRef && sliderRef.current) {
        const range = max - min;
        value = Math.round(posX / (sliderRef.current.offsetWidth / range));
      }

      value += min;
      const percentage = this.getPercentageValue(value);
      this.setState({
        sliderLeft: posX,
        value: value,
        percentageValue: percentage
      }, () => {
        this.props.onChange(this.props.name, {value: value, percentage: percentage})
      });
    }
  };

  getIntervalLabels = () => {
    const {interval, min, max} = this.state;
    let range = min;
    const elms = [];
    const validRange = max - min;
    const blocks = validRange / interval;
    const leftOffset = 100 / blocks;
    let i = 0;
    while (range < max) {
      elms.push(<span key={`divider_label_${range}`}
                      className={'aq-slider-divider-label'} style={{left: `${i * leftOffset}%`}}>{range}</span>);
      range += interval;
      i++;
    }
    return elms;
  }

  getIntervalBlocks = () => {
    const {interval, max, min} = this.state;
    let range = min;
    const elms = [];
    const validRange = max - min;
    const blocks = validRange / interval;
    const leftOffset = 100 / blocks;
    let i = 0;
    while (range < max) {
      elms.push(<span key={`divider_${range}`} style={{left: `${i * leftOffset}%`}} className={'aq-slider-divider'}/>);
      range += interval;
      i++;
    }
    return elms;
  }

  getStyle = () => {
    const {sliderLeft} = this.state;
    const style: CSSProperties = {};
    let posX = sliderLeft - 13;
    style.transform = `translateX(${Math.max(-13, posX)}px)`;
    return style;
  }

  render() {
    const {percentageValue, sliderRef, max, sliderActive, value} = this.state;
    const {label, showTooltip} = this.props;
    return <SliderContainer>
      {label && <div className={'aq-slider-value'}>{label}</div>}
      <div className={'aq-slider-lane'} ref={sliderRef}>
        <div className={'aq-slider-lane-interval'}>{this.getIntervalBlocks()}</div>
        <div className={'aq-slider-lane-labels'}>
          {this.getIntervalLabels()}
          <span className={'aq-max-value'}>{max}</span></div>
        {showTooltip && sliderActive &&
        <div className={'aq-tooltip'} style={{left: percentageValue + '%'}}>{value}<span
          className={'hanger'}/></div>}
        <div className={'aq-slider-lane-selected'} style={{width: percentageValue + '%'}}/>
        <button type={'button'} className={'aq-slider'} onMouseDown={this.handleMouseDown}
                style={this.getStyle()}>
          <div className={'aq-slider-center'}/>
        </button>
      </div>
    </SliderContainer>
  }
}

const AqSlider = AqSliderComponent;
export default AqSlider;
