import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getProjectlist } from "../../controller/https/index";
import { getUserData } from "../../controller/actions";
import { IApplicationState } from "../../controller/models";
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector as _useSelector,
} from "react-redux";
import {
  useAccount,
  useMsal,
} from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { protectedResources } from "../../authConfig";

export const useSelector: TypedUseSelectorHook<IApplicationState> =
  _useSelector;

const HomeComponent = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const authenticate = async () => {
      if (sessionStorage.getItem("Authorization")) {
        setIsAuthenticated(true);
      }
      if (account && inProgress === "none" && !isAuthenticated) {
        try {
          const response = await instance.acquireTokenSilent({
            scopes: protectedResources.esfApi.scopes,
            account: account,
          });
          sessionStorage.setItem(
            "Authorization",
            `${response.tokenType} ${response.accessToken}`
          );
          setIsAuthenticated(true);
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            if (account && inProgress === "none") {
              try {
                const response = await instance.acquireTokenPopup({
                  scopes: protectedResources.esfApi.scopes,
                });
                sessionStorage.setItem(
                  "Authorization",
                  `${response.tokenType} ${response.accessToken}`
                );
                setIsAuthenticated(true);
              } catch (error) {
                setIsAuthenticated(false);
                console.log(error);
              }
            }
          }
        }
      }
    };
    authenticate();
  }, [account, inProgress, instance, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUserData());
      getProjectlist("limit=1").then((projectsPage) => {
        if (projectsPage && projectsPage.total > 0) {
          history.push("/projects");
        } else {
          history.push("/projects/new/company-info");
        }
      });
    }
  }, [isAuthenticated, history, dispatch]);

  return null;
};

export default HomeComponent;
