import React, { Component } from "react";
import styled from "styled-components";
import AqButtonComponent from "../../../components/seeds/aq-button.component";
import AqDateComponent from "../../../components/seeds/aq-date.component";
import AqFormComponent from "../../../components/seeds/aq-form.component";
import AqInput from "../../../components/seeds/aq-input.component";
import AqSelectComponent from "../../../components/seeds/aq-select.component";
import AqUploadComponent from "../../../components/upload/aq-upload.component";
import { White } from "../../../Styles";

const AqVesselFormWrapper = styled.div`
  top: 50%;
  position: relative;
  transform: translateY(-50%);
  margin: 0 auto;
  width: 80%;

  .frm {
    background: ${White};
    padding: 20px;
    box-sizing: border-box;
    transform-origin: top center;
    border-radius: 6px;
    box-shadow: 0px 1px 16px 2px #b5b4b4;
  }
  @media screen and (max-width: 760px) {
    top: 0;
    transform: translateY(0);
    margin: 0 auto;
    width: 100%;
    height: 100%;
    overflow: auto;
    .aq-v-fw {
      .flex {
        display: block;
        &.aq-vs-btns {
          display: flex;
        }
      }
      .span-6 {
        width: 100%;
      }
    }
  }
`;

interface IAqVesselEmploymentFormProps {
  onCancel: any;
  onSave: any;
  initialValues?: any;
  update?: boolean;
  id?: any;
}

class AqVesselEmploymentForm extends Component<
  IAqVesselEmploymentFormProps,
  any
> {
  uploadFileRef: any;
  constructor(props: any) {
    super(props);
    this.uploadFileRef = React.createRef();
    this.state = {
      initialValues: {},
      fields: {
        vesselEmployment: {
          label: "Employment Strategy",
          name: "vesselEmployment",
          options: [
            { label: "Bareboat Charter", value: "Bareboat Charter" },
            { label: "Time Charter", value: "Time Charter" },
            { label: "Pool", value: "Pool" },
            { label: "Spot Charter", value: "Spot Charter" },
          ],
          required: true,
        },
        charterer: {
          label: "Charterer Name",
          name: "charterer",
          placeholder: "Enter name",
          /* required: true, */
        },
        startEndDate: {
          label: "Start and End Date",
          name: "startEndDate",
          type: "date",
          required: true,
        },
        commissionPercent: {
          label: "Commission / Brokerage (%)",
          name: "commissionPercent",
          placeholder: "%",
          required: true,
          min: 0,
          max: 7.5,
        },
        timeCharterEquivalentPerDayRate: {
          label: "Time Charter Equivalent (TCE)",
          name: "timeCharterEquivalentPerDayRate",
          placeholder: "Amount",
          meta: { icon: "currency", unit: "Per day" },
          required: true,
        },
        contract: { label: "Contract", name: "contract" },
      },
    };
  }

  onSubmit = (formdata: any) => {
    const { initialValues = {} } = this.props;
    this.props.onSave(formdata, {
      update: initialValues.update,
      employmentNo: initialValues.employmentNo,
    });
  };

  onCancel = () => {
    this.props.onCancel();
  };

  getFields = (fc: any) => {
    const { formState, onFocus, onBlur, onChange, onSubmit } = fc;
    if (!formState) {
      return null;
    }
    const { fields } = this.state;

    const { initialValues } = this.props;
    const { employmentNo } = initialValues;
    const projectId = sessionStorage.getItem("__projectId");
    const vesselNo = sessionStorage.getItem("__vesselNo");
    const url = `api/projects/${projectId}/vessels/${vesselNo}/employments/${employmentNo}/contract`;

    return (
      <div className={"aq-v-fw"}>
        <h3>Add New Vessel Employment</h3>
        <div className={"flex"}>
          <div className={"span-6"}>
            <AqSelectComponent
              name={fields.vesselEmployment.name}
              label={fields.vesselEmployment.label}
              value={
                formState.vesselEmployment && formState.vesselEmployment.value
              }
              error={
                formState["vesselEmployment"] &&
                formState["vesselEmployment"]._errors
              }
              options={fields.vesselEmployment.options}
              onChange={onChange}
            />
          </div>
          <div className={"span-6"}>
            <AqInput
              name={fields.charterer.name}
              placeholder={fields.charterer.placeholder}
              value={formState.charterer && formState.charterer.value}
              //   error={formState["charterer"] && formState["charterer"]._errors}
              onFocus={onFocus}
              label={fields.charterer.label}
              onBlur={onBlur}
              onChange={onChange}
            />
          </div>
        </div>
        <div className={"flex"}>
          <div className={"span-6"}>
            <AqDateComponent
              name={fields.startEndDate.name}
              label={fields.startEndDate.label}
              selectsRange
              startDate={
                formState.startEndDate &&
                formState.startEndDate &&
                formState.startEndDate.value
              }
              placeholder={fields.startEndDate.placeholder}
              error={formState.startEndDate && formState.startEndDate._errors}
              onBlur={onBlur}
              onChange={onChange}
            />
          </div>
          <div className={"span-6"}>
            <AqInput
              name={fields.commissionPercent.name}
              placeholder={fields.commissionPercent.placeholder}
              value={
                formState.commissionPercent && formState.commissionPercent.value
              }
              error={
                formState["commissionPercent"] &&
                formState["commissionPercent"]._errors
              }
              onFocus={onFocus}
              type={"number"}
              label={fields.commissionPercent.label}
              onBlur={onBlur}
              onChange={onChange}
            />
          </div>
        </div>
        <div className={"flex"}>
          <div className={"span-6"}>
            <AqInput
              name={fields.timeCharterEquivalentPerDayRate.name}
              placeholder={fields.timeCharterEquivalentPerDayRate.placeholder}
              value={
                formState.timeCharterEquivalentPerDayRate &&
                formState.timeCharterEquivalentPerDayRate.value
              }
              error={
                formState["timeCharterEquivalentPerDayRate"] &&
                formState["timeCharterEquivalentPerDayRate"]._errors
              }
              onFocus={onFocus}
              type={"number"}
              label={fields.timeCharterEquivalentPerDayRate.label}
              meta={fields.timeCharterEquivalentPerDayRate.meta}
              onBlur={onBlur}
              onChange={onChange}
              currancy
            />
          </div>
          <div className={"span-6"}>
            <AqUploadComponent
              name={fields.contract.name}
              uploadFileUrl={url}
              onChange={onChange}
              // files={
              //   formState.contract &&
              //   !!formState.contract.value && [
              //     {
              //       name: formState.contract.value.name,
              //       value: { value: formState.contract.value },
              //     },
              //   ]
              // }
              ref={this.uploadFileRef}
              // passFile
            />
          </div>
        </div>
        <div className={"aq-vs-btns flex"}>
          <div className={"span-6 flex flex-end flex-justify-end"}>
            <AqButtonComponent
              label={"Cancel"}
              className={"btn-primary invert medium"}
              onClick={this.onCancel}
            />
          </div>
          <div className={"span-6 flex"}>
            <AqButtonComponent
              label={"Save"}
              className={"btn-primary medium"}
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    );
  };

  /**
   * Handles the validations on fields
   * @param field
   * @param value
   */
  handleValidation = (field: any, value: any) => {
    if (/commissionPercent/.test(field.name)) {
      if (value && (value > field.max || value < field.min)) {
        return {
          error: `Value must be between ${field.min} & ${field.max}`,
        };
      }
    }
    if (field.required && !value) {
      return {
        error: field.error || "field is required",
      };
    }
    return undefined;
  };

  getForm = () => {
    const { fields } = this.state;
    const { initialValues = {} } = this.props;
    return (
      <AqFormComponent
        fields={fields}
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        validator={this.handleValidation}
        renderer={this.getFields}
      />
    );
  };

  render(): React.ReactNode {
    return (
      <AqVesselFormWrapper>
        <div className={"slide-in-anim frm"}>{this.getForm()}</div>
      </AqVesselFormWrapper>
    );
  }
}

export default AqVesselEmploymentForm;
