import React, { Component } from "react";
import { connect } from "react-redux";
import AqButtonComponent from "../../../components/seeds/aq-button.component";
import AqTableComponent from "../../../components/table/aq-table.component";
import AqFormComponent from "../../../components/seeds/aq-form.component";
import AqInput from "../../../components/seeds/aq-input.component";
import AqDateComponent from "../../../components/seeds/aq-date.component";
import dispatchers from "../../../controller/actions";
import { IApplicationState } from "../../../controller/models";
import { getSelectedVessel } from "../../../helpers/utils";
import AqProjectWrapper from "./aq-project-wrapper";
import {
  dateTimeFormat,
  validObjectWithParameterKeys,
} from "../../../controller/utils/commonUtils";
import { requestMeeting } from "../../../controller/https";
import { isMobile } from "react-device-detect";
import { EsfLeftPanel } from "../../leftpanel";

//const setMeetingTime = new Date(new Date().setHours(new Date().getHours() + 1));
//const formInitialVal = { whenDateTime: setMeetingTime, durationInMinutes: 60 };
const formInitialVal = {
  topic: "Meeting with eShipFinance Analyst",
  whenDateTime: "",
  location: "Teams",
  durationInMinutes: 60,
};
class AqRequestMetting extends Component<any, any> {
  formRef: any;
  constructor(props: any) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      dataList: [],
      hideMenu: isMobile,
      gridMode: true,
      btnDisabled: false,
      searchValue: "",
      documentPanel: false,
      limit: 10,
      skip: 0,
      initialValues: formInitialVal,
      fields: {
        topic: {
          name: "topic",
          required: true,
          value: formInitialVal.topic,
          //placeholder: "<Enter Meeting Subject>",
          label: "Meeting topic",
        },
        whenDateTime: {
          name: "whenDateTime",
          required: true,
          value: formInitialVal.whenDateTime,
          placeholder: "Select date & Time",
          label: "When",
        },
        durationInMinutes: {
          name: "durationInMinutes",
          required: true,
          //Value: 60,
          //placeholder: "Duration",
          max: 60,
          min: 0,
          label: "Duration",
          value: formInitialVal.durationInMinutes,
          meta: { unit: "min" },
        },
        location: {
          name: "location",
          required: true,
          Value: "Teams",
          //placeholder: "Skype",
          label: "Location",
        },
        comments: {
          name: "comments",
          placeholder: "Your comment for the meeting",
          label: "Comments",
        },
      },

      tableField: [
        {
          label: "Meeting Date",
          field: "whenDateTime",
          // style: { width: 200 },
          renderer: (val: any, field: any) => dateTimeFormat(val.whenDateTime),
        },
        {
          label: "Topic",
          field: "topic",
          // style: { width: 400 },
        },
        {
          label: "Duration",
          field: "durationInMinutes",
          // style: { width: 200 },
        },
        {
          label: "Location",
          field: "location",
          // style: { width: 200 },
        },
        {
          label: "Requestor",
          field: "updatedBy",
          // style: { width: 200 },
          // renderer: this.fieldRenderer,
        },
        {
          label: "Created At",
          field: "updatedAt",
          // style: { width: 100 },
          renderer: (val: any, field: any) => dateTimeFormat(val.updatedAt),
        },
      ],
    };
  }

  componentDidMount(): void {
    this.onSearch("");
  }

  /**
   * create tbl data wrapper on main data
   */
  createTblData = (items: any) => {
    // console.log("createTblData", vessels);
    return items.map((row: any) => ({
      ...row,
      actionPopup: false,
      vesselPopup: false,
    }));
  };

  /**
   * search listener
   * @param ev
   */
  onSearch = async (val: any) => {
    const { limit, skip } = this.state;
    const { match } = this.props;
    const projectId = validObjectWithParameterKeys(match.params, ["id"])
      ? match.params.id
      : "";
    this.setState({ searchValue: val });
    let query = `skip=${skip}&limit=${limit}&search=${val}`;
    const res = await this.props.prevBookedMeeting(query, projectId);
    if (res) {
      this.setState({ dataList: this.props.bookedMeeting });
      // this.createTblData(this.props.bookedMeeting)
    }
  };

  /**
   * Toggle left menu panel
   */
  toggleMenu = () => {
    this.setState({ hideMenu: !this.state.hideMenu });
  };

  /**
   * Returns custom classes based on props
   */
  getClasses = () => {
    const cls = {
      rightPanel: "right-panel",
    };
    return cls;
  };

  /* Form functions start */
  onSubmit = (formdata: any, id: string, afterSubmit: any) => {
    this.setState({ btnDisabled: true });
    const { match } = this.props;
    const { initialValues = {} } = this.state;
    const projectId = validObjectWithParameterKeys(match.params, ["id"])
      ? match.params.id
      : "";
    const payload: any = {
      ...initialValues,
    };
    Object.keys(formdata.formState).forEach((key: string) => {
      if (formdata.formState[key].value) {
        payload[key] = formdata.formState[key].value;
      }
    });
    const res = requestMeeting(payload, projectId);
    res.then((val: any) => {
      if (afterSubmit && val.status) {
        afterSubmit();
        this.setState(
          { initialValues: formInitialVal, btnDisabled: false },
          this.formRef.current.setFormFields()
        );
      } else {
        this.props.ShowError(val.Message);
        this.setState({ btnDisabled: false });
      }
      this.onSearch("");
    });
  };

  /**
   * Handles the validations on fields
   * @param field
   * @param value
   */
  handleValidation = (field: any, value: any) => {
    if (/durationInMinutes/.test(field.name)) {
      let val = Number(value);
      if (val && (val > Number(field.max) || val < Number(field.min))) {
        return {
          error: `Value must be between ${field.min} & ${field.max}`,
        };
      }
      if (field.required && !val) {
        return {
          error: field.error || "field is required",
        };
      }
    }
    if (field.required && !value) {
      return {
        error: field.error || "field is required",
      };
    }
    return undefined;
  };

  renderFields = (fc: any) => {
    const { formState, onFocus, onBlur, onChange, onSubmit } = fc;
    if (!formState) {
      return null;
    }
    const { fields, btnDisabled } = this.state;
    return (
      <div className={"field-renderer"}>
        <br />
        <div>
          <div className={"span-8"}>
            <AqInput
              name={fields.topic.name}
              placeholder={fields.topic.placeholder}
              value={formState.topic && formState.topic.value}
              error={formState["topic"] && formState["topic"]._errors}
              onFocus={onFocus}
              type={"text"}
              label={fields.topic.label}
              meta={fields.topic.meta}
              onBlur={onBlur}
              onChange={onChange}
            />
          </div>
          <br />
          <div className={"span-4 pr-1"}>
            <AqDateComponent
              name={fields.whenDateTime.name}
              label={fields.whenDateTime.label}
              startDate={fields.whenDateTime && fields.whenDateTime.value}
              minDate={new Date()}
              placeholder={fields.whenDateTime.placeholder}
              error={formState.whenDateTime && formState.whenDateTime._errors}
              onBlur={onBlur}
              onChange={onChange}
              showTimeSelect
            />
          </div>
          <div className={"span-2"}>
            <AqInput
              name={fields.durationInMinutes.name}
              min={fields.durationInMinutes.min}
              max={fields.durationInMinutes.max}
              placeholder={fields.durationInMinutes.placeholder}
              value={
                formState.durationInMinutes && formState.durationInMinutes.value
              }
              error={
                formState["durationInMinutes"] &&
                formState["durationInMinutes"]._errors
              }
              onFocus={onFocus}
              label={fields.durationInMinutes.label}
              onBlur={onBlur}
              type={"number"}
              meta={fields.durationInMinutes.meta}
              onChange={onChange}
            />
          </div>
          <br />
          <div className={"span-8"}>
            <AqInput
              name={fields.location.name}
              placeholder={fields.location.placeholder}
              value={formState.location && formState.location.value}
              error={formState["location"] && formState["location"]._errors}
              onFocus={onFocus}
              type={"text"}
              label={fields.location.label}
              meta={fields.location.meta}
              onBlur={onBlur}
              onChange={onChange}
            />
          </div>

          <div className={"span-8"}>
            <AqInput
              name={fields.comments.name}
              placeholder={fields.comments.placeholder}
              value={formState.comments && formState.comments.value}
              error={formState["comments"] && formState["comments"]._errors}
              onFocus={onFocus}
              type={"textarea"}
              label={fields.comments.label}
              meta={fields.comments.meta}
              onBlur={onBlur}
              inputStyle={{ height: 100 }}
              onChange={onChange}
            />
          </div>
        </div>
        <br />
        <div className={"flex"}>
          <AqButtonComponent
            label={"Cancel"}
            style={{ marginRight: 16 }}
            className={"btn-primary invert medium"}
            onClick={() => this.props.history.push("/projects")}
            disabled={btnDisabled}
          />
          <AqButtonComponent
            label={"Send Request"}
            onClick={onSubmit}
            className={"btn-neutral medium"}
            disabled={btnDisabled}
          />
        </div>
      </div>
    );
  };

  /* Form functions close */

  /**
   * Default render fn
   */
  render(): React.ReactNode {
    const cls = this.getClasses();
    const {
      searchValue,
      fields,
      initialValues = {},
      formId,
      tableField,
      dataList,
      hideMenu,
    } = this.state;
    const { match, error, user = {} } = this.props;
    // console.log("ddd", this.props);
    return (
      <AqProjectWrapper>
        {error && <div className={"aq-error-panel"}>{error.message}</div>}

        <EsfLeftPanel
          hideMenu={hideMenu === true}
          userFirstName={user.firstName}
          userLastName={user.lastName}
          onMyProjectsClicked={undefined}
        />

        <div className={cls.rightPanel}>
          <div className={"aq-project-header"}>
            <div onClick={this.toggleMenu} className={"aq-bread-menu"}>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className="mobile-view-profile">
              <div className={"user-img"}>
                <div className={"no-img"} />
              </div>
              <h3 className={"medium"}>
                {user.firstName} {user.lastName}
              </h3>
            </div>
            <div>
              <input
                className={"aq-search"}
                value={searchValue}
                onChange={(e) => this.onSearch(e.target.value)}
                placeholder={"Search"}
              />
            </div>
          </div>

          {/* {documentPanel && this.getDocumentPanel()} */}
          {error && <div className={"aq-error-panel"}>{error.message}</div>}

          {/* Main page content start */}
          <div className={"flex title-margin"}>
            <div className={"tt icon"}>
              #{" "}
              {validObjectWithParameterKeys(match.params, ["id"])
                ? match.params.id
                : ""}
            </div>
            <div className={"flexible"} />
          </div>
          <div className={"aq-toolbar"}>
            <div className={"flexible project-title"}>
              Request a meeting with eShipfinance Analyst
            </div>
          </div>
          <AqFormComponent
            id={formId}
            key={formId}
            fields={fields}
            ref={this.formRef}
            initialValues={initialValues}
            onSubmit={this.onSubmit}
            validator={this.handleValidation}
            renderer={this.renderFields}
          />

          <br />
          <br />
          <AqTableComponent
            fields={tableField}
            data={dataList}
            titleKey="topic"
          />
        </div>
      </AqProjectWrapper>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  const { root, dashboard } = state;
  const selectedVessel = getSelectedVessel(state);
  const bookedMeeting = dashboard.bookedMeeting || [];
  return {
    ...selectedVessel,
    error: root.error,
    bookedMeeting: bookedMeeting,
    companyInfo: root.companyInfo,
    user: dashboard.users,
  };
};

const mapDispatchToProps = { ...dispatchers };
export default connect(mapStateToProps, mapDispatchToProps)(AqRequestMetting);
