import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import AqButtonComponent from "../../../components/seeds/aq-button.component";
import AqTableComponent from "../../../components/table/aq-table.component";
import dispatchers from "../../../controller/actions";
import {
  deleteVesselEmploymentHttp,
  getVesselEmploymentHttp,
} from "../../../controller/https";
import { formatNumberWithCurrency } from "../../../controller/utils/commonUtils";
import { getSelectedVessel } from "../../../helpers/utils";

const AqVesselTblWrapper = styled.div`
  .aq-tbl-header {
    .lbl-area {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      label {
        margin-right: 20px;
      }
    }
  }
`;

interface IAqVesselTblProps {
  handleAdd: any;
  handleAction: any;
  data?: Array<any>;
  vesselEmployment?: Array<any>;
  getBackTabelData: any;
}

class AqVesselTblComponent extends Component<IAqVesselTblProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      name: "first",
      vesselEmployments: [],
      toggleAction: "",
      fields: [
        {
          label: "Employment Strategy",
          field: "vesselEmployment",
          style: { width: "155px" },
        },
        {
          label: "Start and End Date",
          field: "startDate",
          renderer: this.fieldRenderer,
          style: { width: "200px" },
        },
        {
          label: "TCS ($/day)",
          field: "timeCharterEquivalentPerDayRate",
          style: { width: "100px" },
        },
        { label: "Charterer", field: "charterer", style: { width: "150px" } },
        {
          label: "Commission / Brokerage (%)",
          field: "commissionPercent",
          style: { flex: "1" },
        },
        // {label: 'Contract', field: 'contract', renderer: this.fieldRenderer, style: {width: '80px', justifyContent: 'center'}},
        {
          label: "Action",
          is_action: true,
          field: "actions",
          renderer: this.fieldRenderer,
          mobRender: this.renderDotActions,
          style: { width: "100px" },
        },
      ],
    };
  }

  componentDidMount() {
    this.getEmploymentData();
    setTimeout(() => {
      this.setState({ name: "updated" });
    }, 300);
  }

  getEmploymentData = () => {
    getVesselEmploymentHttp().then(
      (res) => {
        console.log("vesselEmployments in state", res);
        this.setState({ vesselEmployments: res });
        this.props.getBackTabelData(res);
      },
      (error) => {
        this.setState({ vesselEmployments: [] });
      }
    );
  };

  tblClick = (row: any, type: string) => {
    if (type === "delete") {
      deleteVesselEmploymentHttp(row.employmentNo).then(
        (res) => {
          console.log("[DELETED successfully ]", res);
          this.getEmploymentData();
        },
        (error) => {
          console.log("[ERORR in delete ]", error);
        }
      );
    } else {
      this.props.handleAction(row, type);
    }
  };

  getRender = (date: any) => {
    return (
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
      "/" +
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) +
      "/" +
      date.getFullYear()
    );
  };

  /**
   * toggle row action popup
   * @param row
   */
  toggleActionPopup = (row: any) => {
    if (this.state.toggleAction === row.employmentNo) {
      this.setState({ toggleAction: "" });
    } else {
      this.setState({ toggleAction: row.employmentNo });
    }
  };

  isOpen = (row: any) => {
    return this.state.toggleAction === row.employmentNo ? true : false;
  };

  /**
   * Action popup
   * @param props
   * @constructor
   */
  ActionPopup = (props: any) => {
    return (
      <ul className={"aq-action-wrapper"}>
        <div className={"popup-hanger"} />

        <li
          onClick={() => props.onClick("action", props.row, "edit")}
          className={"aq-icon"}
        >
          <span className={"icon-edit"} /> Edit
        </li>
        <li
          onClick={() => props.onClick("action", props.row, "delete")}
          className={"aq-icon"}
        >
          <span className={"icon-trash"} /> Delete
        </li>
      </ul>
    );
  };

  /**
   * action popup click listener
   * @param type
   * @param row
   * @param id
   */
  handlePopupActionClick = (type: string, row: any, id: any) => {
    console.log("handlePopupActionClick", type, row, id);
    if (type === "action") {
      if (id === "edit") {
        this.tblClick(row, "edit");
      }
      if (id === "delete") {
        this.tblClick(row, "delete");
      }

      this.setState({ toggleAction: "" });
    }
  };

  /**
   * row action renderer
   * @param row
   * @param field
   */
  renderDotActions = (row: any, field: any) => {
    console.log("row--", row);
    return (
      <div
        className={"aq-action-cell"}
        // onClick={() => this.handleActionClick(row, field)}
      >
        <div
          onClick={() => this.toggleActionPopup(row)}
          className={"aq-actions-dot"}
        >
          <div className={"aq-more"}>
            <span />
            <span />
            <span />
          </div>
        </div>
        {this.isOpen(row) &&
          this.ActionPopup({
            row,
            field,
            onClick: this.handlePopupActionClick,
          })}
      </div>
    );
  };

  fieldRenderer = (row: any, field: any) => {
    if (field.field === "startDate") {
      if (row[field.field]) {
        return (
          <div>
            {this.getRender(new Date(row[field.field]))} -{" "}
            {this.getRender(new Date(row["endDate"]))}
          </div>
        );
      }
      return null;
    }
    if (field.field === "contract") {
      if (row[field.field]) {
        return (
          <div
            onClick={() => this.tblClick(row, "contract")}
            className={"icon-pdf"}
          />
        );
      }
      return null;
    }
    if (field.field === "actions") {
      return (
        <>
          <span
            onClick={() => this.tblClick(row, "edit")}
            className={"icon-edit"}
          />
          <span
            onClick={() => this.tblClick(row, "delete")}
            className={"icon-trash"}
          />
        </>
      );
    }
    return <div>{row[field.field]}</div>;
  };

  handleBtnClick = (btn: any) => {
    this.props.handleAdd(btn);
  };

  flatEmployments = () => {
    const { vesselEmployments } = this.state;
    if (!vesselEmployments || !vesselEmployments.length) {
      return [];
    }
    return vesselEmployments.map((item: any) => {
      //   console.log("sss", item);
      let employment = {
        ...item.employment,
        timeCharterEquivalentPerDayRate: formatNumberWithCurrency(
          item.employment.timeCharterEquivalentPerDayRate
        ),
      };
      return {
        ...employment,
        employmentNo: item.employmentNo,
      };
    });
  };

  render(): React.ReactNode {
    const { fields } = this.state;
    // const {vesselEmployment = []} = this.props;
    const vesselEmploymentsData = this.flatEmployments();
    return (
      <AqVesselTblWrapper>
        <div className={"aq-tbl-header flex"}>
          <div className={"flexible flex lbl-area"}>
            <label>Vessel Employment</label>
            <div className={"warning-box"}>
              To attract our investor's attention, Make sure you fill in as much
              employment details as possible.
            </div>
          </div>
          <div className={"btn-area"}>
            <AqButtonComponent
              label={"+ Add New"}
              className={"btn-neutral medium"}
              onClick={this.handleBtnClick}
            />
          </div>
        </div>
        <AqTableComponent
          fields={fields}
          data={vesselEmploymentsData}
          titleKey="vesselEmployment"
        />
      </AqVesselTblWrapper>
    );
  }
}

const mapStateToProps = (state: any) => {
  const selectedVessel = getSelectedVessel(state);
  return {
    ...selectedVessel,
  };
};

const mapDispatchToProps = { ...dispatchers };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AqVesselTblComponent);
