export const ADD_VESSEL = "ADD_VESSEL";
export const ADD_COMPLANY_INFO = "ADD_COMPLANY_INFO";
export const ADD_EXPERIENCE_INFO = "ADD_EXPERIENCE_INFO";
export const ADD_NEW_VESSEL = "ADD_NEW_VESSEL";
export const ADD_NEW_COMPANY_PROJECT = "ADD_NEW_COMPANY_PROJECT";
export const ADD_DOCUMENT_TO_PROJECT = "ADD_DOCUMENT_TO_PROJECT";
export const ADD_VESSEL_EMPLOYMENT = "ADD_VESSEL_EMPLOYMENT";
export const CLEAR_IMO_SELECTION = "CLEAR_IMO_SELECTION";
export const GET_EXPERIENCE_INFO = "GET_EXPERIENCE_INFO";
export const DELETE_DOCUMENT_FROM_PROJECT = "DELETE_DOCUMENT_FROM_PROJECT";
export const IS_LOADING = "IS_LOADING";
export const UPDATE_VESSEL_EMPLOYMENT = "UPDATE_VESSEL_EMPLOYMENT";
export const ON_DELETE_VESSEL = "ON_DELETE_VESSEL";
export const ON_EDIT_VESSEL = "ON_EDIT_VESSEL";
export const ON_VESSEL_SELECT = "ON_VESSEL_SELECT";
export const DELETE_VESSEL_EMPLOYMENT = "DELETE_VESSEL_EMPLOYMENT";
export const ON_DUPLICATE_VESSEL = "ON_DUPLICATE_VESSEL";
export const FETCH_VESSELS = "FETCH_VESSELS";
export const ON_EXCEPTION = "ON_EXCEPTION";
export const ON_ERROR = "ON_ERROR";
export const ON_NAVIGATE = "ON_NAVIGATE";
export const ON_SIGN_UP = "ON_SIGN_UP";
export const ON_VESSELS_FETCH = "ON_VESSELS_FETCH";
export const ON_VESSELS_FORM_SUBMIT = "ON_VESSELS_FORM_SUBMIT";
export const ON_VESSELS_OPEX_FORM_SUBMIT = "ON_VESSELS_OPEX_FORM_SUBMIT";
export const ON_VESSELS_OPEX_FORM_SAVE = "ON_VESSELS_OPEX_FORM_SAVE";
export const UPDATE_PAGE = "UPDATE_PAGE";
export const SELECT_PROFILE = "SELECT_PROFILE";
export const SET_CONTEXT = "SET_CONTEXT";
export const RESET_VESSEL = "RESET_VESSEL";
export const ADD_NEW_VESSEL_HEAD = "ADD_NEW_VESSEL_HEAD";

// Project constant
export const PROJECT_LISTS = "PROJECT_LISTS";

// meeting
export const BOOKIED_MEETING = "BOOKIED_MEETING";
export const EXISTING_DOC = "EXISTING_DOC";
export const LOOKUP_DOC_TYPE_LIST = "LOOKUP_DOC_TYPE_LIST";

// Country Flag constant
export const COUNTRYFLAGS_LISTS = "COUNTRYFLAGS_LISTS";
export const USER_INFO = "USER_INFO";
export const PROJECT_PROGRESS = "PROJECT_PROGRESS";
