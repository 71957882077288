import React, { Component } from "react";
import "./upload.css";

export default class Dropzone extends Component<any, any> {
  fileInputRef: any;

  constructor(props: any) {
    super(props);
    this.state = {};
    this.fileInputRef = React.createRef();
    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  onFilesAdded(evt: any) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
  }

  fileListToArray(list: any) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  onDragOver(evt: any) {
    evt.preventDefault();

    if (this.props.disabled) return;

    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onDrop(event: any) {
    event.preventDefault();

    if (this.props.disabled) return;

    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ hightlight: false });
  }

  getAllowedTypes = () => {
    return ".pdf,.png,.jpg,.jpeg,.doc,.docx";
  };

  render() {
    const { error, fileName } = this.props;
    const allowedTypes = this.getAllowedTypes();
    return (
      <div
        className={`Dropzone ${this.state.hightlight ? "Highlight" : ""} ${
          error ? "Error" : ""
        }`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{ cursor: this.props.disabled ? "default" : "pointer" }}
      >
        <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          multiple={false}
          accept={allowedTypes}
          onChange={this.onFilesAdded}
        />
        <div className={"flex"}>
          {!fileName && (
            <>
              <img
                alt="upload"
                className="Icon"
                src="./icons/file-upload.svg"
              />
              <span>
                <span className={"link-txt"}>Click here</span> to upload
                Contract
              </span>
            </>
          )}
          {fileName && (
            <span>
              <span className={"link-txt"}>{fileName}</span>
            </span>
          )}
        </div>
        {this.props.children}
      </div>
    );
  }
}
