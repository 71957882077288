import React, { Component } from "react";
import Styles from "./styles/form";

/**
 * Interface for the Form component's props
 */
interface IFormProps {
  id?: string;
  fields: { [key: string]: any };
  title?: string;
  footerText?: string;
  initialValues: { [key: string]: any };
  onSubmit: any;
  submitTxt?: string;
  validator?: any;
  renderer: any;
  onFieldChange?: any;
}

/**
 * Form component, provides a wrapper on native form
 * also does form's related housekeeping stuff
 */
class AqFormComponent extends Component<IFormProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      fields: {},
    };
  }

  /**
   * Update the local state based on the changes in props
   * @param props
   * @param state
   */
  static getDerivedStateFromProps(props: any, state: any = {}) {
    if (props.fields) {
      for (let key in props.fields) {
        const _touched = state.fields[key] && state.fields[key]._touched;
        state.fields[key] = { ...props.fields[key], _touched };
      }
    }
    return state;
  }

  setFormFields() {
    const { fields, initialValues = {} } = this.props;
    const formState = this.state.formState || {};
    console.log("form", fields, initialValues);
    if (initialValues) {
      for (let field in fields) {
        formState[field] = formState[field] || {};
        formState[field].value =
          initialValues[field] !== undefined ? initialValues[field] : "";
      }
      console.log("formState", formState);
      this.setState({
        formState: { ...formState, _touched: false, _valid: true },
      });
    }
  }
  /**
   * Update the formState when component becomes stable
   */
  componentDidMount() {
    this.setFormFields();
  }

  /**
   * Default validator
   * @param field
   * @param value
   */
  validator = (field: any, value: any) => {
    if (field.required && !value) {
      return {
        error: field.error || "field is required",
      };
    }
    return undefined;
  };

  /**
   * Change handler, performs the side effect of changes
   * @param fieldName
   * @param fieldValue
   * @param skipPropagation
   */
  changeHandler = (
    fieldName: string,
    fieldValue: any,
    skipPropagation?: boolean
  ) => {
    const value =
      typeof fieldValue === "object" && !!fieldValue && fieldValue.value
        ? fieldValue.value
        : fieldValue;
    const { formState, fields } = this.state;
    const changes = { [fieldName]: value };
    const field = fields[fieldName];
    field.value = changes[fieldName];
    formState[fieldName] = formState[fieldName] || {};
    formState[fieldName].value = value;
    if (fieldValue && fieldValue.renderValue) {
      formState[fieldName].renderValue = fieldValue.renderValue;
    }
    const errors = this.getValidatorError(field, value);
    if (errors) {
      formState[fieldName]._errors = errors;
      formState._valid = false;
    } else {
      formState[fieldName]._errors = undefined;
    }
    const newFormState = { ...formState, _touched: true };
    this.setState({ fields, formState: newFormState });
    if (!skipPropagation && this.props.onFieldChange) {
      this.props.onFieldChange({ fieldName, value: fieldValue });
    }
  };

  /**
   * Returns the validation error
   * @param field
   * @param value
   */
  getValidatorError = (field: any, value: any): any => {
    if (field._touched) {
      return (
        (this.props.validator &&
          this.props.validator(field, value, this.state.formState)) ||
        this.validator(field, value)
      );
    }
    return undefined;
  };

  /**
   * Clear form inputs
   */
  clearForm = () => {
    console.log("clear form value >>>>>");
    const { fields, formState } = this.state;
    formState._valid = false;
    for (let key in fields) {
      const field = fields[key];
      if (field.hidden) {
        continue; // no processing on hidden fields
      }
      field._touched = false;
      this.changeHandler(key, "", true);
      if (field.required) {
        if (!field.value) {
          formState._valid = false;
        }
      }
    }
    formState._touched = false;
    this.setState({ fields, formState });
  };

  /**
   * Marks all field as touched
   */
  markedAllFieldsAsTouched(cb: any) {
    const { fields, formState } = this.state;
    formState._valid = true;
    for (let key in fields) {
      const field = fields[key];
      if (field.hidden) {
        continue; // no processing on hidden fields
      }
      field._touched = true;
      this.changeHandler(key, formState[key].value, true);
      if (field.required) {
        if (!field.value) {
          formState._valid = false;
        }
      }
    }
    formState._touched = true;
    this.setState({ fields, formState }, cb);
  }

  /**
   * Focus handler, marks field as touched
   * @param fieldName
   */
  handleFocus = (fieldName: string) => {
    const fields = this.state.fields;
  };

  /**
   * Blur handler
   * @param fieldName
   */
  handleBlur = (fieldName: string) => {
    const { fields, formState } = this.state;
    fields[fieldName]._touched = true;
    this.changeHandler(fieldName, formState[fieldName].value, true);
    // handle blur logic goes here
  };

  submit = (ev: any) => {
    ev && ev.preventDefault();
    this.markedAllFieldsAsTouched(() => {
      if (this.state.formState._valid) {
        this.props.onSubmit(this.state, this.props.id, () => {
          this.clearForm();
        });
      }
    });
  };

  /**
   * Renderer of the component
   */
  render() {
    const { title, id } = this.props;
    return (
      <Styles>
        {title && <h1>{title}</h1>}
        <form onSubmit={this.submit} id={id}>
          {this.props.renderer({
            formState: this.state.formState,
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            onChange: this.changeHandler,
            onSubmit: this.submit,
          })}
        </form>
      </Styles>
    );
  }
}

export default AqFormComponent;
