import React, { Component } from 'react';
import styled from 'styled-components';
import { VesselTypes } from '../../controller/models';
import { FontSize14, MetalDark, Neutral, NeutralLight, Primary, White } from '../../Styles';


const AqVesselWrapper = styled.div`
  
  .aq-vessel-container {
    width: 166px;
    height: 127px;
    box-sizing: border-box;
    border-radius: 22px;
    position: relative;
    margin-right: 24px;
    display: flex;
    cursor: pointer;
    transition: all .2s ease-in-out;
    
    &:hover {
      box-shadow: 3px 3px 8px 0px #989898;
    }
    
    .aq-card-corner {
      position: absolute;
      width: 51px;
      height: 58px;
      right: -1px;
      top: -1px;
      background: ${White};
      border-top-right-radius: 22px;
      
      &.inverted {
        transform: rotate(180deg);
        bottom: -1px;
        top: auto;
        left: -1px;
      }
      
      span {
        width: 11px;
        height: 11px;
        background-color: ${Primary};
        display: block;
        border-radius: 50%;
        top: -3px;
        position: relative;
        left: 4px;
      }
      
      &.selected {
        background-color: ${NeutralLight};
        
        span {
          background-color: ${Neutral}
        }
      }
    }
  
    .aq-card-wrapper {
      font-size: ${FontSize14};
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 80px 0px 24px 0;
      border-radius: 22px;
      border: 3px solid ${Primary};
      flex: 1;
      text-transform: uppercase;
      font-family: 'Rubik-Light';
      background-size: 50px;
      
      &.selected {
        background-color: ${NeutralLight};
        border-color: ${Neutral};
      }
      
      .aq-card-label {
        color: ${MetalDark};
        display: flex;
        z-index: 1;
      }
      
      &.aq-card-icon-0 {
        background-position: center 37px;
        background-repeat: no-repeat;
        background-size: 58px;
        background-image: url(${process.env.PUBLIC_URL + '/icons/ship/carrier.svg'});
      }
      &.aq-card-icon-1 {
        background-position: center 34px;
        background-repeat: no-repeat;
        background-image: url(${process.env.PUBLIC_URL + '/icons/ship/tanker.svg'});
      }
      &.aq-card-icon-2 {
        background-position: center 34px;
        background-repeat: no-repeat;
        background-image: url(${process.env.PUBLIC_URL + '/icons/ship/chemical.svg'});
      }
      &.aq-card-icon-3 {
        background-position: center 40px;
        background-repeat: no-repeat;
        background-size: 58px;
        background-image: url(${process.env.PUBLIC_URL + '/icons/ship/cargo.svg'});
      }
      &.aq-card-icon-4 {
        background-position: center 20px;
        background-repeat: no-repeat;
        background-image: url(${process.env.PUBLIC_URL + '/icons/ship/other.svg'});
      }
     } 
  }
`;

interface IAqVesselCardProps {
  id: any;
  label: string;
  onCardSelected: any;
  selected?: boolean;
  type: VesselTypes;
  inverted?: boolean;
}

export default class AqVesselCardComponents extends Component<IAqVesselCardProps, any> {
  constructor(props: IAqVesselCardProps) {
    super(props);
    this.state = {}
  }

  getClass = () => {
    const {selected, inverted} = this.props;
    const cls = {
      wrapper: 'aq-card-wrapper',
      corner: 'aq-card-corner'
    };
    if (selected) {
      cls.wrapper += ' selected';
      cls.corner += ' selected';
    }
    if (inverted) {
      cls.corner += ' inverted';
    }
    return cls
  }

  handleOnChange = (ev: any) => {
    const {id} = this.props;
    this.props.onCardSelected && this.props.onCardSelected(id);
  }

  render(): React.ReactNode {
    const {type, label} = this.props;
    const cls = this.getClass();
    return <AqVesselWrapper>
      <div onClick={this.handleOnChange} className={'aq-vessel-container'}>
        <div className={cls.corner}><span/></div>
        <div className={`${cls.wrapper} aq-card-icon-${type}`}>
          <div className={`aq-card-label`}>
            {label}
          </div>
        </div>
      </div>
    </AqVesselWrapper>
  }

}
