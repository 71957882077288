import React, { FC, ReactElement, useEffect, useState } from "react";
import CSS from "csstype";
import { useHistory } from "react-router";
import { EsfHelpForm } from "./help-form";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { isMobile } from "react-device-detect";

type EsfLeftPanelProps = {
  hideMenu: boolean;
  userFirstName: string;
  userLastName: string;
  onMyProjectsClicked: Function | undefined;
};

const divLogoStyles: CSS.Properties = {
  textAlign: "center",
  marginBottom: "2em",
};

const logoStyles: CSS.Properties = {
  width: "100%",
};

export const EsfLeftPanel: FC<EsfLeftPanelProps> = ({
  hideMenu = isMobile,
  userFirstName = "",
  userLastName = "",
  onMyProjectsClicked,
}): ReactElement => {
  const { instance } = useMsal();
  const history = useHistory();
  const [initials, setInitials] = useState("");
  const [isHelpFormVisible, setIsHelpFormVisible] = useState(false);

  useEffect(() => {
    let updatedInitials = "";
    if (userFirstName) {
      updatedInitials += userFirstName.substring(0, 1);
    }
    if (userLastName) {
      updatedInitials += userLastName.substring(0, 1);
    }
    setInitials(updatedInitials);
  }, [userFirstName, userLastName]);

  return (
    <div className={`left-panel${hideMenu ? " in-active" : ""}`}>
      <div style={divLogoStyles}>
        <img alt="logo" style={logoStyles} src={"/icons/logo.png"} />
      </div>
      <div className={"user-img"}>
        <div className={"no-img"}>{initials}</div>
      </div>
      <h3 className={"medium"}>
        {userFirstName} {userLastName}
      </h3>
      <ul className={"user-links"}>
        <li
          onClick={() =>
            onMyProjectsClicked
              ? onMyProjectsClicked()
              : history.push("/projects")
          }
          className={"icon-projects"}
        >
          My Projects
        </li>
      </ul>

      <AuthenticatedTemplate>
        <ul className={"left-panel-footer"}>
          <li
            onClick={() => setIsHelpFormVisible(true)}
            className={"icon-help"}
          >
            Help
          </li>

          <li
            onClick={() => {
              instance.logout();
              history.push("/");
            }}
            className={"icon-logout"}
          >
            Logout
          </li>
        </ul>
      </AuthenticatedTemplate>

      {isHelpFormVisible && (
        <EsfHelpForm
          initialValues={{}}
          onClose={() => setIsHelpFormVisible(false)}
          onSend={() => setIsHelpFormVisible(false)}
        />
      )}
    </div>
  );
};
