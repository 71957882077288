import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { AqFooter } from "../../../components/footer/aq-footer";
import AqButtonComponent from "../../../components/seeds/aq-button.component";
import AqDateComponent from "../../../components/seeds/aq-date.component";
import AqFormComponent from "../../../components/seeds/aq-form.component";
import AqInput from "../../../components/seeds/aq-input.component";
import dispatchers from "../../../controller/actions";
import EsfSlider from "../../../components/slider2";
import AqInputfspl from "../../../components/seeds/aq-inputfspl.component";

import {
  addFundingHttp,
  deleteVesselHttp,
  getFundingHttp,
  getProjectAllVesselsHttp,
} from "../../../controller/https";
import { VesselTypes } from "../../../controller/models";
import {
  formatNumberWithCurrency,
  currancyToNumber,
} from "../../../controller/utils/commonUtils";
import { VESSEL_TYPES } from "../../../helpers/constants";
import { getSavedVessels } from "../../../helpers/utils";
import {
  BgDark,
  ErrorCode,
  Hint,
  Light,
  NegativeChange,
  Neutral,
  Orange,
  Primary,
  White,
} from "../../../Styles";
import { useHistory, useParams } from "react-router";

const AqVesselFundingWrapper = styled.div`
  text-align: left;
  margin: 0 auto;
  position: relative;
  width: 100%;
  max-width: 1200px;

  .vessel-item {
    margin: 20px 20px 0 0;
    width: 100%;

    :first-child {
      margin-top: 0;
    }
  }

  .card-1 {
    background-color: ${BgDark};
    padding: 24px 20px;
    box-sizing: border-box;
    margin: 20px 20px 0 0;
    align-self: center;
  }
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 1px 1px 7px 1px #cac9c9;
    padding: 16px;
    background: white;
    border-radius: 4px;
    z-index: 1;
    .popup-content {
      display: flex;
      align-items: center;
      :before {
        content: url(${process.env.PUBLIC_URL +
  "/icons/exclamation-triangle.svg"});
        margin-right: 5px;
      }
    }
    .popup-actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;
    }
  }
  .lbl {
    font-size: 20px;
  }
  .aq-vessel-info-header {
    position: relative;
    flex: 1;
    &:first-child {
      margin-top: 0;
    }
    .counter {
      position: absolute;
      background-color: ${Primary};
      color: ${White};
      border: 1px solid #dcdcdc;
      padding: 11px 14px;
      height: 42px;
      box-sizing: border-box;
      width: 42px;
      border-radius: 50%;
      font-size: 24px;
      top: -11px;
      left: 30px;
    }
    .vessel-box-1 {
      text-align: center;
      padding: 0 27px 0 40px;
      display: inline-block;
      box-sizing: border-box;
      width: 55%;
      h3 {
        margin: 0;
        // font-size: 24px;
      }
    }
  }
  .imo-number-txt {
    font-size: 14px;
  }
  .vessel-box-2 {
    border-left: 1px solid ${Hint};
    padding-left: 20px;
    display: inline-block;
    box-sizing: border-box;
    width: 45%;
    .top-pixel {
      background-color: ${Neutral};
      display: block;
      height: 10px;
      width: 10px;
      margin-bottom: 10px;
    }
    .vessel-box-lbl {
      font-size: 14px;
      font-family: "Rubik-Medium";
    }
  }
  .vessel-box-3 {
    span {
      background-color: ${Light};
    }
  }
  .vessel-box-4 {
    span {
      background-color: ${Orange};
    }
  }
  .vessel-box-5 {
    span {
      background-color: ${NegativeChange};
    }
  }
  [class^="aq-vessel-icon-"] {
    padding: 42px;
    background-position: center 20px;
    background-repeat: no-repeat;
    background-size: 80px;
  }
  .aq-vessel-icon-0 {
    background-image: url(${process.env.PUBLIC_URL +
  "/icons/ship/carrier.svg"});
  }
  .aq-vessel-icon-1 {
    background-image: url(${process.env.PUBLIC_URL + "/icons/ship/tanker.svg"});
  }
  .aq-vessel-icon-2 {
    background-image: url(${process.env.PUBLIC_URL +
  "/icons/ship/chemical.svg"});
  }
  .aq-vessel-icon-3 {
    background-image: url(${process.env.PUBLIC_URL + "/icons/ship/cargo.svg"});
  }
  .aq-vessel-icon-4 {
    background-image: url(${process.env.PUBLIC_URL + "/icons/ship/other.svg"});
    background-position: center 0px;
  }
  .btn-container {
    margin-top: 22px;
    .btn-primary {
      margin-right: 10px;
    }
  }
  .vessel-list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .right-panel {
    max-width: 440px;
  }
  .rt-title-box {
    padding: 16px;
    background-color: ${BgDark};
    font-family: "Rubik-Medium";
    position: relative;
    .top-pixel {
      background-color: ${ErrorCode};
      display: block;
      height: 10px;
      width: 10px;
      margin-bottom: 10px;
    }
    .rt-title {
      margin-bottom: 10px;
    }
    .rt-title-val {
      font-size: 28px;
      color: ${Neutral};
    }
  }
  .funding-box {
    padding: 16px;
    max-width: 100%;
  }
  .left-slider {
    margin-right: 16px;
  }

  .mob-view {
    display: none;
    text-align: center;
    .topSection {
      display: flex;
      justify-content: space-around;
    }
    .counter {
      position: relative;
      margin: 0 auto;
      top: inherit;
      left: inherit;
    }
    .btn-container {
      width: 100%;
    }
  }
  @media screen and (max-width: 677px) {
    .vsl-container {
      display: block;
      // .vessel-list {
      //   display: none;
      // }
      .right-panel {
        max-width: 100%;
      }
      .rt-title-box {
        margin: 16px;
        border-radius: 5px;
        border: 1px solid #d4d4d4;
      }
      .vessel-item {
        margin: 0 auto 20px !important;
        width: calc(100% - 40px) !important;
      }
      .card-1 {
        margin: 0;
        width: calc(100% - 40px);
      }
    }

    .desktop-view {
      display: none;
    }
    .mob-view {
      display: block;
      margin: 0;
      .btn-container {
        border-top: 1px solid #d4d4d4;
        justify-content: center;
        padding-top: 10px;
      }
    }
    .mob-top-view {
      .react-datepicker {
        bottom: 45px;
      }
    }
    .funding-box {
      padding-bottom: 30px;
    }
  }
`;

const AqVesselFundindComponent = (props: any) => {
  const { addVessel, updatePage, projectProgress, setContext } = props;
  const fields = {
    requiredFundingPercentage: {
      name: "requiredFundingPercentage",
      label: ""
    },
    requiredFunding: {
      name: "requiredFunding",
      label: "",
      meta: { icon: "currency" },
      placeholder: ""
    },
    loadTerm: { name: "loadTerm", label: "", min: 36, max: 60, placeholder: "" },
    drawDownDate: {
      name: "drawDownDate",
      type: "date",
      label: "What will be the draw down date?",
      required: true,
      placeholder: ""
    },
    workingCapital: {
      name: "workingCapital",
      label:
        "How much is the initial Working capital allocated to this project?",
      meta: { icon: "currency" },
      placeholder: ""
    },
  };

  const history = useHistory();
  const { id } = useParams<{ id: any }>();
  const [vessels, setVessels] = useState([]);
  const [initialValues, setInitialValues] = useState<{
    requiredFundingPercentage: number;
    requiredFunding: number;
    loadTerm: number;
    drawDownDate: number;
    workingCapital: number;
  } | null>(null);
  const [vesselNo, setVesselNo] = useState(null);

  const getVessels = async (id: any) => {
    try {
      var res = await getProjectAllVesselsHttp(id);
      console.log('getVessels', res);
      setVessels(res);
    }
    catch(error) {
      console.error('Failed to get project vessels', error);
    }
  };

  useEffect(() => {
    updatePage({
      name: "vessels",
      showActions: true,
      step: 3,
      subStep: 2,
      resetVessel: false,
    });
  }, []);

  useEffect(() => {
    if (!!!id || id === "new") {
      return;
    }

    getVessels(id);

    getFundingHttp(id).then(
      (res) => {
        setInitialValues({
          requiredFundingPercentage: 0,
          requiredFunding: res.amount,
          loadTerm: res.loanTermInMonths || 60,
          drawDownDate: res.drawDownDate,
          workingCapital: res.workingCapital,
        });
      },
      (error) => {
        console.log("getFundingHttp error ", error);
      });
    
    projectProgress(100);
  }, [id]);

  let fmSubmitRef: any;

  const handleFooterAction = (ev: any) => {
    if (ev === "back") {
      history.push(`/projects/${id}/company-info`);
    } else {
      fmSubmitRef();
    }
  };

  const handleBtnAction = (type: string, vessel: any) => {
    if (type === "Delete") {
      setVesselNo(vessel.vesselNo);
    } else if (type === "Edit") {
      setContext({ context: vessel.vesselDetails.vesselType });
      sessionStorage.setItem("__vesselNo", vessel.vesselNo);
      history.push(`/projects/${id}/vessels/${vessel.vesselNo}/search`);
    }
  };

  const getVesselType = (type: string = "") => {
    const vesselTypes = [
      {
        label: "bulk carrier",
        type: VesselTypes.bulk,
        id: VESSEL_TYPES.CARRIERS,
      },
      { label: "tanker", type: VesselTypes.tanker, id: VESSEL_TYPES.TANKERS },
      {
        label: "chemical tanker",
        type: VesselTypes.chemical,
        id: VESSEL_TYPES.CHEMICALS,
      },
      {
        label: "container vessel",
        type: VesselTypes.container,
        id: VESSEL_TYPES.CONTAINERS,
      },
      { label: "other", type: VesselTypes.other, id: VESSEL_TYPES.OTHERS },
    ];
    return vesselTypes.find((item) => {
      return item.label.toLowerCase() === type.toLowerCase();
    });
  };

  const getHeaderElm = (item: any, index: number) => {
    const { vesselInfo, declaredValue, vesselType } =
      item.vesselDetails;
    const selectedVesselCard: any = getVesselType(vesselType) || {};
    if (vesselInfo) {
      const data = vesselInfo;
      return (
        <div className={"flex flex-wrap"}>
          <div className={"aq-vessel-info-header card-1 desktop-view"}>
            <h3 className={"counter"}>{index}</h3>
            <div className={"vessel-box-1"}>
              <h3>{data.vesselName}</h3>
              <div className={`aq-vessel-icon-${selectedVesselCard.type}`} />
              <div className={"imo-number-txt"}>
                IMO Number: {data.imoNumber}
              </div>
            </div>

            <div className={"vessel-box-2"}>
              <span className={"top-pixel"} />
              <div className={"vessel-box-lbl"}>{vesselType}</div>
              <label className={"lbl"}>
                {formatNumberWithCurrency(declaredValue)}
              </label>
              <div className={"flex btn-container"}>
                <AqButtonComponent
                  className={"btn-primary invert small"}
                  icon={"edit"}
                  label={"Edit"}
                  onClick={() => handleBtnAction("Edit", item)}
                />
                <AqButtonComponent
                  className={"btn-primary invert small"}
                  icon={"bin"}
                  label={"Delete"}
                  onClick={() => handleBtnAction("Delete", item)}
                />
              </div>
            </div>
          </div>

          <div className={"aq-vessel-info-header card-1 mob-view"}>
            <div className="topSection">
              <div className="image">
                <h3 className={"counter"}>{index}</h3>
                <div className={`aq-vessel-icon-${selectedVesselCard.type}`} />
              </div>
              <div className="detail">
                <h3>{data.vesselName}</h3>
                <div className={"imo-number-txt"}>
                  IMO Number: {data.imoNumber}
                </div>
                <span className={"top-pixel"} />
                <div className={"vessel-box-lbl"}>{vesselType}</div>
                <label className={"lbl"}>
                  {formatNumberWithCurrency(declaredValue)}
                </label>
              </div>
            </div>
            <div className={"flex btn-container"}>
              <AqButtonComponent
                className={"btn-primary invert small"}
                icon={"edit"}
                label={"Edit"}
                onClick={() => handleBtnAction("Edit", item)}
              />
              <AqButtonComponent
                className={"btn-primary invert small"}
                icon={"bin"}
                label={"Delete"}
                onClick={() => handleBtnAction("Delete", item)}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  const renderVessels = () => {
    if (!!!vessels || vessels.length === 0) {
      return;
    }
    return vessels.map((vessel: any, i: number) => {
      return (
        <div key={`__vs_${i}`} className={"vessel-item"}>
          {getHeaderElm(vessel, i + 1)}
        </div>
      );
    });
  };

  const onFormSubmit = async (formData: any) => {
    const amount = Number(
      currancyToNumber(formData.formState.requiredFunding.value)
    );

    const payload = {
      amount: amount,
      loanTermInMonths: formData.formState.loadTerm.value,
      drawDownDate: formData.formState.drawDownDate.value,
      workingCapital: parseInt(
        currancyToNumber(formData.formState.workingCapital.value)
      ),
    };

    var response = await addFundingHttp(payload);
    if (response.ok) {
      history.push(`/projects/${id}/experience`);
    }
    else {
      console.log('Error in submit funding');
    }
  };

  const getTotalCost = () => {
    if (vessels.length === 0) {
      return 0;
    }
    let cost = 0;
    vessels.forEach((intialVal: any) => {
      cost += parseInt(intialVal.vesselDetails.declaredValue);
    });
    return cost;
  };

  const getRequiredFunding = (val: number) => {
    if (!!val) {
      const totalCost = getTotalCost();
      return Math.round((totalCost * val) / 100);
    }

    return 0;
  };

  const getRequiredFundingPercentage = (val: number) => {
    if (!!val && getTotalCost()) {
      const totalCost = getTotalCost();
      return Math.round((val / totalCost) * 100);
    }
    return 0;
  };

  const renderFundingForm = (formData: any) => {
    const {
      drawDownDate,
      requiredFundingPercentage,
      requiredFunding,
      loadTerm,
      workingCapital,
    } = fields;

    const { formState, onFocus, onBlur, onChange, onSubmit } = formData;

    const recalcRequiredFunding = (percentage: number) => {
      const amount = getRequiredFunding(percentage);
      formState.requiredFunding.value = amount;
    };
    const recalcRequiredPercentage = (amount: number) => {
      const percentage = getRequiredFundingPercentage(amount);
      formState.requiredFundingPercentage.value = percentage;
    };

    fmSubmitRef = onSubmit;

    if (!formState) {
      return null;
    }

    if (!formState.requiredFundingPercentage.value) {
      recalcRequiredPercentage(formState.requiredFunding.value);
    }
    else if (!formState.requiredFunding.value) {
      recalcRequiredFunding(formState.requiredFundingPercentage.value);
    }

    return (
      <div className={"right-panel"}>
        <div className={"rt-title-box"}>
          <div className={"top-pixel"} />
          <div className={"rt-title"}>Vessel(s) Value</div>
          <div className={"rt-title-val"}>
            {formatNumberWithCurrency(getTotalCost())}
          </div>
        </div>
        <div className={"funding-box"}>
          <div style={{ marginBottom: '1em' }}>
            <label>How much funding is required?</label>
            <EsfSlider
              marks={[0, 10, 20, 30, 40, 50, 60, 65]}
              step={1}
              value={formState.requiredFundingPercentage.value}
              valueType={"%"}
              onchange={(value: number) => {
                onChange(requiredFundingPercentage.name, value);
                recalcRequiredFunding(value);
              }}
            />
          </div>
          <div>
            <AqInputfspl
              name={requiredFunding.name}
              value={formState.requiredFunding.value}
              error={
                formState.requiredFunding && formState.requiredFunding._errors
              }
              meta={requiredFunding.meta}
              onFocus={onFocus}
              onBlur={onBlur}
              type={"number"}
              placeholder={requiredFunding.placeholder}
              onChange={(name: string, value: any) => {
                onChange(name, value);
                recalcRequiredPercentage(currancyToNumber(value));
              }}
              currancy
            />
          </div>
        </div>
        <div className={"funding-box"}>
          <div>
            <label>Please select loan term</label>
          </div>
          <div className={"flex"}>
            <div className={"flexible left-slider"}>
              <EsfSlider
                marks={[36, 42, 48, 54, 60]}
                step={1}
                value={formState.loadTerm?.value ?? 10}
                valueType={"Month"}
                onchange={(value: number, percentage: number) => onChange(loadTerm.name, { value, percentage })}
              />
            </div>
            <AqInput
              name={loadTerm.name}
              inputStyle={{ width: 58, padding: 10 }}
              value={formState.loadTerm && formState.loadTerm.value}
              error={formState.loadTerm && formState.loadTerm._errors}
              onFocus={onFocus}
              type={"number"}
              onBlur={onBlur}
              placeholder={loadTerm.placeholder}
              onChange={onChange}
              min={formState.loadTerm.min}
              max={formState.loadTerm.max}
            />
          </div>
          <div className="mob-top-view">
            <AqDateComponent
              name={drawDownDate.name}
              label={drawDownDate.label}
              startDate={formState.drawDownDate && formState.drawDownDate.value}
              placeholder={drawDownDate.placeholder}
              error={formState.drawDownDate && formState.drawDownDate._errors}
              onBlur={onBlur}
              minDate={new Date()}
              onChange={onChange}
            />
          </div>
          <div>
            <AqInput
              name={workingCapital.name}
              value={formState.workingCapital && formState.workingCapital.value}
              label={workingCapital.label}
              meta={workingCapital.meta}
              placeholder={workingCapital.placeholder}
              error={
                formState.workingCapital && formState.workingCapital._errors
              }
              onFocus={onFocus}
              onBlur={onBlur}
              type={"number"}
              onChange={onChange}
              currancy
            />
          </div>
        </div>
      </div>
    );
  };

  const handleValidation = (field: any, value: any) => {
    if (/loadTerm|drawDownDate/.test(field.name)) {
      let val = Number(value);
      if (val && (val > field.max || val < field.min)) {
        return {
          error: `Value must be between ${field.min} & ${field.max}`,
        };
      }
    }
    if (field.required && !value) {
      return {
        error: field.error || "field is required",
      };
    }
    return undefined;
  };

  const renderFundingArea = () => {
    if (!initialValues) {
      return <div>Loading...</div>;
    }
    return (
      <AqFormComponent
        id={"funding-form"}
        fields={fields}
        initialValues={initialValues}
        onSubmit={onFormSubmit}
        validator={handleValidation}
        renderer={renderFundingForm}
      />
    );
  };

  const doDelete = () => {
    if (vesselNo) {
      deleteVesselHttp(vesselNo).then((res) => {
        cancelDelete();
        if (res.error) {
          console.error("[Error] in deleteVesselHttp", res.error);
        } else {
          getVessels(id);
        }
      });
    }
  };

  const cancelDelete = () => {
    setVesselNo(null);
  };

  const renderPopup = () => {
    if (!vesselNo) {
      return null;
    }
    return (
      <div className={"popup"}>
        <div className={"popup-content"}>
          Are you sure you want to delete this?
        </div>
        <div className={"popup-actions"}>
          <AqButtonComponent
            style={{ marginRight: 10 }}
            label={"Cancel"}
            className={"btn-primary medium invert"}
            onClick={cancelDelete}
          />
          <AqButtonComponent
            label={"Delete"}
            className={"btn-primary medium"}
            onClick={doDelete}
          />
        </div>
      </div>
    );
  };

  // Add other Vessel
  const addAnotherVessel = () => {
    console.log('addAnotherVessel', id, props);
    addVessel(id, props);
  };

  const vesselCount = vessels.length;
  const isNewVesselCountReached = vesselCount > 99;
  const btnTitle = isNewVesselCountReached
    ? "No more new vessel allowed"
    : "";

  return (
    <AqVesselFundingWrapper>
      <h3 className={"txt-center h3-title"}>Funding Requirements</h3>
      <div className={"flex vsl-container"}>
        <div className={"flexible vessel-list"}>
          {renderVessels()}
          <div className={"card-1"}>
            <div className={"aq-vessel-icon-4"}></div>
            <div>
              <AqButtonComponent
                className={"btn-neutral medium"}
                label={"+ Add another vessel"}
                disabled={isNewVesselCountReached}
                title={btnTitle}
                onClick={addAnotherVessel}
              />
            </div>
          </div>
        </div>
        <div className={"flexible"}>{renderFundingArea()}</div>
      </div>
      <AqFooter onButtonClick={handleFooterAction} />
      {renderPopup()}
    </AqVesselFundingWrapper>
  );
}

const mapStateToProps = (state: any) => {
  const { vessel } = state;
  const savedVessel = getSavedVessels(state);
  return {
    savedVessel,
    vessel,
  };
};

const mapDispatchToProps = { ...dispatchers };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AqVesselFundindComponent);
