import React from "react";
import { Component } from "react";
import AqButtonComponent from "../../../components/seeds/aq-button.component";
import AqFormComponent from "../../../components/seeds/aq-form.component";
import AqInput from "../../../components/seeds/aq-input.component";
import AqSelectComponent from "../../../components/seeds/aq-select.component";
import AqTableComponent from "../../../components/table/aq-table.component";
import AqUploadComponent from "../../../components/upload/aq-upload.component";
import {
  dateFormat,
  strictValidArray,
} from "../../../controller/utils/commonUtils";
import { uuidv4 } from "../../../helpers/utils";
import { onDocumentSubmit, onEditDocument } from "../../../controller/actions";
import {
  downloadDocumentHttp,
  geLookuptDocTypeList,
  deleteProjectDocument,
} from "../../../controller/https";

export default class DocumentPanel extends Component<any, any> {
  defaultFormId = "documentForm";
  uploadFileRef: any;
  formRef: any;

  constructor(props: any) {
    super(props);
    this.uploadFileRef = React.createRef();
    this.formRef = React.createRef();
    this.state = {
      updateMode: false,
      dataList: [],
      limit: 100,
      skip: 0,
      optionsValue: [],
      formId: this.defaultFormId,
      initialValues: {},
      btnDisabled: false,
      editMode: false,
      docId: "",
      fields: {
        documentType: {
          name: "documentType",
          label: "Document Type",
          required: true,
          placeholder: "Select document",
          // options: this.optionsValue,
        },
        document: { name: "document", required: true },
        description: {
          name: "description",
          label: "Description",
          placeholder: "Write a note",
        },
      },
      tableField: [
        {
          label: "Document Type",
          field: "documentType",
          // style: { width: 200 },
        },
        { label: "Description", field: "description" },
        { label: "Update By", field: "updatedByName" },
        {
          label: "Updated At",
          field: "updatedAt",
          // style: { width: 200 },
          renderer: (row: any, field: any) => row.isPending ? 'Update pending' : dateFormat(row.updatedAt),
        },
        {
          label: "Actions",
          field: "actions",
          // style: { width: 100 },
          renderer: this.fieldRenderer,
        },
      ],
    };
  }

  componentDidMount(): void {
    this.onSearch("");
    this.docTypeListOption();
  }

  docTypeListOption = async () => {
    const { projectId } = this.props.vessel;
    const res = await geLookuptDocTypeList(projectId);
    if (res) {
      const changedArray =
        strictValidArray(res) &&
        res.map((item: any) => ({
          label: item.documentType,
          value: item.documentType,
        }));
      this.setState({ optionsValue: changedArray || [] });
    }
    return [];
  };

  /**
   * search listener
   * @param ev
   */
  onSearch = async (val: any) => {
    const { limit, skip } = this.state;
    const { projectId } = this.props.vessel;
    this.setState({ searchValue: val });
    let query = `skip=${skip}&limit=${limit}&search=${val}`;
    const res = await this.props.existingDoc(query, projectId);
    if (res) {
      this.setState({ dataList: this.props.exixtingDoc });
      // this.createTblData(this.props.bookedMeeting)
    }
  };

  deleteDocument = async (id: any) => {
    const { projectId } = this.props.vessel;
    let res = await deleteProjectDocument(projectId, id);
    if (res.status) {
      this.onSearch("");
    } else {
      this.props.ShowError(res.message);
    }
  };

  editDocument = (row: any) => {
    let values = {
      description: row.description,
      document: row.fileName,
      documentType: row.documentType,
    };
    this.setState(
      { initialValues: values, docId: row.id, editMode: true },
      () => this.formRef.current.setFormFields()
    );
  };

  fieldRenderer = (row: any, field: any) => {
    const { projectId } = this.props.vessel;
    return (
      <>
        <span
          onClick={() => downloadDocumentHttp(projectId, row.id, row.fileName)}
          className={"icon-download"}
        />
        &nbsp;&nbsp;
        <span onClick={() => this.editDocument(row)} className={"icon-edit"} />
        &nbsp;
        <span
          onClick={() => this.deleteDocument(row.id)}
          className={"icon-trash"}
        />
      </>
    );
  };

  onSubmit = async (formdata: any, id: string, afterSubmit: any) => {
    this.setState({ btnDisabled: true });
    const { initialValues = {}, docId, editMode } = this.state;
    const { projectId } = this.props.vessel;
    const payload: any = {
      vesselNo: 0,
      employmentNo: 0,
      ...initialValues,
    };
    Object.keys(formdata.formState).forEach((key: string) => {
      if (formdata.formState[key].value) {
        payload[key] = formdata.formState[key].value;
      }
    });
    payload.fileName = payload.document.name;
    let res: any;
    if (docId && editMode) {
      res = await onEditDocument(payload, projectId, docId);
    } else {
      res = await onDocumentSubmit(payload, projectId);
    }
    // console.log("res", res, this.props);
    // this.setState({ btnDisabled: false });
    if (res && res.error) {
      this.props.ShowError(res.error);
      this.setState({ btnDisabled: false, editMode: false, docId: "" });
    } else {
      if (res && afterSubmit) {
        afterSubmit();
        this.setState({
          initialValues: undefined,
          btnDisabled: false,
          editMode: false,
          docId: "",
        });
        this.uploadFileRef.current.removeFiles();
        this.onSearch("");
      }
    }
    // });
  };

  onCancel = () => {
    this.props.onCancel();
    this.setState({ btnDisabled: false });
  };

  renderFields = (fc: any) => {
    const { formState, onFocus, onBlur, onChange, onSubmit } = fc;
    if (!formState) {
      return null;
    }
    const { fields, updateMode, btnDisabled } = this.state;
    const { projectId } = this.props.vessel;
    const url = `api/projects/${projectId}/documents/${1}/content`;
    console.log("formState +++", formState);
    return (
      <div className={"field-renderer"}>
        <br />
        <div>
          <div className={"span-6"}>
            <AqSelectComponent
              name={fields.documentType.name}
              label={fields.documentType.label}
              defaultValue={fields.documentType.placeholder}
              value={formState.documentType && formState.documentType.value}
              error={
                formState["documentType"] && formState["documentType"]._errors
              }
              options={this.state.optionsValue}
              onChange={onChange}
            />
          </div>
        </div>
        <div>
          <div className={"span-6"}>
            <AqUploadComponent
              name={fields.document.name}
              error={formState["document"] && formState["document"]._errors}
              files={
                formState.document &&
                !!formState.document.value && [
                  {
                    name: formState.document.value.name,
                    value: { value: formState.document.value },
                  },
                ]
              }
              // files={[{name: 'sample.png', value: '{"id": "sdsg"}'}]}
              ref={this.uploadFileRef}
              onChange={onChange}
              uploadFileUrl={url}
              passFile
            />
          </div>
        </div>
        <br />
        <div className={"span-8"}>
          <AqInput
            name={fields.description.name}
            placeholder={fields.description.placeholder}
            value={formState.description && formState.description.value}
            error={formState["description"] && formState["description"]._errors}
            onFocus={onFocus}
            type={"textarea"}
            label={fields.description.label}
            meta={fields.description.meta}
            onBlur={onBlur}
            inputStyle={{ height: 100 }}
            onChange={onChange}
          />
        </div>
        <div className={"flex"}>
          <AqButtonComponent
            label={"Cancel"}
            style={{ marginRight: 16 }}
            className={"btn-primary invert medium"}
            onClick={this.onCancel}
            disabled={btnDisabled}
          />
          {!updateMode && (
            <AqButtonComponent
              label={"Submit"}
              onClick={onSubmit}
              className={"btn-neutral medium"}
              disabled={btnDisabled}
            />
          )}
          {updateMode && (
            <AqButtonComponent
              label={"Update"}
              onClick={onSubmit}
              className={"btn-neutral medium"}
            />
          )}
        </div>
      </div>
    );
  };

  getForm = () => {
    const { fields } = this.state;
    const { initialValues = {}, formId } = this.state;
    return (
      <AqFormComponent
        id={formId}
        key={formId}
        fields={fields}
        initialValues={initialValues}
        ref={this.formRef}
        onSubmit={this.onSubmit}
        renderer={this.renderFields}
      />
    );
  };

  getTable = () => {
    const { tableField, dataList } = this.state;
    // const { vessel = {} } = this.props;
    // const data = vessel.documents || [];
    return (
      <AqTableComponent
        fields={tableField}
        data={dataList || []}
        titleKey="documentType"
      />
    );
  };

  cancel = () => {
    this.props.onCancel();
  };

  render(): React.ReactNode {
    const { projectId } = this.props.vessel;
    return (
      <div>
        <div className={"flex title-margin"}>
          <div className={"tt icon"} onClick={this.cancel}>
            # {projectId}
          </div>
          <div className={"flexible"} />
        </div>
        <div className={"aq-toolbar"}>
          <div className={"flexible project-title"}>Project Documents</div>
        </div>
        <div>{this.getForm()}</div>
        <br />
        <br />
        <div>{this.getTable()}</div>
      </div>
    );
  }
}
