import {
  ADD_NEW_VESSEL,
  ADD_VESSEL_EMPLOYMENT,
  DELETE_VESSEL_EMPLOYMENT,
  ON_DUPLICATE_VESSEL,
  ON_EDIT_VESSEL,
  ON_VESSEL_SELECT,
  ON_VESSELS_FETCH,
  ON_VESSELS_FORM_SUBMIT,
  ON_VESSELS_OPEX_FORM_SUBMIT,
  RESET_VESSEL,
  SET_CONTEXT,
  UPDATE_VESSEL_EMPLOYMENT,
  PROJECT_PROGRESS,
} from "../../constants/action-types";
import { IAction, IVesselState } from "../models";
import { VesselsState } from "../states";
import {
  onAddNewVessel,
  onDeleteVesselEmployment,
  onDuplicateVessel,
  onEditNewVessel,
  onSubmitVesselForm,
  onUpdateVesselEmployment,
  onVesselEmployment,
  onVesselFetch,
  onVesselOpexFormSubmit,
  onVesselReset,
  onVesselSelect,
} from "./reducer-helper/vessel-helpers";

const vesselSessionKey = "__vessels__";
const getInitialState = () => {
  try {
    const persistState = sessionStorage.getItem(vesselSessionKey);
    if (persistState) {
      return JSON.parse(persistState);
    } else {
      return VesselsState;
    }
  } catch (e) {
    return VesselsState;
  }
};

const initialState = getInitialState();

function vessels(state: IVesselState = initialState, action: IAction) {
  let newState;
  switch (action.type) {
    case ADD_NEW_VESSEL:
      newState = onAddNewVessel(action, state);
      break;
    case RESET_VESSEL:
      newState = onVesselReset();
      break;
    case ON_EDIT_VESSEL:
      newState = onEditNewVessel(action, state);
      break;
    case ADD_VESSEL_EMPLOYMENT:
      newState = onVesselEmployment(action, state);
      break;
    case UPDATE_VESSEL_EMPLOYMENT:
      newState = onUpdateVesselEmployment(action.payload, state);
      break;
    case DELETE_VESSEL_EMPLOYMENT:
      newState = onDeleteVesselEmployment(action.payload, state);
      break;
    case ON_VESSELS_FETCH:
      newState = onVesselFetch(action, state);
      break;
    case ON_VESSELS_FORM_SUBMIT:
      newState = onSubmitVesselForm(action, state);
      break;
    case ON_VESSELS_OPEX_FORM_SUBMIT:
      newState = onVesselOpexFormSubmit(action, state);
      break;
    case ON_DUPLICATE_VESSEL:
      newState = onDuplicateVessel(action, state);
      break;
    case ON_VESSEL_SELECT:
      newState = onVesselSelect(action, state);
      break;
    case SET_CONTEXT:
      newState = { ...state, selected: action.payload };
      break;
    case PROJECT_PROGRESS:
      newState = { ...state, progress: action.payload };
      break;
    default:
      newState = state;
  }
  sessionStorage.setItem(vesselSessionKey, JSON.stringify(newState));
  return newState;
}

export default vessels;
