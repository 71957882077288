import React, { PureComponent } from 'react';

import styled from 'styled-components';
import { Line, NegativeChange, Neutral, Primary } from '../../Styles';

const CheckboxContainer = styled.div`
  position: relative;
  
  label {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .error-txt {
    color: ${NegativeChange};
    position: absolute;
    top: 32px;
  }
  .chk {
    width: 24px;
    height: 24px;
    border: 1px solid ${Line};
    appearance: none;
    border-radius: 2px;
    margin: 5px 8px 5px 2px;
    
    &.has-error {
      border-color: ${NegativeChange};
    }
    
    &:focus {
      outline: 1px solid ${Neutral};
    }
    &.checked {
      background-image: url(${process.env.PUBLIC_URL + '/icons/tick.png'});
      background-repeat: no-repeat;
      background-position: 3px 4px;
      background-color: ${Neutral};
      background-size: 16px;
      
      &.inverted {
        background-color: ${Primary};
        
        &:focus {
          outline: 1px solid ${Primary};
        }
      }
    }
  }
`

const CheckInput = (props: any) => {
  return (
    <input type={'checkbox'} {...props} className={`chk ${props.className}`}/>
  )
}

interface ICheckboxProps {
  name: string;
  label: any;
  error?: any;
  primary?: boolean;
  checked?: boolean;
  onChange?: any;
  inputStyle?: any;
}

export default class AqCheckboxComponent extends PureComponent<ICheckboxProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      checked: props.checked
    }
  }

  handleCheckboxChange = (event: any) => {
    this.setState({checked: event.target.checked});
    this.props.onChange && this.props.onChange(this.props.name, event.target.checked);
  }

  getClass = (): string => {
    let cls = '';
    if (this.state.checked) {
      cls = 'checked';
    }
    if (this.props.error) {
      cls += ' has-error';
    }
    if (this.props.primary) {
      cls += ' inverted'
    }
    return cls;
  }

  render() {
    const {label, error, inputStyle} = this.props;
    const clsName = this.getClass();
    return (
      <CheckboxContainer style={inputStyle}>
        <label>
          <CheckInput
            className={clsName}
            checked={this.state.checked}
            onChange={this.handleCheckboxChange}
          />
          <span>{label}</span>
        </label>
        {error && <div className={'error-txt'}>{error.error}</div>}
      </CheckboxContainer>
    )
  }
}