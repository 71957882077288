// import React from 'react';
// import { Link } from 'react-router-dom';
import moment from 'moment';

export const dateFormat = val => (val ? moment(val).format("DD MMM YYYY") : "");
export const dateTimeFormat = val =>
    val ? moment(val).format("DD MMM YYYY h:mm A") : "";

/**
 * Checks if a valid string;
 * @param val: number/string/object/array != (undefined or null)
 */
export const validValue = val =>
  typeof val !== 'undefined' && val !== undefined && val !== null;

/**
 * Serailize json to query string
 * @param {*} object
 */
export const jsonToQueryString = obj =>
  strictValidObject(obj) &&
  Object.keys(obj)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join('&');

/**
 * Checks if a valid string
 * @param str: string
 */
export const strictValidString = str => !!str && typeof str === 'string';

/**
 * Checks if a valid string and validate with min length.
 * @param str: string
 */
export const strictValidStringWithMinLength = (str, length = 1) =>
  !!str && typeof str === 'string' && str.length >= length;


/**
 * Checks if a valid array
 * @param arr: array
 */
export const strictValidArray = arr => arr && Array.isArray(arr);

/**
 * Checks if a valid array with minimum specified length
 * @param arr: array
 * @param minLength: integer
 */
export const strictValidArrayWithMinLength = (arr, minLength) =>
  strictValidArray(arr) && arr.length >= minLength;

/**
 * Checks if a valid array with length
 * @param arr: array
 */
export const strictValidArrayWithLength = arr =>
  strictValidArray(arr) && !!arr.length;

/**
 * Checks if a valid object
 * @param obj: object
 */
export const strictValidObject = obj =>
  obj &&
  obj === Object(obj) &&
  Object.prototype.toString.call(obj) !== '[object Array]';

/**
 * Checks if a valid object with keys
 * @param obj: object
 */
export const strictValidObjectWithKeys = obj =>
  strictValidObject(obj) && !!Object.keys(obj).length;

  /**
 * Checks if a valid object with specified keys
 * @param obj: object
 * @param parameterKeys: array
 */
export const validObjectWithParameterKeys = (obj, parameterKeys = []) =>
strictValidObjectWithKeys(obj) &&
strictValidArrayWithLength(parameterKeys) &&
Object.keys(obj).filter(k => parameterKeys.indexOf(k) > -1).length ===
  parameterKeys.length;


/**
 * Formatting number for thousand seperator
 *
 */
export const formatNumberWithCurrency = val => Number(val).toLocaleString('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

export const currancyToNumber = val => validValue(val) && val.toString().replaceAll(",","");


export const replaceDollarcurrancyToNumber = val => validValue(val) && val.toString().replaceAll("$","");

/**
 * Checks if given value is function
 * @param fn: function
 */
export const isFunction = fn => validValue(fn) && typeof fn === 'function';

export const getIntegerValue = val => Math.round(val);

/**
 * Round off an Integer to 2 decimal places
 * @param value: Integer
 */
export const roundOffTo2Decimals = value => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
};

/**
 * print address
 * @param {*} str
 * @param {*} length
 */
export const fullAddress = ( address = {} ) => {
	let {
		location = '',
		// address_2 = '',
		city = '',
		state = '',
		zipcode = ''
	} = address;
	location = strictValidStringWithMinLength(location, 1) && `${location}` + ', ' || '';
	// address_2 = strictValidStringWithMinLength(address_2, 1) && `${address_2}` + ', ' || '';
	city = strictValidStringWithMinLength(city, 1) && `${city}` + ', ' || '';
	state = strictValidStringWithMinLength(state, 1) && `${state}` || '';
	zipcode = strictValidStringWithMinLength(zipcode, 1) && ', ' + `${zipcode}` || '';
	return `${location}${city}${state}${zipcode}`
}
