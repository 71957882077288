import React, { Component } from "react";
import styled from "styled-components";
import {
  FontSize14,
  FontSize18,
  Light,
  Line,
  Neutral,
  Primary,
  White,
} from "../../Styles";
import AqButtonComponent from "../seeds/aq-button.component";

const AqStepWrapper = styled.div`
  display: flex;
  padding: 30px;

  .aq-step-wrapper {
    position: relative;
    margin: 0 auto;
  }

  .steps {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    font-size: ${FontSize18};

    .flexible:last-child {
      display: none;
    }

    .step {
      z-index: 1;
    }

    .step:first-child {
      transform: translateX(-40%);
    }
    .step:last-child {
      transform: translateX(40%);
    }

    .step-content {
      text-align: center;

      .step-count {
        height: 40px;
        width: 40px;
        border-radius: 6px;
        line-height: 40px;
        margin: 0 auto;
        z-index: 1;
        cursor: text;
        color: ${White};
        background-image: url(${process.env.PUBLIC_URL +
        "/icons/polygen-disable.png"});
        background-position-x: 6px;
        font-family: "Rubik-Medium";
        background-repeat: no-repeat;
      }

      .step-count-mob {
        display: none;
      }

      &.selected {
        font-family: "Rubik-Medium";

        .step-count {
          background-image: url(${process.env.PUBLIC_URL +
          "/icons/polygen.png"});
        }
      }

      .step-base {
        span {
          height: 6px;
          background-color: ${Line};
          display: inline-block;
          width: 58px;
          border-radius: 6px;
          margin-right: 5px;

          &.selected {
            background-color: ${Neutral};
          }
        }
      }
    }
  }

  .divider {
    background: ${Light};
    width: 100%;
    height: 1px;
    top: 18px;
    position: absolute;
  }
  @media screen and (max-width: 677px) {
    padding: 30px 10px;
    .aq-step-wrapper {
      .divider {
        display: none;
      }
      .steps {
        background: #f2f2f2;
        border-radius: 6px;
        overflow: hidden;
        .step {
          transform: none;
          &:nth-child(2) {
            border-left: 1px solid #dedede;
            border-right: 1px solid #dedede;
          }
          .step-content {
            display: flex;
            height: 100%;
            min-height: 71px;
            width: 100%;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            padding: 10px 9px;
            box-sizing: border-box;
            color: #a3a3a3;
            .step-count,
            > .step-base {
              display: none;
            }
            .step-count-mob {
              display: flex;
              .step-base {
                margin-left: 10px;
                > span {
                  width: 22px;
                  background-color: ${White};
                  border: 1px solid ${White};
                  box-sizing: border-box;
                  font-family: "Rubik";
                }
              }
              > span {
                font-family: "Rubik";
              }
              > div {
                font-family: "Rubik-Medium";
              }
            }

            > div {
              font-size: 14px;
              white-space: nowrap;
            }
            &.selected {
              background-color: ${Neutral};
              color: ${White};
              .step-count-mob {
                .step-base {
                  > span {
                    width: 22px;
                    background-color: ${Neutral};
                    border: 1px solid ${White};
                    box-sizing: border-box;
                    font-family: "Rubik";
                    &.selected {
                      background-color: ${White};
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

interface IStepProps {
  steps: Array<any>;
  onStepChange: any;
  width: number;
}

export default class AqStepComponent extends Component<IStepProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      steps: props.steps,
    };
  }

  handleStepChange = (step: any) => {
    this.props.onStepChange(step);
  };

  getClass = (step: any): string => {
    return step.selected ? "selected" : "";
  };

  getSteps = () => {
    const { steps } = this.state;
    return steps.map((step: any, i: number) => (
      <div key={`step-${i}`} className={"step"}>
        <div
          onClick={() => this.handleStepChange(step)}
          className={`step-content ${step.selected ? "selected" : ""}`}
        >
          <div className={"step-count"}>{i + 1}</div>
          <div className={"step-count-mob"}>
            <span>STEP 0{i + 1}</span>
            {step.steps && (
              <div className={"step-base"}>
                {step.steps.map((s: any, i: number) => (
                  <span key={`substep_${i}`} className={this.getClass(s)} />
                ))}
              </div>
            )}
          </div>
          <div>{step.label}</div>
          {step.steps && (
            <div className={"step-base"}>
              {step.steps.map((s: any, i: number) => (
                <span key={`substep_${i}`} className={this.getClass(s)} />
              ))}
            </div>
          )}
        </div>
        <div className={"flexible"} />
      </div>
    ));
  };

  render(): React.ReactNode {
    const { width } = this.props;
    return (
      <AqStepWrapper>
        <div style={{ width: width }} className={"aq-step-wrapper"}>
          <div className={"divider"}></div>
          <div className={"steps"}>{this.getSteps()}</div>
        </div>
      </AqStepWrapper>
    );
  }
}
