import React, { PureComponent } from "react";
import styled from "styled-components";
import { BadgeIcons } from "../../controller/models";

import {
  FieldBottomMargin,
  FontFamilyMedium,
  InputBorderRadius,
  Line,
  NegativeChange,
  Neutral,
  Primary,
  TxtSize,
  White,
} from "../../Styles";

const AqBadgeWrapper = styled.div`
  font-size: ${TxtSize};
  margin-bottom: ${FieldBottomMargin};

  div {
    height: 60px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    border: 1px solid ${Line};
    border-radius: ${InputBorderRadius};
    box-sizing: border-box;
    box-shadow: 1px 2px 4px #dcdcdc;

    &:active {
      box-shadow: inset 1px 2px 2px #dcdcdc;
    }

    label {
      font-family: ${FontFamilyMedium};
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;

      .icon-bulk {
        padding-right: 10px;
        width: 58px;
        content: url(${process.env.PUBLIC_URL + "/icons/ship/carrier.svg"});
      }
      .icon-tanker {
        padding-right: 10px;
        width: 58px;
        content: url(${process.env.PUBLIC_URL + "/icons/ship/tanker.svg"});
      }
      .icon-other {
        padding-right: 10px;
        width: 58px;
        height: 50px;
        content: url(${process.env.PUBLIC_URL + "/icons/ship/other.svg"});
      }
      .icon-chemical {
        padding-right: 10px;
        width: 58px;
        height: 45px;
        content: url(${process.env.PUBLIC_URL + "/icons/ship/chemical.svg"});
      }
      .icon-container {
        padding-right: 10px;
        width: 58px;
        content: url(${process.env.PUBLIC_URL + "/icons/ship/cargo.svg"});
      }

      .flex {
        flex: 1;
      }

      .chk {
        width: 24px;
        height: 24px;
        appearance: none;
        border-radius: 2px;
        margin: 5px 8px 5px 2px;
        background-image: url(${process.env.PUBLIC_URL + "/icons/circle.png"});

        &.has-error {
          border-color: ${NegativeChange};
        }

        &:focus {
          outline: 1px solid ${Neutral};
        }
        &.checked {
          background-image: url(${process.env.PUBLIC_URL +
          "/icons/check-circle.png"});
          background-repeat: no-repeat;
          background-size: 24px;

          &.inverted {
            background-color: ${Primary};

            &:focus {
              outline: 1px solid ${Primary};
            }
          }
        }
      }
    }

    &.selected {
      color: ${White};
      background-color: ${Primary};

      .icon-bulk {
        content: url(${process.env.PUBLIC_URL +
        "/icons/ship/carrier-white.svg"});
      }
      .icon-tanker {
        content: url(${process.env.PUBLIC_URL +
        "/icons/ship/tanker-white.svg"});
      }
      .icon-other {
        content: url(${process.env.PUBLIC_URL + "/icons/ship/other-white.svg"});
      }
      .icon-chemical {
        content: url(${process.env.PUBLIC_URL +
        "/icons/ship/chemical-white.svg"});
      }
      .icon-container {
        content: url(${process.env.PUBLIC_URL + "/icons/ship/cargo-white.svg"});
      }
    }
  }
`;

interface IAqBadgeProps {
  icon: BadgeIcons;
  label: string;
  name: string;
  inputOnLeft?: boolean;
  onChange: any;
  selected?: boolean;
}

const CheckInput = (props: any) => {
  return (
    <input type={"checkbox"} {...props} className={`chk ${props.className}`} />
  );
};

class AqBadgeComponent extends PureComponent<IAqBadgeProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  getIcon = (): { wrapper: string; icon: string; input: string } => {
    const { icon, selected } = this.props;
    const className = {
      icon: "",
      input: "chk",
      wrapper: "",
    };
    if (selected) {
      className.input += " checked";
      className.wrapper += " selected";
    }
    if (icon !== undefined) {
      className.icon = ` icon-${BadgeIcons[icon]}`;
    }
    return className;
  };

  handleCheckboxChange = (event: any) => {
    this.props.onChange(this.props.name, event.target.checked);
  };

  render() {
    const { label, inputOnLeft, selected } = this.props;
    const cls = this.getIcon();
    return (
      <AqBadgeWrapper>
        <div className={cls.wrapper}>
          <label>
            {inputOnLeft && (
              <CheckInput
                className={cls.input}
                checked={selected}
                onChange={this.handleCheckboxChange}
              />
            )}
            <span className={cls.icon} />
            <span className={"flex"}>{label}</span>
            {!inputOnLeft && (
              <CheckInput
                className={cls.input}
                checked={selected}
                onChange={this.handleCheckboxChange}
              />
            )}
          </label>
        </div>
      </AqBadgeWrapper>
    );
  }
}

const AqBadge = AqBadgeComponent;

export default AqBadge;
