import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { dashboardReducer, rootReducer, savedReducer, vesselReducer, loginReducer } from '../reducers';

const combinedState = combineReducers({
  root: rootReducer,
  vessel: vesselReducer,
  dashboard: dashboardReducer,
  done: savedReducer,
  login: loginReducer
});
const config = {
  basePath: 'http://localhost:9091/',
  paths: {
    vessels: 'vessels'
  }
};


const store = createStore(combinedState, applyMiddleware(thunkMiddleware.withExtraArgument({config})));
export default store;
