/**
 * Wrapper on session storage to return the value of provided session key
 * @param key
 * @param isJson
 */
export const getSession = function (key: string, isJson?: boolean) {
  const val = sessionStorage.getItem(key);
  try {
    if (!!val) {
      return isJson ? JSON.parse(val) : val;
    }
  } catch (e) {
    return isJson ? {} : undefined;
  }
};

/**
 * Wrapper on session storage to set the value of provided session key
 * @param key
 * @param value
 * @param isJson
 */
export const setSession = function (key: string, value: any, isJson?: boolean) {
  try {
    const val = isJson ? JSON.stringify(value) : value;
    sessionStorage.setItem(key, val)
  } catch (e) {
    // logger;
  }
};

export const getSavedVessels = (state: any) => {
  if (state) {
    return state.done.vessels
  }
  return [];
};
export const getSelectedVessel = (props: any) => {
  const {vessel} = props;
  if (vessel) {
    const {selected} = vessel;
    const selectedContext = vessel[selected.context];
    const selectedCarrier = selectedContext && selectedContext.find((item: any) => item.id === vessel.selected.id);
    if (!selectedCarrier && vessel[selected.context] && vessel[selected.context].length) {
      // return vessel[selected.context][0];
      return {};
    }
    return selectedCarrier;
  }
  return {};
};


export const getSelectContext = function (state: any) {
  const {selected} = state;
  const selectedItem = state[selected.context];
  if (selectedItem && selectedItem.length) {
    return selectedItem.find((item: any) => item.id === selected.id) || selectedItem[0];
  }
};

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
