import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { AqFooter } from "../../../components/footer/aq-footer";
import AqCheckboxComponent from "../../../components/seeds/aq-checkbox.component";
import AqFormComponent from "../../../components/seeds/aq-form.component";
import AqInput from "../../../components/seeds/aq-input.component";
import AqRadioComponent from "../../../components/seeds/aq-radio.component";
import actionDispatcher from "../../../controller/actions";

const AqComanyExp = styled.div`
  text-align: left;
  width: 420px;
  margin: 0 auto;

  .fm-content {
    margin-bottom: 16px;
  }

  .txt-regular {
    label {
      font-family: "Rubik";
    }
  }

  .row-cell {
    .input-field {
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 677px) {
    padding: 0 16px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 70px;

    .txt-regular {
      display: block;
    }

    .row-cell {
      display: block;
      margin-bottom: 16px;

      .input-field {
        margin: 0;
      }
    }

    .emp {
      display: block;
    }
  }
`;

class AqCompanyExperienceComponent extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      initialValues: {
        hasShip: "Yes, Currently",
        hasTechnicalManagement: "No",
      },
      fields: {
        hasShip: {
          name: "hasShip",
          label: "Does your company owns ships?",
          options: [
            { label: "Yes, Currently", value: "Yes, Currently" },
            { label: "No", value: "No" },
            { label: "Yes, In the past", value: "Yes, In the past" },
          ],
        },
        isDryShips: { name: "isDryShips", label: "Dry" },
        dryShips: { name: "dryShips", label: "" },
        tankShips: { name: "tankShips", label: "" },
        isTankShips: { name: "isTankShips", label: "Tank" },
        isContShips: { name: "isContShips", label: "Cont" },
        contShips: { name: "contShips", label: "" },
        isOtherShips: { name: "isOtherShips", label: "Other" },
        otherShips: { name: "otherShips", label: "" },
        hasTechnicalManagement: {
          name: "hasTechnicalManagement",
          label: "Do you have your own Technical Management?",
          options: [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ],
        },
        commercialManagement: {
          name: "commercialManagement",
          label: "Commercial Manager if Outsourced",
        },
        technicalManagementBy: {
          name: "technicalManagementBy",
          label: "",
          placeholder: "Managed by",
        },
        totalEmployeeStrength: {
          name: "totalEmployeeStrength",
          label: "",
          placeholder: "No. of crew",
        },
        totalShoredEmployeeStrength: {
          name: "totalShoredEmployeeStrength",
          label: "What is your total employee strength globally?",
          placeholder: "No. of shore based",
        },
      },
    };
  }

  fmSubmitRef: any;
  projectId: any;

  componentDidMount() {
    this.projectId = this.props.match.params.id;
    this.props.getCompanyExperience().then(
      (res: any) => {
        const fields = { ...this.state.fields };
        fields.technicalManagementBy.required =
          res.hasTechnicalManagement === "no";
        this.setState({ fetched: true, initialValues: res, fields });
      },
      (error: any) => {
        this.setState({ fetched: true });
      }
    );
  }

  onSubmitForm = (formData: any) => {
    const { formState } = formData;
    const payload: any = {
      shipsOwnership: formState.hasShip.value,
      commercialManagement: {
        isOwning: true,
        name: formState.commercialManagement.value,
      },
      technicalManagement: {
        isOwning: formState.hasTechnicalManagement.value === "yes",
      },
      shoreBasedEmployeesNumber: formState.totalShoredEmployeeStrength.value,
      crewNumber: formState.totalEmployeeStrength.value,
    };
    if (formState.hasTechnicalManagement.value === "no") {
      payload.technicalManagement.name = formState.technicalManagementBy.value;
    }
    if (formState.hasShip.value !== "No") {
      const shipsData = this.getShipsPayload(formState);
      if (shipsData.isValid) {
        payload.shipNumbers = shipsData.payload;
      } else {
        this.setState({
          error: "Alteast one of ship type value should be provided.",
        });
        return;
      }
    }
    this.props.addCompanyExperience(payload).then((res: any) => {
      this.props.history.push(`/projects/${this.projectId}/done`);
    });
  };

  getShipsPayload = (formState: any) => {
    const payload: any = {};
    let isValid = false;
    if (formState.isDryShips.value && formState.dryShips.value > 0) {
      payload.dry = formState.dryShips.value;
      isValid = true;
    }
    if (formState.isTankShips.value) {
      payload.tank = !!formState.tankShips.value
        ? formState.tankShips.value
        : 0;
      isValid = true;
    }
    if (formState.isContShips.value) {
      payload.cont = !!formState.contShips.value
        ? formState.contShips.value
        : 0;
      isValid = true;
    }
    if (formState.isOtherShips.value) {
      payload.other = !!formState.otherShips.value
        ? formState.otherShips.value
        : 0;
      isValid = true;
    }
    return { payload, isValid };
  };

  handleFooterAction = (ev: any) => {
    if (ev === "back") {
      this.props.history.goBack();
    } else {
      this.fmSubmitRef();
      // this.opexFormSubmit();
    }
  };

  renderFormElm = (formData: any) => {
    const { fields, error } = this.state;
    const {
      hasShip,
      dryShips,
      tankShips,
      contShips,
      isDryShips,
      isTankShips,
      isContShips,
      isOtherShips,
      otherShips,
      hasTechnicalManagement,
      commercialManagement,
      technicalManagementBy,
      totalEmployeeStrength,
      totalShoredEmployeeStrength,
    } = fields;
    const { formState, onFocus, onBlur, onChange, onSubmit } = formData;
    if (!formState) {
      return null;
    }
    this.fmSubmitRef = onSubmit;
    const initialValues = formState;
    const hasShipInitialValue =
      initialValues.hasShip && initialValues.hasShip.value;

    return (
      <div className={"elm-container"}>
        <div className={"fm-content"}>
          <label>Does your company owns ships?</label>
          <div className={"flex txt-regular"}>
            {hasShip.options.map((item: any, i: number) => (
              <AqRadioComponent
                key={`_fm_exp_rd_${i}`}
                name={hasShip.name}
                checked={hasShipInitialValue === item.value}
                label={item.label}
                value={item.value}
                onChange={onChange}
              />
            ))}
          </div>
        </div>
        {hasShipInitialValue !== "No" && (
          <div className={"fm-content"}>
            <label>Specify sectors and number of ships</label>
            <div className={"row"}>
              <div className={"span-6 row-cell"}>
                <div className={"flex flex-align-center"}>
                  <AqCheckboxComponent
                    inputStyle={{ width: 100 }}
                    checked={
                      initialValues.isDryShips && initialValues.isDryShips.value
                    }
                    name={isDryShips.name}
                    label={isDryShips.label}
                    onChange={onChange}
                  />
                  <AqInput
                    name={dryShips.name}
                    value={
                      initialValues.dryShips && initialValues.dryShips.value
                    }
                    error={
                      initialValues["dryShips"] &&
                      initialValues["dryShips"]._errors
                    }
                    onFocus={onFocus}
                    label={dryShips.label}
                    onBlur={onBlur}
                    type={"number"}
                    disabled={
                      !(
                        initialValues.isDryShips &&
                        initialValues.isDryShips.value
                      )
                    }
                    meta={dryShips.meta}
                    inputStyle={{ width: 90 }}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className={"span-6 row-cell"}>
                <div className={"flex flex-align-center"}>
                  <AqCheckboxComponent
                    inputStyle={{ width: 100 }}
                    checked={
                      initialValues.isTankShips &&
                      initialValues.isTankShips.value
                    }
                    name={isTankShips.name}
                    label={isTankShips.label}
                    onChange={onChange}
                  />
                  <AqInput
                    name={tankShips.name}
                    value={
                      initialValues.tankShips && initialValues.tankShips.value
                    }
                    error={
                      initialValues["tankShips"] &&
                      initialValues["tankShips"]._errors
                    }
                    onFocus={onFocus}
                    label={tankShips.label}
                    onBlur={onBlur}
                    type={"number"}
                    inputStyle={{ width: 90 }}
                    meta={tankShips.meta}
                    disabled={
                      !(
                        initialValues.isTankShips &&
                        initialValues.isTankShips.value
                      )
                    }
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className={"span-6 row-cell"}>
                <div className={"flex flex-align-center"}>
                  <AqCheckboxComponent
                    inputStyle={{ width: 100 }}
                    checked={
                      initialValues.isContShips &&
                      initialValues.isContShips.value
                    }
                    name={isContShips.name}
                    label={isContShips.label}
                    onChange={onChange}
                  />
                  <AqInput
                    name={contShips.name}
                    value={
                      initialValues.contShips && initialValues.contShips.value
                    }
                    error={
                      initialValues["contShips"] &&
                      initialValues["contShips"]._errors
                    }
                    onFocus={onFocus}
                    label={contShips.label}
                    onBlur={onBlur}
                    type={"number"}
                    inputStyle={{ width: 90 }}
                    meta={contShips.meta}
                    disabled={
                      !(
                        initialValues.isContShips &&
                        initialValues.isContShips.value
                      )
                    }
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className={"span-6 row-cell"}>
                <div className={"flex flex-align-center"}>
                  <AqCheckboxComponent
                    inputStyle={{ width: 100 }}
                    checked={
                      initialValues.isOtherShips &&
                      initialValues.isOtherShips.value
                    }
                    name={isOtherShips.name}
                    label={isOtherShips.label}
                    onChange={onChange}
                  />
                  <AqInput
                    name={otherShips.name}
                    value={
                      initialValues.otherShips && initialValues.otherShips.value
                    }
                    error={
                      initialValues["otherShips"] &&
                      initialValues["otherShips"]._errors
                    }
                    onFocus={onFocus}
                    label={otherShips.label}
                    onBlur={onBlur}
                    type={"number"}
                    inputStyle={{ width: 90 }}
                    meta={otherShips.meta}
                    disabled={
                      !(
                        initialValues.isOtherShips &&
                        initialValues.isOtherShips.value
                      )
                    }
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
            {error && <div className={"error-txt"}>{error}</div>}
          </div>
        )}
        <div className={"fm-content"}>
          <label>{hasTechnicalManagement.label}</label>
          <div className={"flex flex-align-center row-cell"}>
            {hasTechnicalManagement.options.map((item: any, i: number) => (
              <AqRadioComponent
                key={`_fm_exp_tec_rd_${i}`}
                name={hasTechnicalManagement.name}
                checked={
                  initialValues.hasTechnicalManagement &&
                  initialValues.hasTechnicalManagement.value === item.value
                }
                label={item.label}
                value={item.value}
                onChange={onChange}
              />
            ))}
            {initialValues.hasTechnicalManagement &&
              initialValues.hasTechnicalManagement.value === "no" && (
                <AqInput
                  name={technicalManagementBy.name}
                  className={"flexible"}
                  value={
                    initialValues.technicalManagementBy &&
                    initialValues.technicalManagementBy.value
                  }
                  error={
                    initialValues["technicalManagementBy"] &&
                    initialValues["technicalManagementBy"]._errors
                  }
                  onFocus={onFocus}
                  label={technicalManagementBy.label}
                  onBlur={onBlur}
                  placeholder={technicalManagementBy.placeholder}
                  meta={technicalManagementBy.meta}
                  onChange={onChange}
                />
              )}
          </div>
        </div>
        <div className={"fm-content"}>
          <AqInput
            name={commercialManagement.name}
            className={"flexible"}
            value={
              initialValues.commercialManagement &&
              initialValues.commercialManagement.value
            }
            error={
              initialValues["commercialManagement"] &&
              initialValues["commercialManagement"]._errors
            }
            onFocus={onFocus}
            label={commercialManagement.label}
            onBlur={onBlur}
            placeholder={commercialManagement.placeholder}
            onChange={onChange}
          />
        </div>
        <div className={"fm-content"}>
          <label>{totalEmployeeStrength.label}</label>
          <div className={"flex emp"}>
            <AqInput
              name={totalShoredEmployeeStrength.name}
              className={"span-6"}
              value={
                initialValues.totalShoredEmployeeStrength &&
                initialValues.totalShoredEmployeeStrength.value
              }
              error={
                initialValues["totalShoredEmployeeStrength"] &&
                initialValues["totalShoredEmployeeStrength"]._errors
              }
              onFocus={onFocus}
              onBlur={onBlur}
              type={"number"}
              placeholder={totalShoredEmployeeStrength.placeholder}
              meta={totalShoredEmployeeStrength.meta}
              onChange={onChange}
            />
            <AqInput
              name={totalEmployeeStrength.name}
              className={"span-6"}
              value={
                initialValues.totalEmployeeStrength &&
                initialValues.totalEmployeeStrength.value
              }
              error={
                initialValues["totalEmployeeStrength"] &&
                initialValues["totalEmployeeStrength"]._errors
              }
              onFocus={onFocus}
              onBlur={onBlur}
              type={"number"}
              placeholder={totalEmployeeStrength.placeholder}
              meta={totalEmployeeStrength.meta}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    );
  };

  /**
   * Handles the validations on fields
   * @param field
   * @param value
   * @param form
   */
  handleValidation = (field: any, value: any, form: any) => {
    return undefined;
  };

  onFieldChange = (field: any) => {
    const fields = { ...this.state.fields };
    if (field.fieldName === "hasTechnicalManagement") {
      fields.technicalManagementBy.required = field.value.value === "no";
    }
    this.setState({ fields, error: undefined });
  };

  renderForm = () => {
    const { fields, fetched } = this.state;
    if (!fetched) {
      return null;
    }
    const initialValues = this.state.initialValues || {};
    return (
      <AqFormComponent
        fields={fields}
        initialValues={initialValues}
        onSubmit={this.onSubmitForm}
        onFieldChange={this.onFieldChange}
        validator={this.handleValidation}
        renderer={this.renderFormElm}
      />
    );
  };

  render(): React.ReactNode {
    return (
      <AqComanyExp>
        <h3 className={"txt-center h3-title"}>Your Company Experience</h3>
        <div>{this.renderForm()}</div>
        <AqFooter onButtonClick={this.handleFooterAction} />
      </AqComanyExp>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    companyExperience: { ...state.root.companyExperience },
  };
};

const mapDispatchToProps = { ...actionDispatcher };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AqCompanyExperienceComponent);
