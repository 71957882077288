import React, { PureComponent } from "react";
import styled from "styled-components";
import { CardTypes } from "../../controller/models";
import { AqRadio } from "../seeds";
import {
  FontSize14,
  H1Size,
  Light,
  MetalDark,
  Neutral,
  NeutralLight,
  Primary,
  White,
  Secondary,
} from "../../Styles";

const AqCardWrapper = styled.div`
  width: 267px;
  height: 204px;
  box-sizing: border-box;
  border-radius: 22px;
  position: relative;
  margin: 0 10px 10px 0;
  display: flex;
  flex: 1;

  .aq-card-corner {
    position: absolute;
    width: 51px;
    height: 58px;
    right: -1px;
    top: -1px;
    background: ${White};
    border-top-right-radius: 22px;

    &.inverted {
      transform: rotate(180deg);
      bottom: -1px;
      top: auto;
      left: -1px;
    }

    span {
      width: 11px;
      height: 11px;
      background-color: ${Primary};
      display: block;
      border-radius: 50%;
      top: -3px;
      position: relative;
      left: 4px;
    }

    &.selected {
      background-color: ${NeutralLight};

      span {
        background-color: ${Neutral};
      }
    }
  }

  .aq-card-wrapper {
    font-size: ${FontSize14};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 24px;
    border-radius: 22px;
    border: 3px solid ${Primary};
    width: 100%;

    &.selected {
      background-color: ${NeutralLight};
      border-color: ${Neutral};
    }

    input {
    }

    .aq-card-label {
      color: ${MetalDark};
      display: flex;

      &.borrower:before {
        width: 48px;
        height: 48px;
        background-size: 48px;
        background-repeat: no-repeat;
        content: " ";
        margin-right: 5px;
        background-image: url(${process.env.PUBLIC_URL +
        "/icons/card/borrower.svg"});
      }

      &.investor:before {
        width: 48px;
        height: 48px;
        background-size: 48px;
        background-repeat: no-repeat;
        content: " ";
        margin-right: 5px;
        background-image: url(${process.env.PUBLIC_URL +
        "/icons/card/invester.svg"});
      }

      .aq-card-label-sm {
      }

      .aq-card-label-lg {
        font-size: ${H1Size};
        font-family: "Rubik-Medium";
      }
    }

    .aq-card-footer {
      margin-top: 22px;
      text-align: center;
      color: ${Light};
    }
  }
  @media screen and (max-width: 677px) {
    height: 72px;
    margin: 0 0 30px;
    .aq-card-corner,
    .aq-card-footer {
      display: none;
    }
    .aq-card-wrapper {
      border: 1px solid #dedede;
      box-sizing: border-box;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      padding: 0 5px;
      > div {
        margin-left: 16px;
        margin-right: 0;
      }
      .aq-card-label {
        align-items: center;
        &.borrower:before,
        &.investor:before {
          width: 30px;
          height: 30px;
          background-size: 30px;
        }
        .aq-card-label-lg {
          font-size: 16px;
        }
      }
      label {
        margin: 0;
      }

      &.selected {
        background-color: ${Secondary};
        border-color: ${Secondary};
        .aq-card-label {
          color: ${White};
          &.borrower:before,
          &.investor:before {
            filter: brightness(100);
          }
        }
        label {
          .rdo.checked {
            border-color: ${White};
            filter: brightness(100);
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
`;

interface IAqCardProps {
  id: string;
  type: CardTypes;
  footer: string;
  name: string;
  onChange: any;
  selected?: boolean;
  inverted?: boolean;
}

const CheckInput = (props: any) => {
  return (
    <input type={"checkbox"} {...props} className={`chk ${props.className}`} />
  );
};

class AqCardComponent extends PureComponent<IAqCardProps, any> {
  constructor(props: IAqCardProps) {
    super(props);
    this.state = {
      selected: props.selected,
    };
  }

  getClass = () => {
    const { type, selected, inverted } = this.props;
    const cls = {
      wrapper: "aq-card-wrapper",
      lbl: "investor",
      corner: "aq-card-corner",
    };
    if (selected) {
      cls.wrapper += " selected";
      cls.corner += " selected";
    }
    if (inverted) {
      cls.corner += " inverted";
    }
    if (type === CardTypes.borrower) {
      cls.lbl = "borrower";
    }
    return cls;
  };

  handleOnChange = (ev: any) => {
    const { id } = this.props;
    this.props.onChange && this.props.onChange({ id });
  };

  render() {
    const { type, footer, name, selected } = this.props;
    const cls = this.getClass();
    return (
      <AqCardWrapper>
        <div className={cls.corner}>
          <span />
        </div>
        <div className={cls.wrapper}>
          <AqRadio
            name={name}
            label={""}
            checked={selected}
            onChange={this.handleOnChange}
          />
          <div className={`aq-card-label ${cls.lbl}`}>
            <div>
              <div className={"aq-card-label-sm"}>I am a</div>
              <div className={"aq-card-label-lg"}>
                {type === CardTypes.borrower && "BORROWER"}
                {type === CardTypes.inverster && "INVESTOR"}
              </div>
            </div>
          </div>
          <div className={"aq-card-footer"}>{footer}</div>
        </div>
      </AqCardWrapper>
    );
  }
}

const AqCard = AqCardComponent;
export default AqCard;
