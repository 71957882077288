import React, { PureComponent, ReactComponentElement } from "react";
import { validValue } from "../../controller/utils/commonUtils";
import Styles from "./styles/input";

interface IInputMetaProps {
  icon?: string;
  clear?: boolean;
  iconRight?: string;
  unit?: string;
}

/**
 * Interface for the Input component's props
 */
interface IInputProps {
  value: any;
  name: string;
  type?: string;
  maxLength?: number;
  placeholder?: string;
  min?: number;
  max?: number;
  error?: any;
  label?: string;
  inputStyle?: any;
  meta?: IInputMetaProps;
  onChange?: any;
  onFocus?: any;
  onBlur?: any;
  footer?: any;
  onClear?: any;
  className?: string;
  autoComplete?: string;
  disabled?: boolean;
  step?: string | number;
  currancy?: boolean;
}

/**
 * Input component, a wrapper componnet on top of native input components,
 * renders the selected components based on the provide config
 */
class AqInputComponent extends PureComponent<IInputProps, any> {
  constructor(props: IInputProps) {
    super(props);
    this.state = {
      type: props.type,
      actualType: props.type,
    };
  }

  /**
   * Derives the states form props values
   * @param props
   */
  static getDerivedStateFromProps(props: IInputProps) {
    // console.log("valueinput", props);
    if (props.currancy) {
      let val =
        (validValue(props.value) &&
          props.value
            .toString()
            .replace(/\D/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")) ||
        "";
      return { value: val };
    }

    return { value: props.value };
  }

  // format number 1000000 to 1,234,567
  // formatNumber = (n: any) => {
  //   return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // };

  /**
   * Change handler to invoke the parent change handler
   * @param ev
   */
  changeHandler = (ev: any) => {
    if (!this.hasMaxLengthReached(ev.target.value)) {
      this.setState({ value: ev.target.value });
      this.props.onChange &&
        this.props.onChange(this.props.name, ev.target.value);
    }
  };

  hasMaxLengthReached = (value: string): boolean => {
    if (this.props.maxLength === undefined) {
      return false;
    }
    return value.length > this.props.maxLength;
  };

  /**
   * Focus handler to invoke the parent focus handler
   * @param value
   */
  focusHandler = (value: any) => {
    this.props.onFocus &&
      this.props.onFocus(this.props.name, value.target.value);
  };

  /**
   * Blur handler to invoke the parent blue handler
   * @param value
   */
  blurHandler = (value: any) => {
    this.props.onBlur && this.props.onBlur(this.props.name, value.target.value);
  };

  /**
   * Blur handler to invoke the parent blue handler
   * @param value
   */
  closeHandler = (value: any) => {
    this.props.onClear &&
      this.props.onClear(this.props.name, value.target.value);
  };

  /**
   * Returns the class name based on the icon value
   */
  getClassNames() {
    const { error, meta = {} } = this.props;
    const { icon, unit } = meta;
    const clasess = {
      wrapper: "input-field",
      input: "",
    };
    if (error) {
      clasess.input += " error";
      clasess.wrapper += " has-error";
    }
    if (icon) {
      clasess.input += " has-icon";
      if (icon === "currency" && !unit) {
        clasess.input += " txt-right";
      }
    }
    if (unit) {
      clasess.input += " has-unitTxt";
    }
    return clasess;
  }

  /**
   * Returns the icon template for field
   * @param meta
   */
  getIcon = (meta: IInputMetaProps): ReactComponentElement<any> | null => {
    const { icon } = meta;
    if (icon) {
      return <span className={`icon-${icon}`} />;
    }
    return null;
  };

  /**
   * Displays the password value
   */
  showPassword = () => {
    const type = this.state.type === "text" ? this.props.type : "text";
    this.setState({ type });
  };

  /**
   * Render fn of the component
   */
  render() {
    const { value, type = "text", actualType } = this.state;
    const {
      error,
      placeholder,
      meta = {},
      label,
      inputStyle,
      name,
      footer,
      className,
      min,
      max,
      autoComplete,
      disabled,
      currancy,
    } = this.props;
    const { clear, unit } = meta;
    const clasess = this.getClassNames();
    return (
      <Styles className={className}>
        {label && <label>{label}</label>}
        <div className={clasess.wrapper}>
          {type === "textarea" && (
            <textarea
              id={name}
              className={clasess.input}
              placeholder={placeholder}
              value={value}
              style={inputStyle}
              onChange={this.changeHandler}
              onFocus={this.focusHandler}
              onBlur={this.blurHandler}
            />
          )}
          {type !== "textarea" && (
            <input
              id={name}
              className={clasess.input}
              placeholder={placeholder}
              min={min || 0}
              max={max}
              value={value}
              type={currancy ? "text" : type}
              style={inputStyle}
              autoComplete={autoComplete}
              onChange={this.changeHandler}
              onFocus={this.focusHandler}
              onBlur={this.blurHandler}
              disabled={disabled}
            />
          )}
          {this.getIcon(meta)}
          {actualType === "password" && (
            <span onClick={this.showPassword} className={"icon-r-eye"} />
          )}
          {clear && (
            <span onClick={this.closeHandler} className={`icon-r-close`}>
              &#10799;
            </span>
          )}
          {unit && <span className={"input-right-txt"}>{unit}</span>}
          {error && <div className={"error-txt"}>{error.error}</div>}
          {footer && <div className={"footer-txt"}>{footer}</div>}
        </div>
      </Styles>
    );
  }
}

const AqInput = AqInputComponent;
export default AqInput;
