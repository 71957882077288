import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import AqButtonComponent from "../../../components/seeds/aq-button.component";
import actionDispatcher from "../../../controller/actions";

const AqDownloadWrapper = styled.div`
  .download-btn {
    padding: 10px 40px;
  }

  @media screen and (max-width: 768px) {
    .download-btn {
      padding: 10px;
    }
  }
`;

class AqDownloadPlanComponent extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  onDownload = () => {
    const projectId = sessionStorage.getItem("__projectId");
    this.props.downloadBusinessPlan(projectId, this.props.history);
  };

  render(): React.ReactNode {
    return (
      <AqDownloadWrapper>
        <h3 className={"txt-center h3-title aq-color-txt"}>
          Your details have been successfully captured!!!
        </h3>
        <br />
        <div>
          <AqButtonComponent
            style={{ width: "auto" }}
            icon={"download"}
            className={"btn-neutral download-btn"}
            label={"Download your business plan"}
            onClick={this.onDownload}
          />
        </div>
        <AqButtonComponent
          style={{ width: "auto" }}
          className={"btn-neutral download-btn"}
          label={"Finish"}
          onClick={() => this.props.history.push("/projects")}
        />
      </AqDownloadWrapper>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {};
};
const mapDispatchToProps = { ...actionDispatcher };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AqDownloadPlanComponent);
