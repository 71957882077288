import React, { PureComponent } from 'react';
import AqSelect from '../seeds/aq-select.component';

import { AqButton, AqForm, AqInput } from '../seeds/index';
import SignupStyles from './signup.styles';

class SigninComponent extends PureComponent<any, any> {
  constructor(props: any) {
    super(props);
    const userEmail = sessionStorage.getItem('userEmail');
    this.state = {
      initialValues: {email: userEmail, password: ''},
      step: 'email',
      fields: {
        email: {
          name: 'email',
          required: true,
          type: 'email',
          regex: /^[^@ ]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9]+$/,
          error: '*This is required field',
          placeholder: 'Enter Email'
        },
        password: {
          name: 'password',
          required: true,
          // hidden: true,
          type: 'password',
          error: '*This is required field',
          placeholder: 'Enter password'
        }
      }
    }
  }

  /**
   * Handles the validations on fields
   * @param field
   * @param value
   */
  handleValidation = (field: any, value: any) => {
    if (field.regex && value) {
      if (!field.regex.test(value)) {
        return {
          error: 'Invalid value'
        }
      }
    }
    if (field.required && !value) {
      return {
        error: field.error || 'field is required'
      }
    }
    return undefined;
  }

  /**
   * handles form submit event
   * @param formValue
   */
  handleSubmit = (formValue: any) => {
    const {step, fields} = this.state;
    console.log('handle submit called ', formValue);
    this.props.history.push('/otp');
  }

  /**
   * Populates and return the fields template
   * @param state
   */
  getFields = (state: any) => {
    const {formState, onFocus, onBlur, onChange} = state;
    const {fields} = this.state;
    const initialValues = formState;
    return <div className={'field-wrapper'}>
      {Object.keys(fields).map((key: any, index: number) => {
        if (!fields[key].hidden) {
          return (
            <AqInput key={`${index}_input`}
                     name={fields[key].name || key}
                     placeholder={fields[key].placeholder}
                     meta={fields[key].meta}
                     value={initialValues[key] && initialValues[key].value}
                     error={initialValues[key]._errors}
                     onFocus={onFocus}
                     onBlur={onBlur}
                     type={fields[key].type}
                     onChange={onChange}/>)
        }
      })}
    </div>
  }

  /**
   * Custom renderer for the form component
   * @param state
   */
  renderer = (state: any = {}) => {
    const {formState, onSubmit} = state;
    if (!formState) {
      return null;
    }
    return <div>
      {this.getFields(state)}
      <br/>
      <div className={'aq-txt-l'}><a>Forgot Password?</a></div>
      <br/>
      <AqButton label={'Sign In'} onClick={onSubmit}/>
    </div>
  }

  /**
   * Returns the Form template
   */
  getForm() {
    const {initialValues, fields} = this.state;
    return <AqForm
      id={'signin'}
      title='Sign In'
      onSubmit={this.handleSubmit}
      fields={fields}
      initialValues={initialValues}
      validator={this.handleValidation}
      renderer={this.renderer}
    />
  }

  /**
   * Default renderer fn
   */
  render() {
    return <SignupStyles>
      <div className={'signup-form'}>
        <div className={'panel'}>
          <div className={'bg-1'}/>
          <div className={'bg-2'}/>
          <div className={'bg-3'}/>
          <div className={'bg-cover'}>
            <div className={'mobile'}></div>
            {this.getForm()}
            <div className={'footer'}>
              <p className={'footer-txt'}>
                <span className={'safe-shield'}></span>Secure Login
              </p>
            </div>
          </div>
        </div>
      </div>
    </SignupStyles>
  }
}

export default SigninComponent;
