export interface IAction {
  type: string;
  payload: any;
}

/**
 * Badge Icons enum
 */
export enum BadgeIcons {
  bulk,
  tanker,
  chemical,
  container,
  other,
}

/**
 * Card types
 */
export enum CardTypes {
  borrower,
  inverster,
}

/**
 * Vessels types
 */
export enum VesselTypes {
  bulk,
  tanker,
  chemical,
  container,
  other,
}

export interface IApplicationState {
  dashboard: IDashboardState;
  done: ISavedState;
  root: IRootState;
  vessel: IVesselState;
}

export interface IDashboardState {
  page: any;
  header: any;
  projects: Array<any>;
  user?: any;
  bookedMeeting: Array<any>;
  exixtingDoc: Array<any>;
  docTypeItems: Array<any>;
  users?: any;
}

export interface IVessel {
  id?: string;
  purposeInfo: { [key: string]: any };
  selectedVesselCard: { [key: string]: any };
  vesselEmployment: Array<any>;
  vesselInfo: { [key: string]: any };
  vesselOpex: { [key: string]: any };
}
export interface IVesselState {
  carriers: Array<IVessel>;
  tankers: Array<IVessel>;
  chemicals: Array<IVessel>;
  containers: Array<IVessel>;
  others: Array<IVessel>;
  selected: {
    context: string;
    id?: string;
  };
}
export interface ISavedState {
  vessels: Array<IVessel>;
}
export interface IRootState {
  vessels: Array<any>;
  companyInfo?: any;
  context?: any;
  companyExperience: {};
  error?: any;
}
