
export const MetalDark = '#333333';
export const LightDark = '#343741';
export const Primary = '#091859';
export const Secondary = '#2D3766';
export const Neutral = '#33BE97';
export const NeutralLight = '#DEF9FD';

export const Warning = '#EB9F2E';
export const ErrorCode = '#FA6968';
export const NegativeChange = '#DB2D0E';
export const Orange = '#FF9D27';

export const Dark = '#333333';
export const Light = '#747474';
export const Hint = '#C8C8C8';


export const Line = '#DEDEDE';
export const BgDark = '#F5F5F5';
export const BgLight = '#FAFAFA';
export const BgLight2 = '#DEF9ED';

export const White = '#FFFFFF';
export const DarkBlue = '#2d3766';
export const Disabled = '#C1C5C5';

export const FontFamily = 'Rubik';
export const FontFamilyLight = 'Rubik-Light';
export const FontFamilyMedium = 'Rubik-Medium';
export const FontFamilyBold = 'Rubik-Bold';

export const H1Size = '24px';
export const H1LineHeight = '28px';
export const TxtSize = '16px';
export const TxtLineHeight = '19px';

export const FieldBottomMargin = '22px';

export const BtnWidth = '212px';

export const BorderRadius = '8px';
export const InputBorderRadius = '6px';

export const SteelGrey = '#DFE6F4';


export const FontSize18 = '18px';
export const FontSize16 = '16px';
export const FontSize14 = '14px';
export const FontSize12 = '12px';

