import GoogleMapReact from "google-map-react";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { AqFooter } from "../../components/footer/aq-footer";
import AqFormComponent from "../../components/seeds/aq-form.component";
import AqInput from "../../components/seeds/aq-input.component";
import AqSelectComponent from "../../components/seeds/aq-select.component";
import AqPlacement from "../../components/seeds/aq-placement.component";
import actionDispatcher from "../../controller/actions";
import { getCompanyInfoHttp } from "../../controller/https";
import {
  strictValidObjectWithKeys,
  validObjectWithParameterKeys,
} from "../../controller/utils/commonUtils";

const AqCompanyWrapper = styled.div`
  height: 100%;
  display: flex;

  .aq-company-content {
    display: flex;
    flex: 1;

    .aq-company-panel {
      flex: 1;
      padding: 20px 90px 20px 0;
    }

    .aq-company-map {
      flex: 1;
    }
  }

  .field-container {
    .span-12 {
      width: 100%;
    }

    .span-6 {
      width: 50%;
      display: inline-block;
      padding-right: 20px;
      box-sizing: border-box;

      :nth-child(even) {
        padding: 0;
      }
    }
  }

  @media screen and (max-width: 677px) {
    .aq-company-content {
      display: block;
      padding: 0 10px;

      .aq-company-panel {
        padding: 0;

        .field-wrapper {
          .span-6 {
            width: 100%;
            padding: 0;
          }
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 47px;
  height: 43px;
  // background-color: #000;
  // border: 2px solid #fff;
  // border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIyNCIgdmVyc2lvbj0iMS4xIiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6Y2M9Imh0dHA6Ly9jcmVhdGl2ZWNvbW1vbnMub3JnL25zIyIgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLTEwMjguNCkiPjxwYXRoIGQ9Im0xMiAwYy00LjQxODMgMi4zNjg1ZS0xNSAtOCAzLjU4MTctOCA4IDAgMS40MjEgMC4zODE2IDIuNzUgMS4wMzEyIDMuOTA2IDAuMTA3OSAwLjE5MiAwLjIyMSAwLjM4MSAwLjM0MzggMC41NjNsNi42MjUgMTEuNTMxIDYuNjI1LTExLjUzMWMwLjEwMi0wLjE1MSAwLjE5LTAuMzExIDAuMjgxLTAuNDY5bDAuMDYzLTAuMDk0YzAuNjQ5LTEuMTU2IDEuMDMxLTIuNDg1IDEuMDMxLTMuOTA2IDAtNC40MTgzLTMuNTgyLTgtOC04em0wIDRjMi4yMDkgMCA0IDEuNzkwOSA0IDQgMCAyLjIwOS0xLjc5MSA0LTQgNC0yLjIwOTEgMC00LTEuNzkxLTQtNCAwLTIuMjA5MSAxLjc5MDktNCA0LTR6IiBmaWxsPSIjZTc0YzNjIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDEwMjguNCkiLz48cGF0aCBkPSJtMTIgM2MtMi43NjE0IDAtNSAyLjIzODYtNSA1IDAgMi43NjEgMi4yMzg2IDUgNSA1IDIuNzYxIDAgNS0yLjIzOSA1LTUgMC0yLjc2MTQtMi4yMzktNS01LTV6bTAgMmMxLjY1NyAwIDMgMS4zNDMxIDMgM3MtMS4zNDMgMy0zIDMtMy0xLjM0MzEtMy0zIDEuMzQzLTMgMy0zeiIgZmlsbD0iI2MwMzkyYiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAxMDI4LjQpIi8+PC9nPjwvc3ZnPg==");
  background-size: contain;
  &:hover {
    z-index: 1;
  }
`;
// eslint-disable-next-line @typescript-eslint/no-redeclare
const AnyReactComponent = (val: any) => <Wrapper />;
class AqMap extends PureComponent<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      center: {
        lat: 52.0705,
        lng: 4.3007,
      },
      zoom: 12,
    };
  }

  render(): React.ReactNode {
    const { center, zoom } = this.state;
    const centerValue = validObjectWithParameterKeys(this.props.centerPoint, [
      "lng",
      "lat",
    ])
      ? this.props.centerPoint
      : center;
    // console.log("map state", this.state, centerValue);
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCUpdAjCJ6aPUdC40flRcPn8GeUzabbPAA" }}
          yesIWantToUseGoogleMapApiInternals
          center={centerValue}
          defaultZoom={zoom}
        >
          <AnyReactComponent
            lat={centerValue.lat}
            lng={centerValue.lng}
            text="My Marker"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

class AqCompanyComponent extends PureComponent<any, any> {
  constructor(props: any) {
    super(props);
    this.projectId = this.props.match.params.id;
    this.state = {
      centerPoint: {},
      initialValue: {
        companyName: "",
        address: "",
        website: "",
      },
      fields: {
        companyName: {
          name: "companyName",
          required: true,
          placeholder: "Company Name",
          label: "Which company do you represent?",
        },
        address: {
          name: "address",
          required: true,
          placeholder: "Address",
          type: "placement",
          label: "Where is your company located?",
          value: "",
        },
        websiteUrl: {
          name: "websiteUrl",
          placeholder: "Enter website URL",
          label: "Do you have a company website?",
        },
      },
    };
  }

  projectId: any;
  formSubmitCb: any;

  onFormSubmit = (formData: any) => {
    const payload: any = {};
    Object.keys(formData.formState).forEach((key: string) => {
      if (formData.fields[key]) {
        payload[key] = formData.formState[key].value;
      }
    });

    if (strictValidObjectWithKeys(this.state.existingCompanyInfo)) {
      this.props.updateCompanyInfo(
        { ...payload, projectId: this.state.existingCompanyInfo.projectId },
        this.props.history
      );
    } else {
      this.props.addCompanyInfo(
        { ...payload, projectId: this.state.existingCompanyInfo.projectId },
        this.props.history
      );
    }
  };

  static getDerivedStateFromProps(props: any) {
    return {
      currentRoute: props.location.pathname,
      page: props.page,
    };
  }

  loadExistingCompanyInfo = async () => {
    if (!!!this.projectId || this.projectId === "new") {
      this.setState({ existingCompanyInfo: {} });
      return;
    }

    let existingCompanyInfo = {};
    try {
      const response = await getCompanyInfoHttp(this.projectId);
      if (response.ok) {
        existingCompanyInfo = await response.json();
      }
    } catch {
      this.props.ShowError("Oops! Failed to read company info.");
    }
    this.setState({
      existingCompanyInfo: { ...existingCompanyInfo, projectId: this.projectId },
    });
  };

  componentDidMount = async () => {
    this.props.updatePage({
      name: "profile-info",
      title: "Welcome: MSC - Global Shipping Company",
      showActions: false,
    });
    this.props.projectProgress(25);

    await this.loadExistingCompanyInfo();
  };

  /**
   * Populates and return the fields template
   * @param state
   */
  getFields = (state: any) => {
    const { formState, onFocus, onBlur, onChange } = state;
    const fields = this.state.fields;
    const initialValues = formState;
    // console.log("data----", initialValues);
    return (
      <div className={"field-wrapper field-container"}>
        {Object.keys(fields).map((key: any, index: number) => {
          const span = fields[key].span
            ? `span-${fields[key].span}`
            : "span-12";
          if (fields[key].type === "select") {
            return (
              <div key={`${index}_input`} className={span}>
                <AqSelectComponent
                  name={fields[key].name || key}
                  label={fields[key].label}
                  defaultValue={fields[key].placeholder}
                  value={initialValues[key] && initialValues[key].value}
                  error={initialValues[key] && initialValues[key]._errors}
                  options={fields[key].options}
                  onChange={onChange}
                />
              </div>
            );
          }
          if (fields[key].type === "placement") {
            return (
              <div key={`${index}_input`} className={span}>
                <AqPlacement
                  name={fields[key].name || key}
                  label={fields[key].label}
                  defaultValue={fields[key].placeholder}
                  value={initialValues[key] && initialValues[key].value}
                  error={initialValues[key] && initialValues[key]._errors}
                  filtersOptions={[]}
                  onChange={onChange}
                  centerPointValue={(val: any) => {
                    this.setState({ centerPoint: val });
                  }}
                />
              </div>
            );
          }
          return (
            <div key={`${index}_input`} className={span}>
              <AqInput
                name={fields[key].name || key}
                label={fields[key].label}
                placeholder={fields[key].placeholder}
                meta={fields[key].meta}
                value={initialValues[key] && initialValues[key].value}
                error={initialValues[key] && initialValues[key]._errors}
                onFocus={onFocus}
                onBlur={onBlur}
                type={fields[key].type}
                onChange={onChange}
              />
            </div>
          );
        })}
      </div>
    );
  };

  fieldRenderer = (state: any) => {
    const { formState, onSubmit } = state;
    if (!formState) {
      return null;
    }
    this.formSubmitCb = onSubmit;
    return <div>{this.getFields(state)}</div>;
  };

  handleFooterAction = (ev: any) => {
    if (ev === "back") {
      this.props.history.push("/projects");
    } else if (this.formSubmitCb) {
      this.formSubmitCb();
    }
  };

  handleValidation = (field: any, value: any) => {
    if (/website/.test(field.name)) {
      if (
        value &&
        // eslint-disable-next-line no-useless-escape
        !/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi.test(
          value
        )
      ) {
        return {
          error: `Please enter a valid URL.`,
        };
      }
    }
    return undefined;
  };

  render(): React.ReactNode {
    let { fields, centerPoint, initialValue, existingCompanyInfo } = this.state;
    const { isLoading } = this.props;

    if (existingCompanyInfo !== undefined) {
      if (strictValidObjectWithKeys(existingCompanyInfo)) {
        initialValue = existingCompanyInfo;
      }
      return (
        <AqCompanyWrapper>
          <div className={"aq-company-content"}>
            <div className={"aq-company-panel"}>
              <AqFormComponent
                fields={fields}
                initialValues={initialValue}
                onSubmit={this.onFormSubmit}
                validator={this.handleValidation}
                renderer={this.fieldRenderer}
              />
            </div>
            <div className={"aq-company-map"}>
              <AqMap centerPoint={centerPoint} />
            </div>
          </div>
          <AqFooter progress={25} onButtonClick={this.handleFooterAction} />
          {isLoading && <div className={"global-loader"}>Saving...</div>}
        </AqCompanyWrapper>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state: any) => {
  const { dashboard, root } = state;
  return {
    ...dashboard,
    isLoading: root.isLoading,
  };
};
const mapDispatchToProps = { ...actionDispatcher };

export default connect(mapStateToProps, mapDispatchToProps)(AqCompanyComponent);
