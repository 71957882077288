import { ON_ERROR, PROJECT_LISTS } from "../../constants/action-types";
import { getProjectlist } from "../https";

export const getProjectsList = (payload: any, history: any) => {
  return (dispatch: any) => {
    return getProjectlist(payload).then(
      (res: any) => {
        // console.log("project list res", res);

        if (res && res.items && res.total) {
          dispatch({ type: PROJECT_LISTS, payload: res });
          return true;
        }
      },
      (error) => {
        // console.log("project list error", error);
        dispatch({ type: ON_ERROR, payload: error });
      }
    );
  };
};
