import {
  ADD_DOCUMENT_TO_PROJECT,
  ADD_NEW_COMPANY_PROJECT,
  DELETE_DOCUMENT_FROM_PROJECT,
  ON_NAVIGATE,
  UPDATE_PAGE,
  PROJECT_LISTS,
  ADD_NEW_VESSEL_HEAD,
  BOOKIED_MEETING,
  EXISTING_DOC,
  LOOKUP_DOC_TYPE_LIST,
  USER_INFO,
} from "../../constants/action-types";
import { IAction, IDashboardState } from "../models";
import { DashboardState } from "../states";

const dashboardSessionKey = "__dashboard__";
const getInitialState = () => {
  try {
    const persistState = sessionStorage.getItem(dashboardSessionKey);
    if (persistState) {
      return JSON.parse(persistState);
    } else {
      return DashboardState;
    }
  } catch (e) {
    return DashboardState;
  }
};

const initialState = getInitialState();

function dashboard(state: IDashboardState = initialState, action: IAction) {
  let newState;
  switch (action.type) {
    case USER_INFO:
      newState = { ...state, users: action.payload };
      break;
    case PROJECT_LISTS:
      // console.log("reducer", action, state);
      sessionStorage.setItem("__projects", JSON.stringify(action));
      const { items = [], total = 0 } = action.payload;
      newState = { ...state, projects: [...items], projectCount: total };
      break;
    case ADD_NEW_COMPANY_PROJECT:
      const { projects = [] } = state;
      projects.push(action.payload);
      newState = { ...state, projects: [...projects] };
      break;
    case ADD_DOCUMENT_TO_PROJECT:
      newState = addDocument(state, action);
      break;
    case DELETE_DOCUMENT_FROM_PROJECT:
      newState = deleteDocument(state, action);
      break;
    case ON_NAVIGATE:
      const { header } = state;
      newState = {
        ...state,
        header: { ...header, title: action.payload.headerLabel },
      };
      break;
    case ADD_NEW_VESSEL_HEAD:
      const { actions = [] } = state.header;
      // console.log("sadsadsadsd", action);
      if (action.payload === "clear") {
        newState = {
          ...state,
          header: { ...state.header, actions: [{ label: "Vessel 1" }] },
        };
      } else {
        actions.push({ label: "Vessel " + (actions.length + 1) });
        newState = {
          ...state,
          header: { ...state.header, ...actions },
        };
      }

      break;
    case UPDATE_PAGE:
      const step = action.payload.step;
      const subStep = action.payload.subStep;
      const { steps } = state.header;
      if (step) {
        steps.forEach((item: any) => {
          item.selected = item.id <= step;
          if (item.steps && subStep) {
            item.steps.forEach(
              (ss: any, i: number) => (ss.selected = i < subStep)
            );
          }
        });
      }
      newState = {
        ...state,
        page: { ...action.payload },
        header: { ...state.header, steps },
      };
      break;
    case BOOKIED_MEETING:
      let {
        items: bookedMeetItems = [],
        total: totalBookedMeet = 0,
      } = action.payload;
      newState = {
        ...state,
        bookedMeeting: bookedMeetItems,
        bookedMeetingCount: totalBookedMeet,
      };
      break;
    case EXISTING_DOC:
      let { items: existItems = [], total: totalExist = 0 } = action.payload;
      newState = {
        ...state,
        exixtingDoc: existItems,
        exixtingDocCount: totalExist,
      };
      break;
    case LOOKUP_DOC_TYPE_LIST:
      newState = {
        ...state,
        docTypeItems: action.payload,
      };
      break;

    default:
      newState = state;
  }
  sessionStorage.setItem(dashboardSessionKey, JSON.stringify(newState));
  return newState;
}

function deleteDocument(state: IDashboardState, action: IAction) {
  const { projects, users } = state;
  const project = projects.find(
    (item: any) => item.id === action.payload.projectId
  );
  if (project && project.documents && project.documents.length) {
    const documents = project.documents;
    const docIndex: number = documents.findIndex(
      (doc: any) => doc.id === action.payload.documentId
    );
    if (docIndex > -1) {
      documents.splice(docIndex, 1);
      project.documents = [...documents];
    }
  }
  return { ...state, projects: [...projects] };
}

function addDocument(state: IDashboardState, action: IAction) {
  const { projects, users } = state;
  const project = projects.find(
    (item: any) => item.id === action.payload.projectId
  );
  if (project) {
    project.documents = project.documents || [];
    const isPresent = project.documents.findIndex(
      (p: any) => p.id === action.payload.document.id
    );
    if (isPresent > -1) {
      project.documents[isPresent] = {
        ...project.documents[isPresent],
        ...action.payload.document,
      };
    } else {
      const newDocument = {
        updatedBy: users.firstName + " " + users.lastName,
        ...action.payload.document,
      };
      project.documents.push(newDocument);
    }
  }
  return { ...state, projects: [...projects] };
}

export default dashboard;
