import React, { PureComponent } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import AqInputComponent from "./aq-input.component";
import "./styles/date.css";
import { dateFormat, dateTimeFormat } from "../../controller/utils/commonUtils";
import { AnyCnameRecord } from "dns";

interface IAqDateProps {
  name: string;
  label?: string;
  placeholder?: string;
  startDate: string;
  endDate?: string;
  onChange: any;
  onBlur?: any;
  selectsRange?: boolean;
  error?: any;
  className?: string;
  minDate?: any;
  maxDate?: any;
  showTimeSelect?: boolean;
}

export default class AqDateComponent extends PureComponent<IAqDateProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isDateVisible: false,
      startDate: "",
      endDate: null,
    };
  }

  componentDidMount(): void {
    if (this.props.startDate) {
      const startDt: any = this.props.startDate;
      if (this.props.selectsRange) {
        const multiDt = startDt.split(" ");
        const stDate = new Date(multiDt[0]);
        const endDate = new Date(multiDt[1]);
        if (typeof stDate === "object") {
          try {
            // const newDt = dt.toISOString().split('T')[0].split('-')
            this.setState({ startDate: stDate, endDate }, () => {
              // call onChange to set value in modal
              this.props.onChange(this.props.name, {
                renderValue: this.getDateValue(),
                value: this.getDateString([stDate, endDate]),
              });
            });
          } catch (e) {
            this.setState({
              startDate: multiDt[0].toISOString(),
              endDate: multiDt[1].toISOString(),
            });
          }
        } else {
          const st = multiDt[0].split("-");
          const et = multiDt[1].split("-");
          this.updateState(st, "startDate");
          this.updateState(et, "endDate");
        }
      } else {
        console.log("single date", startDt, typeof startDt);
        const dt = this.buildDateOnly(startDt);
        console.log("single date set", startDt);
        this.setState({ startDate: dt });
      }
    }
  }

  buildDateOnly = (dt: any): Date => {
    let result;
    if (/T/g.test(dt)) {
      let local = dt;
      if (typeof dt === "string") {
        local = new Date(dt);
      }
      result = new Date(
        Date.UTC(local.getFullYear(), local.getMonth(), local.getDate())
      );
    } else {
      const date = dt.split("-");
      result = new Date(`${date[1]}-${date[0]}-${date[2]}`);
    }
    return result;
  };

  updateState = (dt: any, key: string) => {
    this.setState({ [key]: new Date(`${dt[1]}-${dt[0]}-${dt[2]}`) });
    // if (typeof dt === 'object') {
    //     try {
    //         const newDt = dt.toISOString().split('T')[0].split('-')
    //         this.setState({[key]: `${newDt[2]}-${newDt[1]}-${newDt[0]}`})
    //     } catch (e) {
    //         this.setState({[key]: dt.toISOString()});
    //     }
    // } else if (typeof dt === 'string') {
    //     // this.setState({[key]: (new Date(`${dt[1]}-${dt[0]}-${dt[2]}`))});
    //     const nd = new Date(dt);
    //     this.setState({[key]: `${nd.getMonth() + 1}-${nd.getDate()}-${nd.getFullYear()}`});
    // }
  };

  getDateString = (dates: any): any => {
    if (Array.isArray(dates)) {
      return [this.getDateString(dates[0]), this.getDateString(dates[1])];
    } else {
      return this.buildDateOnly(dates).toISOString();
    }
  };

  /**
   * Handles the date value changes
   * @param dates
   */
  onDateChange = (dates: any) => {
    const { selectsRange } = this.props;
    if (selectsRange) {
      const [startDate, endDate] = dates;
      this.setState({ startDate, endDate }, () => {
        if (startDate && endDate) {
          this.hideDateRange();
          this.props.onChange(this.props.name, {
            renderValue: this.getDateValue(),
            value: this.getDateString(dates),
          });
        }
      });
    } else {
      this.setState({ startDate: dates }, () => {
        this.props.onChange(this.props.name, {
          renderValue: this.getDateValue(),
          value: this.getDateString(dates),
        });
      });
      this.hideDateRange();
    }
  };

  /**
   * Sets the date picker to visible state
   */
  showDateRange = () => {
    this.setState({ isDateVisible: true });
  };

  /**
   * Sets the date picker to hidden
   */
  hideDateRange = () => {
    this.setState({ isDateVisible: false });
  };

  /**
   * Returns the composite range date value
   */
  getDateValue = () => {
    const { startDate, endDate } = this.state;
    const { selectsRange, showTimeSelect } = this.props;
    let output = "";
    if (selectsRange) {
      if (startDate && endDate) {
        output += dateFormat(startDate); // new Date(startDate).toLocaleDateString("nl");
        output += " to " + dateFormat(endDate); //new Date(endDate).toLocaleDateString("nl");
      }
    } else if (typeof startDate === "object") {
      try {
        // const newDt = startDate.toISOString().split('T')[0].split('-')
        // const nd = startDate;
        output = showTimeSelect
          ? dateTimeFormat(startDate)
          : dateFormat(startDate); //`${nd.getDate()}-${nd.getMonth() + 1}-${nd.getFullYear()}`;
      } catch (e) {
        output = showTimeSelect
          ? dateTimeFormat(startDate)
          : dateFormat(startDate); //startDate.toISOString();
      }
    }
    return output;
  };

  /**
   * Change value handler
   * @param value
   */
  handleChange = (value: string) => {};

  /**
   * Clear value handler
   */
  handleClear = () => {
    this.setState({ startDate: "", endDate: "" });
    this.props.onChange(this.props.name, undefined);
  };
  filterPassedTime = (time: any) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  render() {
    const { startDate, endDate, isDateVisible } = this.state;
    const {
      selectsRange,
      error,
      label,
      className,
      minDate,
      maxDate,
      showTimeSelect = false,
      placeholder,
    } = this.props;
    let handleColor = (time: any) => {
      return "text-success";
      //  time.getHours() < 6 || time.getHours() > 22
      //   ? "text-success"
      //   : "text-error";
    };
    return (
      <div
        className={`aq-date-wrapper ${className} ${
          showTimeSelect ? "showTimer" : ""
        }`}
      >
        {label && <label>{label}</label>}
        <AqInputComponent
          value={this.getDateValue()}
          name={"date"}
          autoComplete={"off"}
          placeholder={placeholder || "Select date..."}
          meta={{ icon: "date", clear: true }}
          error={error}
          onFocus={this.showDateRange}
          onChange={this.handleChange}
          onClear={this.handleClear}
        />
        {isDateVisible && selectsRange && (
          <DatePicker
            selected={startDate}
            onChange={this.onDateChange}
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            selectsRange={selectsRange}
            showYearDropdown
            inline
          />
        )}
        {!selectsRange && (
          <DatePicker
            selected={startDate}
            onChange={this.onDateChange}
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            showYearDropdown
            showTimeSelect={showTimeSelect}
            timeClassName={handleColor}
          />
        )}
      </div>
    );
  }
}
