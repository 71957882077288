import {
  DELETE_VESSEL_EMPLOYMENT,
  ON_DELETE_VESSEL,
  ON_DUPLICATE_VESSEL,
  ON_VESSELS_OPEX_FORM_SAVE
} from '../../constants/action-types';
import { IAction, ISavedState } from '../models';
import { SavedState } from '../states';
import { onDeleteVessel, onDeleteVesselEmployment, onDuplicateVessel } from './reducer-helper/vessel-helpers';

const savedSessionKey = '__saved__';
const getInitialState = (): ISavedState => {
  try {
    const persistState = sessionStorage.getItem(savedSessionKey);
    if (persistState) {
      return JSON.parse(persistState);
    } else {
      return SavedState
    }
  } catch (e) {
    return SavedState
  }
};
const initialState: ISavedState = getInitialState();
export default function savedVessels(state: ISavedState = initialState, action: IAction) {
  let newState;
  switch (action.type) {
    case ON_DELETE_VESSEL:
      newState = onDeleteVessel(action, state);
      break;
    case ON_VESSELS_OPEX_FORM_SAVE:
      newState = addVesselItem(action, state);
      break;
    default:
      newState = state;
  }
  sessionStorage.setItem(savedSessionKey, JSON.stringify(newState));
  return newState;
}

function addVesselItem(action: IAction, state: ISavedState) {
  const {vessels = []} = state;
  const oldIndex = vessels.findIndex((item: any) => {
    return item.id === action.payload.id
  });
  if (oldIndex > -1) {
    vessels[oldIndex] = action.payload;
  } else {
    vessels.push(action.payload);
  }
  return {...state, vessels};
}