import React, { FC } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { AqInput } from "../../components/seeds";
import AqButtonComponent from "../../components/seeds/aq-button.component";
import AqFormComponent from "../../components/seeds/aq-form.component";
import actions from "../../controller/actions";
import { askForHelpHttp } from "../../controller/https";
import { White } from "../../Styles";

const EsfHelpFormWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 50%;

  .frm {
    background: ${White};
    padding: 20px;
    box-sizing: border-box;
    transform-origin: top center;
    border-radius: 6px;
    box-shadow: 0px 1px 16px 2px #b5b4b4;
    .btn-primary.medium {
      width: 8em;
    }
  }

  @media screen and (max-width: 760px) {
    top: 0;
    transform: translateY(0);
    margin: 0 auto;
    width: 100%;
    height: 100%;
    overflow: auto;
    .aq-v-fw {
      .flex {
        display: block;
        &.aq-vs-btns {
          display: flex;
        }
      }
      .span-6 {
        width: 100%;
      }
    }
  }
`;

interface IEsfHelpFormProps {
  onClose: any;
  onSend: any;
  initialValues?: any;
}

export const EsfHelpForm: FC<IEsfHelpFormProps> = ({
  initialValues,
  onClose,
  onSend,
}) => {
  const dispatch = useDispatch();

  const fields = {
    subject: {
      label: "Topic",
      name: "subject",
      required: true,
    },
    body: {
      label: "Message",
      name: "body",
      required: true,
    },
  };

  const onCancel = () => {
    onClose();
  };

  const onSubmit = async (formdata: any) => {
    console.log("formdata", formdata);
    var response = await askForHelpHttp(
      formdata.formState.subject.value,
      formdata.formState.body.value
    );
    if (!response.ok) {
      dispatch(
        actions.ShowError("Failed to send the message, please try again later.")
      );
    } else {
      onSend();
    }
  };

  const onValidate = (field: any, value: any) => {};

  const buildFormControls = (fc: any) => {
    const { formState, onFocus, onBlur, onChange, onSubmit } = fc;

    if (!formState) {
      return null;
    }

    return (
      <div className={"aq-v-fw"}>
        <h3>Ask For Assistance</h3>
        <div className={"flex"}>
          <div className={"span-12"}>
            <AqInput
              name={fields.subject.name}
              label={fields.subject.label}
              value={formState.subject && formState.subject.value}
              error={formState.subject && formState.subject._errors}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChange}
            />
          </div>
        </div>
        <div className={"flex"}>
          <div className={"span-12"}>
            <AqInput
              name={fields.body.name}
              label={fields.body.label}
              value={formState.body && formState.body.value}
              error={formState.body && formState.body._errors}
              type="textarea"
              inputStyle={{ height: "16em" }}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChange}
            />
          </div>
        </div>
        <div className={"aq-vs-btns flex"}>
          <div className={"span-6 flex flex-end flex-justify-end"}>
            <AqButtonComponent
              label={"Cancel"}
              className={"btn-primary invert medium"}
              onClick={onCancel}
            />
          </div>
          <div className={"span-6 flex"}>
            <AqButtonComponent
              label={"Send"}
              className={"btn-primary medium"}
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <EsfHelpFormWrapper>
      <div className={"slide-in-anim frm"}>
        <AqFormComponent
          fields={fields}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validator={onValidate}
          renderer={buildFormControls}
        />
      </div>
    </EsfHelpFormWrapper>
  );
};
