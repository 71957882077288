import { getSelectContext, uuidv4 } from '../../../helpers/utils';
import { IAction, IRootState, ISavedState, IVesselState } from '../../models';
import { VesselsState } from '../../states';


function getContext(state: IVesselState): { selectedItem: any, selectedContext: Array<any> } {
  const {selected} = state;
  // @ts-ignore
  const selectedContext = state[selected.context];
  const output: any = {
    selectedItem: undefined,
    selectedContext: undefined
  };
  if (selectedContext && selectedContext.length) {
    output.selectedItem = getSelectContext(state);
    output.selectedContext = selectedContext;
  } else {
    output.selectedItem = {};
    output.selectedContext = [output.selectedItem];
  }
  return output;
}

export function onGetExperience(action: IAction, state: IRootState) {
  return {...state, companyExperience: action.payload};
}

export function onAddComplanyInfo(action: IAction, state: IRootState) {
  return {...state, companyInfo: action.payload};
}

export function onAddNewVessel(action: IAction, state: IVesselState) {
  return VesselsState;
}
export function onVesselReset() {
  return VesselsState;
}

export function onEditNewVessel(action: IAction, state: IVesselState) {
  const payload = {...action.payload};
  const type = payload.type;
  delete payload.type;
  return {...state, [type]: [payload], selected: {context: type, id: payload.id}};
}

/**
 * ON_VESSELS_FETCH
 * @param action
 * @param state
 */
export const onVesselFetch = function (action: IAction, state: IVesselState) {
  const {selected} = state;
  if (selected.context === undefined) {
    return state;
  }
  const {selectedContext, selectedItem} = getContext(state);
  selectedItem.id = selectedItem.id || uuidv4();
  selected.id = selectedItem.id;
  selectedItem.vesselInfo = {data: {...action.payload}, foundVessel: !!action.payload.name};
  return {...state, [selected.context]: [...selectedContext]};
};

export const onSubmitVesselForm = function (action: IAction, state: IVesselState) {
  const {carriers} = state;
  const context: string = state.selected.context;
  const newState: any = {
    ...state,
    carriers: [],
    tankers: [],
    chemicals: [],
    containers: [],
    others: []
  };
  // @ts-ignore
  newState[context] = state[context];
  const {selectedItem} = getContext(state);
  const updatedItem = {
    ...selectedItem,
    vesselInfo: {
      ...selectedItem.vesselInfo,
      foundVessel: true,
      data: {...(selectedItem.vesselInfo && selectedItem.vesselInfo.data), ...action.payload.vesselForm}
    },
    purposeInfo: {data: action.payload.purposeForm},
    selectedVesselCard: action.payload.selectedVesselCard
  };
  Object.keys(updatedItem).forEach((key: string) => {
    selectedItem[key] = updatedItem[key];
  });
  newState[context] = [updatedItem];
  return newState;
  // return {...state, [context]: [...updatedItem]};
};

export const onVesselOpexFormSubmit = function (action: IAction, state: any) {
  const {selected} = state;
  const {selectedItem, selectedContext} = getContext(state);
  const updatedItem = {...selectedItem, vesselOpex: action.payload};
  Object.keys(updatedItem).forEach((key: string) => {
    selectedItem[key] = updatedItem[key];
  });
  return {...state, [selected.context]: [...selectedContext]};
};

export const onUpdateVesselEmployment = function (payload: any, state: IVesselState) {
  const {selected} = state;
  const {selectedContext, selectedItem} = getContext(state);

  const id = payload.id;
  const {vesselEmployment = []} = selectedItem;
  if (vesselEmployment && vesselEmployment.length) {
    const updateIndex = vesselEmployment.findIndex((item: any) => item.id === id);
    if (updateIndex !== undefined) {
      vesselEmployment.splice(updateIndex, 1, payload);
    }
  }
  const updatedItem = {...selectedItem, vesselEmployment: [...vesselEmployment]};
  Object.keys(updatedItem).forEach((key: string) => {
    selectedItem[key] = updatedItem[key];
  });
  return {...state, [selected.context]: [...selectedContext]};
};

export const onVesselEmployment = function (action: IAction, state: IVesselState) {
  const {selected} = state;
  const {selectedContext, selectedItem} = getContext(state);

  const {vesselEmployment = []} = selectedItem;
  vesselEmployment.push(action.payload);
  const updatedItem = {...selectedItem, vesselEmployment: [...vesselEmployment]};
  Object.keys(updatedItem).forEach((key: string) => {
    selectedItem[key] = updatedItem[key];
  });
  return {...state, [selected.context]: [...selectedContext]};
};

export const onDeleteVesselEmployment = function (id: any, state: IVesselState) {
  const {selected} = state;
  const {selectedContext, selectedItem} = getContext(state);
  const {vesselEmployment = []} = selectedItem;

  if (vesselEmployment && vesselEmployment.length) {
    const deleteIndex = vesselEmployment.findIndex((item: any) => item.id === id);
    if (deleteIndex > -1) {
      vesselEmployment.splice(deleteIndex, 1);
    }
  }
  const updatedItem = {...selectedItem, vesselEmployment: [...vesselEmployment]};
  Object.keys(updatedItem).forEach((key: string) => {
    selectedItem[key] = updatedItem[key];
  });
  return {...state, [selected.context]: [...selectedContext]};
};

export const onDeleteVessel = function (action: IAction, state: ISavedState) {
  const {vessels} = state;
  const deleteId = action.payload.id;
  if (vessels && vessels.length) {
    const deleteIndex = vessels.findIndex((item: any) => item.id === deleteId);
    if (deleteIndex > -1) {
      vessels.splice(deleteIndex, 1);
    }
  }
  return {...state, vessels: [...vessels]}
};

export const onVesselSelect = function (action: IAction, state: IVesselState) {
  const {selected} = state;
  // @ts-ignore
  const selectedContext = state[action.payload.context];
  const updates: any = {context: action.payload.context};
  if (selectedContext && selectedContext.length) {
    // updates.id = selectedContext[0].id;
  }
  return {...state, selected: {...selected, ...updates}};
};

export const onDuplicateVessel = function (action: IAction, state: IVesselState) {
  const {selected} = state;
  const {selectedContext, selectedItem} = getContext(state);
  selected.id = uuidv4();
  selectedItem.id = selected.id;
  selectedItem.vesselInfo = {};
  selectedItem.purposeInfo = {};
  return {...state, [selected.context]: [...selectedContext]};
  // const {vessels} = state;
  // const id = action.payload.id;
  // const mainEntry = vessels.find((item: any) => item.id === id);
  // if (mainEntry) {
  //   vessels.push({...mainEntry, id: uuidv4()})
  // }
  // return {...state, vessels: [...vessels]};
};
