import React, {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { useEffect } from "react";
import TagManager from 'react-gtm-module';
import {
  MsalAuthenticationTemplate,
  MsalProvider,
} from "@azure/msal-react";
import {
  EventType,
  InteractionType,
  IPublicClientApplication,
} from "@azure/msal-browser";
import { b2cPolicies, loginRequest } from "./authConfig";

import { HomeComponent } from "./components";

import AqDashboardComponent from "./containers/dashborad/aq-dashboard.component";
import AqProjectsComponent from "./containers/pages/dashboard/aq-projects.component";
import AqRequestMetting from "./containers/pages/request-meeting/index";

import styled from "styled-components";
import { DarkBlue, White } from "./Styles";
import "./styles/App.css";

const AppWrapper = styled.div`
  .aq-app-root {
    padding: 100px;
    background-color: ${DarkBlue};
    text-align: center;
    height: 100vh;
    box-sizing: border-box;
    overflow: auto;

    .aq-bg-1 {
      position: absolute;
      background-image: url(${process.env.PUBLIC_URL + "/icons/group_149.png"});
      background-repeat: no-repeat;
      width: 120px;
      height: 120px;
      left: 28%;
      top: 40px;
    }

    .aq-bg-2 {
      position: absolute;
      background-image: url(${process.env.PUBLIC_URL + "/icons/group_148.png"});
      background-position: -85px -120px;
      background-repeat: no-repeat;
      width: 135px;
      height: 136px;
      left: 30px;
      top: 330px;
    }

    .aq-bg-3 {
      position: absolute;
      background-image: url(${process.env.PUBLIC_URL + "/icons/group_149.png"});
      background-repeat: no-repeat;
      width: 120px;
      height: 120px;
      right: 34px;
      bottom: 30%;
    }

    .aq-bg-cover {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 90px 22px 55px;
      background-color: ${White};
      border-radius: 5px;
    }
    .aq-app-panel {
      background-color: ${White};
      position: absolute;
      left: 90px;
      right: 90px;
      top: 90px;
      bottom: 140px;
      min-height: 400px;
      border-radius: 10px;
      padding: 30px;

      .aq-app-content {
        height: 100%;
        overflow: auto;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .aq-app-root {
      padding: 0;
    }
    .aq-app-root .aq-app-panel {
      left: 0;
      right: 0;
      padding: 0;
      border-radius: 0;
      bottom: 0;
      top: 0;
    }
  }
`;

function PrivateRoute(props: any) {
  const { component: Component, authed, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/signup" }} />
        )
      }
    />
  );
}

const Routes = [
  {
    path: "/projects/:id",
    protected: true,
    authed: true,
    component: AqDashboardComponent,
  },
  {
    path: "/projects",
    component: AqProjectsComponent,
  },
  {
    path: "/request-meeting/:id",
    component: AqRequestMetting,
  },
  //   {
  //     path: "/auth",
  //     component: Auth,
  //   },
  {
    path: "",
    component: HomeComponent,
  },
];

const Pages = () => {
  return (
    <Switch>
      {Routes.map((route: any, i: number) => {
        if (route.protected) {
          return <PrivateRoute key={`root-route${i}`} {...route} />;
        }
        return <Route key={`root-route${i}`} {...route} />;
      })}
    </Switch>
  );
};

const App = ({ instance }: { instance: IPublicClientApplication }) => {
  useEffect(() => {
    if (process.env.REACT_APP_GTM_ID) {
      TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
    }
  }, []);

  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  // const { instance } = useMsal();

  /**
   * Using the event API, you can register an event callback that will do something when an event is emitted.
   * When registering an event callback in a react component you will need to make sure you do 2 things.
   * 1) The callback is registered only once
   * 2) The callback is unregistered before the component unmounts.
   * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
   */
   useEffect(() => {
    const callbackId = instance.addEventCallback((event: any) => {
      if (event.eventType === EventType.LOGIN_FAILURE && event.error) {
        if (event.error.errorMessage.indexOf("AADB2C90118") > -1) {
          if (event.interactionType === InteractionType.Redirect) {
            instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
          } else if (event.interactionType === InteractionType.Popup) {
            instance
              .loginPopup(b2cPolicies.authorities.forgotPassword)
              .catch((e) => {
                return;
              });
          }
        }
        else if (event.error.errorMessage.indexOf("AADB2C90091") > -1) {
          console.log('User has cancelled the authentication process, restarting the app');
          window.location.reload();
        }
      }

      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ) {
        if (event?.payload) {
          /**
           * We need to reject id tokens that were not issued with the default sign-in policy.
           * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
           * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
           */
          if (
            event.payload.idTokenClaims["acr"] ===
            b2cPolicies.names.forgotPassword
          ) {
            window.alert(
              "Password has been reset successfully. \nPlease sign-in with your new password."
            );
            return instance.logout();
          } else if (
            event.payload.idTokenClaims["acr"] === b2cPolicies.names.editProfile
          ) {
            window.alert(
              "Profile has been edited successfully. \nPlease sign-in again."
            );
            return instance.logout();
          }

          if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.accessToken) {
              sessionStorage.setItem("Authorization", `${event.payload.tokenType} ${event.payload.accessToken}`);
            }
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  return (
    <Router>
      <MsalProvider instance={instance}>
        <AppWrapper>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequest}
          >
            <Pages/>
          </MsalAuthenticationTemplate>
        </AppWrapper>
      </MsalProvider>
    </Router>
  );
};

export default App;
