import styled, { css } from 'styled-components'
import {
  BtnWidth,
  Disabled,
  FontFamily,
  FontSize14,
  H1Size,
  Light,
  Line,
  Neutral,
  Primary,
  Secondary,
  TxtLineHeight,
  TxtSize,
  White
} from '../../../Styles';


const btn = (bg: any, color: any = White) => css`
  white-space: nowrap;
  border-radius: 6px;
  margin: 5px auto;
  cursor: pointer;
  font-family: ${FontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: ${TxtSize};
  line-height: ${TxtLineHeight};
  color: ${color};
  background: ${bg};
  height: 54px;
  width: ${BtnWidth};
  border: 1px solid ${Line};
  transition: transform 0.1s ease-in-out;
  
  &:focus {
    outline: 0px solid ${bg};
  }
  &:visited {
    color: ${color};
  }
  &:not([disabled]):hover {
    opacity: 0.8;
    
    &[disabled] {
      background: ${bg};
    }
  }
  &:not([disabled]):active {
    transform: scale(1.05);
    box-shadow: 2px 2px 7px #dcdbdb;
    opacity: 1;
  }
  &:visited {
    color: black;
  }
  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed !important;
    background-color: ${Disabled} !important;
    color: ${White} !important;
    border-color: ${Disabled} !important;
  }
  
  &.medium {
    height: 36px;
    padding: 0 30px;
    width: auto;
  }
  
  .icon-download:before {
    content: url(${process.env.PUBLIC_URL + '/icons/download.svg'});
    padding-right: 5px;
  }
  
  &.small {
    height: 27px;
    padding: 0 12px;
    width: auto;
    
    .icon-edit:before {
      content: url(${process.env.PUBLIC_URL + '/icons/edit.png'});
      padding-right: 5px;
    }
    .icon-bin:before {
      content: url(${process.env.PUBLIC_URL + '/icons/bin.png'});
      padding-right: 5px;
    }
    .icon-duplicate:before {
      content: url(${process.env.PUBLIC_URL + '/icons/duplicate.png'});
      padding-right: 5px;
    }
  }
  
  &.rounded {
    height: 36px;
    border-radius: 100px;
    padding: 0 18px;
    padding: 0 18px;
    width: auto;
    font-size: ${FontSize14};
  }
  
  .btn-arrow-l {
    padding-right: 5px;
    font-size: ${H1Size};
  }
  .btn-arrow-r {
    padding-left: 5px;
    font-size: ${H1Size};
  }
`

const btnInvert = (color: any, bg: any = White) => css`
  color: ${color};
  background: ${bg};
  border-color: ${color};
`

const btnDefault = css`
  ${btn(White, Light)};
`

const btnPrimary = btn(Primary);
const btnPrimaryInvert = btnInvert(Primary);
const btnSecondary = btn(Secondary);
const btnSecondaryInvert = btnInvert(Secondary);
const btnNeutral = btn(Neutral);
const btnNeutralInvert = btnInvert(Neutral);

const form = css`
      font-family: ${FontFamily};
    
      button {
        display: flex;
        align-items: center;
        flex-flow: row nowrap;
        justify-content: center;
        margin-top: 15px;
      }
      button.btn-default {
        ${btnDefault};
      }
      
      button.btn-primary {
        ${btnPrimary};
        
        &.invert {
          ${btnPrimaryInvert}
        }
      }
      
      button.btn-secondary {
         ${btnSecondary};
         
         &.invert {
          ${btnSecondaryInvert}
        }
      }
      
      button.btn-neutral {
       ${btnNeutral};
       
       &.invert {
        ${btnNeutralInvert};
       }
      }
`

export default styled.div`
  ${form}
`
