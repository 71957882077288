import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import AqCard from "../../components/card/aq-card.component";
import AqButtonComponent from "../../components/seeds/aq-button.component";
import {
  fetchVessels,
  onNavigate,
  selectProfile,
  updatePage,
} from "../../controller/actions";
import { USER_INFO } from "../../helpers/constants";
import { getSession } from "../../helpers/utils";
import { CardTypes } from "../../controller/models";

const ProfileWrapper = styled.div`
  text-align: left;
  padding: 100px;
  display: flex;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;
  align-items: center;

  .aq-profile-title {
  }
  .aq-profile-content {
    display: flex;
    margin: 30px 0;
    flex-wrap: wrap;
  }
  .aq-profile-footer {
    height: 54px;
    margin-top: 100px;
    display: flex;
    justify-content: center;
  }
  @media screen and (max-width: 677px) {
    align-items: baseline;
    .aq-profile-footer {
      position: absolute;
      bottom: 30px;
      transform: translateX(50%);
      margin: 0;
      button {
        height: 36px;
        width: 140px;
        font-size: 14px;
      }
    }
  }
`;
class AqProfileComponent extends Component<any, any> {
  constructor(props: any) {
    super(props);
    const userInfo = getSession(USER_INFO, true);
    this.state = {
      userInfo: userInfo || {},
      cards: [
        {
          name: "card",
          footer: "Lorem ipsum dolor sit amet. consectetur adipscing elit.",
          selected: false,
          value: "borrower",
          type: CardTypes.borrower,
          id: 2,
        },
        {
          name: "card",
          footer: "Lorem ipsum dolor sit amet. consectetur adipscing elit.",
          selected: false,
          inverted: true,
          value: "invester",
          type: CardTypes.inverster,
          id: 1,
        },
      ],
      isCardSelected: false,
      token: "",
    };
  }

  componentDidMount(): void {
    const { root } = this.props;
    let isCardSelected = false;
    let selectedCard = false;
    const { cards } = this.state;
    if (root && root.context) {
      isCardSelected = true;
      selectedCard = root.context.profile;
      cards.forEach(
        (card: any) => (card.selected = card.value === selectedCard)
      );
    }
    this.setState({ cards, selectedCard, isCardSelected });
  }

  /**
   * Cards selection handler
   * @param ev
   */
  handleCardSelection = (ev: any) => {
    const cards = this.state.cards;
    let selectedCard;
    const selected = cards.find((card: any) => card.id === ev.id);
    if (selected) {
      selectedCard = selected.value;
    }
    cards.forEach((itm: any) => {
      itm.selected = itm.id === ev.id;
    });
    this.setState({ cards, isCardSelected: true, selectedCard });
  };

  handleContinue = (ev: any) => {
    this.props.selectProfile(
      { profile: this.state.selectedCard },
      this.props.history
    );
  };

  /**
   * Returns cards node
   */
  getCards = (): React.ReactNode => {
    const { cards } = this.state;
    return cards.map((card: any, i: number) => (
      <>
        <AqCard
          key={`card_${i}`}
          selected={card.selected}
          id={card.id}
          inverted={card.inverted}
          type={card.type}
          footer={card.footer}
          name={card.name}
          onChange={this.handleCardSelection}
        />
      </>
    ));
  };

  render(): React.ReactNode {
    const { userInfo, isCardSelected } = this.state;
    return (
      <ProfileWrapper>
        <div>
          <div className={"aq-profile-title"}>
            Hi {userInfo.name}, <br />
            Choose your profile to continue
          </div>
          <div className={"aq-profile-content"}>{this.getCards()}</div>
          <div className={"aq-profile-footer"}>
            {isCardSelected && (
              <AqButtonComponent
                label={"Continue"}
                onClick={this.handleContinue}
              />
            )}
          </div>
        </div>
      </ProfileWrapper>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { root } = state;
  return { root };
};
const mapDispatchToProps = {
  onNavigate,
  fetchVessels,
  updatePage,
  selectProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(AqProfileComponent);
