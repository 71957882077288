import React, { Component } from "react";
import { connect } from "react-redux";
import AqButtonComponent from "../../../components/seeds/aq-button.component";
import AqTableComponent from "../../../components/table/aq-table.component";
import dispatchers from "../../../controller/actions";
import { IApplicationState } from "../../../controller/models";
import { getSelectedVessel } from "../../../helpers/utils";
import DocumentPanel from "./aq-document-panel.component";
import AqProjectWrapper from "./aq-project-wrapper";
import { getProjectsList } from "../../../controller/actions/projects";
import {
  strictValidArrayWithLength,
  formatNumberWithCurrency,
} from "../../../controller/utils/commonUtils";
import { isMobile } from "react-device-detect";
import { EsfLeftPanel } from "../../leftpanel";
import { Link } from "react-router-dom";

/**
 * multiple Vessel popup
 * @param props
 * @constructor
 */
const VesselPopup = function (props: any) {
  return (
    <ul className={"aq-action-wrapper left"}>
      <div className={"popup-hanger"} />
      {props.row &&
        props.row.vessels &&
        props.row.vessels.map((item: any, i: number) => (
          <li
            key={`more-vessel-${i}`}
            onClick={() => props.onClick("vessel", props, item)}
          >
            {item}
          </li>
        ))}
    </ul>
  );
};

/**
 * Action popup
 * @param props
 * @constructor
 */
const ActionPopup = function (props: any) {
  return (
    <ul className={"aq-action-wrapper"}>
      <div className={"popup-hanger"} />
      <li
        onClick={() => props.onClick("action", props, "requestMeeting")}
        className={"aq-icon-calender"}
      >
        Request a meeting
      </li>
      <li
        onClick={() => props.onClick("action", props, "UploadDocument")}
        className={"aq-icon-document"}
      >
        Documents
      </li>
      <li
        onClick={() => props.onClick("action", props, "updateDetails")}
        className={"aq-icon-clock"}
      >
        Update details
      </li>
      <li
        onClick={() => props.onClick("action", props, "downloadPlan")}
        className={"aq-icon-download"}
      >
        Download business plan
      </li>
    </ul>
  );
};

class AqProjectsComponent extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      hideMenu: isMobile,
      gridMode: true,
      searchValue: "",
      documentPanel: false,
      limit: 1000,
      skip: 0,
      fields: [
        { label: "#", field: "id", style: { width: 50 } },
        {
          label: "Company Name",
          field: "companyName",
          style: { minWidth: 200, flex: "1" },
          renderer: this.fieldRenderer,
        },
        {
          label: "Vessel Name",
          field: "vesselName",
          style: { minWidth: 150, flex: "1" },
          renderer: this.fieldRenderer,
        },
        {
          label: "Vessel(s) Value",
          field: "vesselValue",
          style: { width: 122 },
          renderer: this.fieldRenderer,
        },
        {
          label: "Funding",
          field: "funding",
          style: { width: 100 },
          renderer: this.fieldRenderer,
        },
        {
          label: "Loan Term",
          field: "loanTerm",
          style: { width: 90 },
          renderer: this.fieldRenderer,
        },
        {
          label: "Status",
          field: "status",
          style: { width: 65 },
          renderer: this.fieldRenderer,
        },
        {
          label: "Actions",
          field: "action",
          is_action: true,
          style: { width: 75, justifyContent: "center" },
          renderer: this.fieldRenderer,
          mobRender: this.fieldRenderer,
        },
      ],
    };
  }

  componentDidMount(): void {
    // this.setState({ data: this.createTblData(this.props.vessels) });
    // console.log(formatNumberWithCurrency(2344));
    this.onSearch("");
    this.props.getUserData();
    // this.props.getDocTypeList();
  }

  /**
   * search listener
   * @param ev
   */
  onSearch = async (val: any) => {
    const { limit, skip } = this.state;
    this.setState({ searchValue: val });
    let query = `skip=${skip}&limit=${limit}&search=${val}`;
    const res = await this.props.getProjectsList(query);
    if (res) {
      this.setState({ data: this.createTblData(this.props.vessels) });
    }
  };

  /**
   * Toggle left menu panel
   */
  toggleMenu = () => {
    this.setState({ hideMenu: !this.state.hideMenu });
  };

  handleActionClick = (row: any, field: any) => {
    console.log("handleActionClick called >>>> ", row);
  };

  /**
   * action popup click listener
   * @param type
   * @param row
   * @param id
   */
  handlePopupActionClick = (type: string, row: any, id: any) => {
    // console.log("handlePopupActionClick", type, row, id);

    //  START FSPL Fixed
    if (row.item) {
      var projectId = row.item.projectId;
      var newrow = row.item;
    } else {
      projectId = row.row.projectId;
      newrow = row.row;
    }
    // End FSPL

    if (type === "action") {
      if (id === "UploadDocument") {
        this.setState({ documentPanel: projectId });
      }
      if (id === "requestMeeting") {
        this.props.history.push(`/request-meeting/${projectId}`);
      }

      if (id === "updateDetails") {
        sessionStorage.setItem("__projectId", projectId);
        this.props.history.push(`/projects/${projectId}/company-info`);
      }

      if (id === "downloadPlan") {
        this.props.downloadBusinessPlan(projectId);
      }
      this.toggleActionPopup(row);
    } else if (type === "vessel") {
      this.toggleVesselPopup(row);
      this.editVessel(id, newrow);
    }
  };

  /**
   * hide documentPanel and show list view
   */
  hideDocumentPanel = () => {
    this.setState({ documentPanel: undefined });
  };

  /**
   * Custom field renderer
   * @param row
   * @param field
   */
  fieldRenderer = (row: any, field: any) => {
    const { company } = row;
    switch (field.field) {
      case "action":
        return this.renderActions(row, field);
      case "companyName":
        return <div>{company}</div>;
      case "vesselName":
        return this.renderVesselName(row, field);
      case "vesselValue":
        return <div>{formatNumberWithCurrency(row.acquisitionCost)}</div>;
      case "funding":
        return <div>{formatNumberWithCurrency(row.funding)}</div>;
      case "loanTerm":
        return <div>{row.loanTerm} Months</div>;
      case "status":
        return <div>{row.status}</div>;
      default:
        return row[field.field];
    }
  };

  /**
   * toggle multiple vessel popup
   * @param row
   */
  toggleVesselPopup = (row: any) => {
    const { data } = this.state;
    data.forEach((item: any) => {
      item.actionPopup = false;
      if (item.projectId === row.projectId) {
        item.vesselPopup = !row.vesselPopup;
      } else {
        item.vesselPopup = false;
      }
    });
    this.setState({ data });
  };

  /**
   * toggle row action popup
   * @param row
   */
  toggleActionPopup = (row: any) => {
    const { data } = this.state;
    data.forEach((item: any) => {
      item.vesselPopup = false;
      if (item.projectId === row.projectId) {
        item.actionPopup = !row.actionPopup;
      } else {
        item.actionPopup = false;
      }
    });
    this.setState({ data });
  };

  /**
   * vessenName field renderer
   * @param row
   * @param field
   */
  renderVesselName = (row: any, field: any) => {
    if (strictValidArrayWithLength(row.vessels)) {
      if (row.vessels.length === 1) {
        return (
          <div className={"aq-vessel-cell"}>
            <div onClick={() => this.toggleVesselPopup(row)}>
              {row.vessels[0]}
            </div>
            {row.vesselPopup &&
              VesselPopup({ row, field, onClick: this.handlePopupActionClick })}
          </div>
        );
      } else {
        return (
          <div className={"aq-vessel-cell"}>
            <div onClick={() => this.toggleVesselPopup(row)}>
              Multiple Vessels...
            </div>
            {row.vesselPopup &&
              VesselPopup({ row, field, onClick: this.handlePopupActionClick })}
          </div>
        );
      }
    }
  };

  /**
   * row action renderer
   * @param row
   * @param field
   */
  renderActions = (row: any, field: any) => {
    return (
      <div
        className={"aq-action-cell"}
        onClick={() => this.handleActionClick(row, field)}
      >
        <div
          onClick={() => this.toggleActionPopup(row)}
          className={"aq-actions-dot"}
        >
          <div className={"aq-more"}>
            <span />
            <span />
            <span />
          </div>
        </div>
        {row.actionPopup &&
          ActionPopup({ row, field, onClick: this.handlePopupActionClick })}
      </div>
    );
  };

  /**
   * create tbl data wrapper on main data
   */
  createTblData = (vessels: any) => {
    // console.log("createTblData", vessels);
    return vessels.map((row: any) => ({
      ...row,
      actionPopup: false,
      vesselPopup: false,
    }));
  };

  /**
   * Returns Table component
   */
  getVessels = () => {
    const { fields, data } = this.state;
    if (data) {
      return <AqTableComponent fields={fields} data={data} />;
    }
    return null;
  };

  /**
   * edit action listener
   * @param vessel
   * @param row
   */
  editVessel = (vessel: any, row: any) => {
    console.log("vessel click valled ", vessel, row);
  };

  /**
   * Returns vessel card for grid view
   * @param vessels
   * @param row
   */
  getVesselCard = (vessels: any, row: any) => {
    if (strictValidArrayWithLength(vessels)) {
      const btnStyle = { width: "100%", color: "#091859", fontSize: 12 };
      if (vessels.length === 1) {
        return (
          <AqButtonComponent
            className={"btn-default invert small"}
            style={btnStyle}
            label={vessels[0]}
            onClick={() => this.editVessel(vessels[0], row)}
          />
        );
      } else {
        return (
          <div className={"aq-vessel-cell"}>
            <AqButtonComponent
              className={"btn-default invert small"}
              style={btnStyle}
              onClick={() => this.toggleVesselPopup(row)}
              label={"Multiple Vessels.."}
            />
            {row.vesselPopup &&
              VesselPopup({ row, onClick: this.handlePopupActionClick })}
          </div>
        );
      }
    }
  };

  /**
   * Returns grid view element
   */
  getProjectsGrid = () => {
    const { data } = this.state;
    if (data) {
      return data.map((item: any, i: number) => {
        const stausCls = item.status === "Open" ? "s-open" : "s-close";
        return (
          <div key={`grid_${i}`} className={"aq-grid-cell"}>
            <div className={"header"}>
              <div className={"flex-between"}>
                <div className={"aq-id-cell"}>#&nbsp;{item.projectId}</div>
                <div className={"meet-link"} onClick={() => this.props.history.push(`/request-meeting/${item.projectId}`)}>Meet</div>
              </div>
              <div className={"flex-between"}>
                <div className={"aq-grid-row-2"}>{item.company}</div>
                <div className={"flex rt-sm-tool"}>
                  <div className={`aq-status ${stausCls}`}>{item.status}</div>
                  <div className={"aq-actions-dot"}>
                    <div
                      className={"aq-more"}
                      onClick={() => this.toggleActionPopup(item)}>
                      <span />
                      <span />
                      <span />
                    </div>
                    {item.actionPopup &&
                      ActionPopup({
                        item,
                        onClick: this.handlePopupActionClick,
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className={"body"}>
              <div className={"aq-grid-row-3 flex"}>
                <div className={"aq-grid-col-1 flexible"}>
                  <div className={"aq-rect funding"} />
                  <div className={"aq-badge-title"}>Funding</div>
                  <div className={"aq-grid-col-value"}>
                    {formatNumberWithCurrency(item.funding)}
                  </div>
                </div>
                <div className={"aq-grid-col-2 flexible"}>
                  <div className={"aq-rect loan"} />
                  <div className={"aq-badge-title"}>Loan Term</div>
                  <div className={"aq-grid-col-value"}>{item.loanTerm}</div>
                </div>
                <div className={"aq-grid-col-3 flexible"}>
                  <div className={"aq-rect cost"} />
                  <div className={"aq-badge-title"}>Total Value</div>
                  <div className={"aq-grid-col-value highlight"}>
                    {formatNumberWithCurrency(item.acquisitionCost)}
                  </div>
                </div>
              </div>
              <div>{this.getVesselCard(item.vessels, item)}</div>
            </div>
          </div>
        );
      });
    }
    return null;
  };

  /**
   * Returns custom classes based on props
   */
  getClasses = () => {
    const cls = {
      rightPanel: "right-panel",
    };
    return cls;
  };

  /**
   * Create new project
   */
  createNewProject = () => {
    this.props.addVessel("new", { history: this.props.history });
  };

  /**
   * Toggle view mode between grid and list
   */
  toggleViewMode = (gridMode: boolean) => {
    this.setState({ gridMode });
  };

  /**
   * Returns toolbar component
   */
  getToolbar = () => {
    const { gridMode } = this.state;
    return (
      <>
        <div className={"flexible project-title"}>Projects</div>
        <div>
          <AqButtonComponent
            onClick={this.createNewProject}
            label={"Create New"}
            className={"btn-neutral medium "}
          />
        </div>
        <div className={"aq-icons-container flex"}>
          <div
            className={`aq-icon-dashboard ${gridMode ? "disabled" : ""}`}
            onClick={() => this.toggleViewMode(true)}
          />
          <div
            className={`aq-icon-list ${gridMode ? "" : "disabled"}`}
            onClick={() => this.toggleViewMode(false)}
          />
        </div>
      </>
    );
  };

  // onDocumentUpload = (project: any, document: any) => {
  //   this.props.addDocumentToProject({ projectId: project.projectId, document });
  // };

  documentAction = (action: any, project: any) => {
    console.log("documentAction", action, project);
    this.props.deleteDocumentFromProject({
      projectId: project.projectId,
      documentId: action.row.id,
    });
  };
  getDocumentPanel = () => {
    const selecedDocPanel = this.state.documentPanel;
    const vessel = this.props.vessels.find(
      (item: any) => item.projectId === selecedDocPanel
    );
    return (
      <DocumentPanel
        initialValues={{}}
        vessel={vessel || []}
        onCancel={this.hideDocumentPanel}
        onAction={(action: any) => this.documentAction(action, vessel)}
        // onSubmit={(payload: any) => this.onDocumentUpload(vessel, payload)}
        {...this.props}
      />
    );
  };

  /**
   * Default render fn
   */
  render(): React.ReactNode {
    const cls = this.getClasses();
    const { searchValue, gridMode, documentPanel, hideMenu } = this.state;
    const { error, user = {} } = this.props;
    return (
      <AqProjectWrapper>
        {error && <div className={"aq-error-panel"}>{error.message}</div>}

        <EsfLeftPanel
          hideMenu={hideMenu === true}
          userFirstName={user.firstName}
          userLastName={user.lastName}
          onMyProjectsClicked={this.hideDocumentPanel}
        />

        <div className={cls.rightPanel}>
          <div className={"aq-project-header"}>
            <div onClick={this.toggleMenu} className={"aq-bread-menu"}>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className="mobile-view-profile">
              <div className={"user-img"}>
                <div className={"no-img"} />
              </div>
              <h3 className={"medium"}>
                {user.firstName} {user.lastName}
              </h3>
            </div>
            <div>
              <input
                className={"aq-search"}
                value={searchValue}
                onChange={(e) => this.onSearch(e.target.value)}
                placeholder={"Search"}
              />
            </div>
          </div>
          {!documentPanel && (
            <div>
              <div className={"aq-toolbar"}>{this.getToolbar()}</div>
              {!gridMode && (
                <div className={"aq-ds-tbl"}>{this.getVessels()}</div>
              )}
              {gridMode && (
                <div className={"aq-grid"}>{this.getProjectsGrid()}</div>
              )}
            </div>
          )}

          {documentPanel && this.getDocumentPanel()}
        </div>
      </AqProjectWrapper>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  const { root, dashboard } = state;
  const selectedVessel = getSelectedVessel(state);
  const vessels = dashboard.projects;
  return {
    ...selectedVessel,
    vessels: vessels,
    error: root.error,
    companyInfo: root.companyInfo,
    user: dashboard.users,
    exixtingDoc: dashboard.exixtingDoc,
    docTypeItems: dashboard.docTypeItems,
  };
};

const mapDispatchToProps = { ...dispatchers, getProjectsList };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AqProjectsComponent);
