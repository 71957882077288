import React, { Component } from "react";
import Dropzone from "./aq-file-dropzone";
import { uploadSingleFile } from "../../controller/https";
import "./upload.css";
import { strictValidArrayWithLength } from "../../controller/utils/commonUtils";

class Progress extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="ProgressBar">
        <div
          className="Progress"
          style={{ width: this.props.progress + "%" }}
        />
        <div
          className="ProgressValue"
          style={{ left: this.props.progress + "%" }}
        >
          {Math.round(this.props.progress) + "%"}
        </div>
      </div>
    );
  }
}

interface IAqUploadProps {
  onChange: any;
  name: string;
  files?: Array<any>;
  error?: any;
  uploadFileUrl?: any;
  passFile?: boolean;
  ref?: any;
}

export default class AqUploadComponent extends Component<IAqUploadProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  static getDerivedStateFromProps(props: IAqUploadProps) {
    let { files = [] } = props;
    if (strictValidArrayWithLength(files) && !files[0].name) {
      let details = {
        files: [{ name: files[0].value.value, value: files[0].value }],
        successfullUploaded: true,
        uploadProgress: {
          [files[0].value.value]: { state: "done", percentage: 100 },
        },
      };
      return { ...details };
    }
    return false;
  }

  onFilesAdded(files: any) {
    this.setState(
      {
        files,
      },
      () => {
        this.uploadFiles();
      }
    );
  }

  removeFiles = () => {
    this.props.onChange(this.props.name, "");
    this.setState({ files: [], successfullUploaded: false });
  };

  renderProgress(file: any) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <div className="ProgressWrapper">
          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
          <img
            className="CheckIcon"
            alt="done"
            src="./icons/tick.svg"
            style={{
              opacity:
                uploadProgress && uploadProgress.state === "done" ? 1 : 0,
            }}
          />
          <img
            className="CheckIcon"
            onClick={this.removeFiles}
            alt="done"
            src="./icons/trash.svg"
            style={{
              cursor: "pointer",
              opacity:
                uploadProgress && uploadProgress.state === "done" ? 1 : 0,
            }}
          />
        </div>
      );
    }
  }

  renderActions() {
    if (this.state.successfullUploaded) {
      return (
        <button
          onClick={() =>
            this.setState({ files: [], successfullUploaded: false })
          }
        >
          Clear
        </button>
      );
    } else {
      return (
        <button
          disabled={this.state.files.length < 0 || this.state.uploading}
          onClick={this.uploadFiles}
        >
          Upload
        </button>
      );
    }
  }

  async uploadFiles() {
    this.setState({ uploadProgress: {}, uploading: true });
    const promises: any = [];
    this.state.files.forEach((file: any) => {
      promises.push(this.sendRequest(file));
    });
    try {
      const res = await Promise.all(promises);
      console.log("res >>>>>>> file done >>>>> ", res);
      this.setState({ successfullUploaded: true, uploading: false });
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false });
    }
  }

  sendRequest(file: any) {
    const subUrl = this.props.uploadFileUrl;
    if (!this.props.passFile) {
      const res = uploadSingleFile(file, subUrl);
      res.then((item) => {
        if (!item.status) {
          this.props.onChange(this.props.name, {
            value: { id: 0, name: file.name },
          });
        } else {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = { state: "done", percentage: 100 };
          this.setState({ uploadProgress: copy, successfullUploaded: true });
          this.props.onChange(this.props.name, {
            value: { id: 1, name: file.name },
          });
        }
      });
    } else {
      const copy = { ...this.state.uploadProgress };
      copy[file.name] = { state: "done", percentage: 100 };
      this.setState({ uploadProgress: copy, successfullUploaded: true });
      // this.props.onChange(this.props.name, {
      //   value: { id: 1, name: file.name },
      // });
      this.props.onChange(this.props.name, file);
    }
  }

  componentDidMount(): void {
    const { files } = this.props;
    if (files && files.length) {
      // set default file value
      this.setState({
        files,
        successfullUploaded: true,
        uploadProgress: { [files[0].name]: { state: "done", percentage: 100 } },
      });
      this.props.onChange(this.props.name, files[0].value);
    }
  }

  render() {
    const { error } = this.props;
    const { files } = this.state;
    // console.log("upload file", this.props, this.state);
    return (
      <div className="Upload">
        <div className="Content">
          <Dropzone
            onFilesAdded={this.onFilesAdded}
            error={error}
            fileName={files[0] && files[0].name}
            disabled={this.state.uploading || this.state.successfullUploaded}
          >
            <div className="Files">
              {files.map((file: any) => {
                return (
                  <div key={file.name} className="Row">
                    {/*<span className="Filename">{file.name}</span>*/}
                    {this.renderProgress(file)}
                  </div>
                );
              })}
            </div>
          </Dropzone>
          {error && <div className={"error-txt"}>{error.error}</div>}
        </div>
      </div>
    );
  }
}
