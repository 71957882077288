import React, { FC, useEffect, useState } from "react";
import ReactSlider from 'react-slider';
import styled from "styled-components";

interface IEsfSliderProps {
  marks: ReadonlyArray<number>,
  step: number,
  value: number,
  valueType: string,
  onchange: ((value: number, percentage: number) => void) | undefined
}

const EsfSliderContainer = styled.div`
.horizontal-slider {
  width: 100%;
  height: 3em;
}

.slider-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #33be97;
  border: 3px solid #def9ed;
  border-radius: 100%;
  display: block;
  box-shadow: 0px 0px 2px 1px #b3b2b2;
}

.slider-thumb-middle {
  width: 4px;
  height: 4px;
  margin-top: -2px;
  margin-left: -2px;
  position: relative;
  top: 50%;
  left: 50%;
  border-radius: 100%;
  background-color: white;
}

.slider-thumb.active {
  background-color: #33be97;
}

.slider-track {
  position: relative;
  border: 1px solid #dedede;
  border-radius: 6px;
}

.slider-track.slider-track-0 {
  background: #33BE97;
}

.horizontal-slider .slider-track,
.horizontal-slider .slider-mark {
  height: 12px;
}

.horizontal-slider .slider-thumb {
  top: -7px;
  width: 22px;
  height: 22px;
  outline: none;
  line-height: 38px;
}

.horizontal-slider .slider-mark {
  padding-left: 5px;
  display: block;
  text-align: center;
  top: -2px;
  user-select: none;
}

.slider-value-type {
  position: relative;
  top: -5px;
  color: #FF9D27;
  font-size: 14px;
}

.horizontal-slider .slider-mark div:first-of-type {
  font-size: 11px;
  height: 17px;
  margin-bottom: 5px;
  color: #dedede;
}`

const EsfSlider: FC<IEsfSliderProps> = ({
  marks,
  step,
  value,
  valueType,
  onchange
}) => {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(100);

  useEffect(() => {
    if (marks && marks.length > 0) {
      setMin(marks[0]);
      setMax(marks[marks.length - 1]);
    }
  },
    [marks]);

  const onValueChanged = (value: number) => {
    if (max > 0 && onchange) {
      const percentage = Math.round((value - min) * (100 / (max - min)));
      onchange(value, percentage);
    }
  }

  return (
    <EsfSliderContainer>
      <div className="slider-value-type">{valueType}</div>
      <ReactSlider
        className="horizontal-slider"
        thumbClassName="slider-thumb"
        trackClassName="slider-track"
        markClassName="slider-mark"
        min={min}
        max={max}
        step={step}
        marks={marks}
        value={value}
        onChange={onValueChanged}
        renderMark={(props) => {
          return (
            <span
              {...props}>
              <div>{props.key === min || props.key === max ? "" : "|"}</div>
              <div>{props.key}</div>
            </span>
          );
        }}
        renderThumb={(props) => {
          return (
            <div
              {...props}>
              <div className="slider-thumb-middle" />
            </div>
          );
        }}
      />
    </EsfSliderContainer>
  );
};

export default EsfSlider;