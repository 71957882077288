import React, { PureComponent } from 'react';

import Styles from './styles/button';

interface IButtonProps {
  label: string;
  onClick: any;
  leftArrow?: boolean;
  icon?: string;
  rightArrow?: boolean;
  id?: string;
  className?: string;
  title?: string;
  type?: any;
  disabled?: boolean;
  style?: any;
}

export default class AqButtonComponent extends PureComponent<IButtonProps, any> {

  onClickHandler = (ev: any) => {
    ev.preventDefault();
    this.props.onClick(ev);
  }

  render() {
    const {label, type, className = 'btn-primary', id, leftArrow, rightArrow, icon, disabled, style, title} = this.props;
    return <Styles>
      <button title={title} disabled={disabled} className={className} id={id} style={style} type={type} onClick={this.onClickHandler}>
        {icon && <span className={`icon-${icon}`}/>}
        {leftArrow && <span className={'btn-arrow-l'}>&lt; </span>}{label}
        {rightArrow && <span className={'btn-arrow-r'}> &gt;</span>}
        {this.props.children}
      </button>
    </Styles>
  }
}
