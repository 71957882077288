import { Component, Fragment } from "react";
import styled from "styled-components";
import { require } from "yargs";
import { strictValidArrayWithLength } from "../../controller/utils/commonUtils";
import {
  FontFamilyMedium,
  FontSize14,
  Line,
  Primary,
  White,
  Disabled
} from "../../Styles";
import downPolygon from "../../img/down-Polygon.svg";

const AqTableWrapper = styled.div`
  .mobile-view {
    display: none;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 677px) {
    .table-view {
      display: none;
    }
    .mobile-view {
      display: block;
    }
  }
  .Collapse {
    border: 1px solid #dfe6f4;
    border-radius: 5px;
    box-shadow: 0 0 3px #ccc;
    margin-bottom: 5px;
    .collapse-header {
      font-family: Rubik;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: #333333;
      background: #dfe6f4;
      padding: 8px;
      justify-content: space-between;
      &.open {
        img {
          transform: rotate(180deg);
          filter: brightness(0);
        }
      }
      img {
        margin-right: 10px;
      }
    }
    .collapse-body {
      padding: 8px;
      font-family: Rubik;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      align-items: center;
      color: #333333;
      display: none;
      &.open {
        display: block;
        .flex {
          display: flex;
          strong {
            white-space: nowrap;
          }
        }
      }
    }
  }
  .aq-table {
    font-size: ${FontSize14};
    background-color: ${White};
    width: 100%;
    overflow: auto;

    table {
      border-spacing: 1px;
      width: 100%;
      th {
        white-space: nowrap;
      }
      tbody {
        tr {
          td {
            border-bottom: 1px solid #dedede;
          }
        }
      }
    }
    .aq-table-cell {
      padding: 10px;
      box-sizing: border-box;
      align-items: center;
      // display: flex;
      text-align: left;
    }

    .aq-table-header {
      // display: flex;
      background-color: ${Primary};

      .aq-table-cell {
        color: ${White};
        font-family: ${FontFamilyMedium};
        background-color: ${Primary};
      }
    }
    .aq-table-row {
      // display: flex;
      border-bottom: 1px solid ${Line};
      &.pending {
        pointer-events: none;
        opacity: 0.7;
      }
    }

    @media screen and (max-width: 760px) {
    }
  }
`;

interface IAqTableProps {
  fields: Array<{ [key: string]: any }>;
  data: Array<{ [key: string]: any }>;
  handleAction?: any;
  handleSelect?: any;
  titleKey?: any;
}

export default class AqTableComponent extends Component<IAqTableProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      openCollaps: [],
    };
  }

  getHeader = () => {
    const { fields } = this.props;
    return (
      <tr className={"aq-table-header"}>
        {fields.map((field: any, i: number) => {
          return (
            <th
              key={`tbl-header-cell-${i}`}
              style={field.style}
              className={"aq-table-cell"}
            >
              {field.label}
            </th>
          );
        })}
      </tr>
    );
  };

  getRow = (row: any, fields: any) => {
    const isPending = row['isPending'];
    return (
      <tr className={`aq-table-row${isPending ? ' pending' : ''}`}>
        {fields.map((field: any, i: number) => {
          if (typeof field.renderer === "function") {
            return (
              <td
                key={`tbl-row-cell-${i}`}
                style={field.style}
                className={"aq-table-cell"}
              >
                {field.renderer(row, field)}
              </td>
            );
          }
          return (
            <td
              key={`tbl-row-cell-${i}`}
              style={field.style}
              className={"aq-table-cell"}
            >
              {row[field.field]} {row["projectId"]}
            </td>
          );
        })}
      </tr>
    );
  };

  getRows = () => {
    const { fields, data } = this.props;
    return data.map((row: any, i: number) => this.getRow(row, fields));
  };

  getTable = () => {
    return (
      <div className={"aq-table"}>
        <table>
          <thead>{this.getHeader()}</thead>
          <tbody>{this.getRows()}</tbody>
        </table>
      </div>
    );
  };

  openCollapsFun = (val: any) => {
    let values = [...this.state.openCollaps];
    if (values.findIndex((item: any) => item === val) > -1) {
      values = values.filter((item: any) => item !== val);
    } else {
      values.push(val);
    }
    this.setState({ openCollaps: values });
  };

  isCollapseOpen = (val: any) => {
    return this.state.openCollaps.findIndex((item: any) => item === val) > -1
      ? true
      : false;
  };

  getCollapse = () => {
    const { fields, data, titleKey } = this.props;
    return (
      <div>
        {strictValidArrayWithLength(data) &&
          data.map((row: any, i: number) => {
            return (
              <div key={`cl-box${i}`} className={"Collapse"}>
                <div
                  className={`collapse-header ${
                    this.isCollapseOpen(`${i}`) ? "open" : ""
                  }`}
                >
                  <div onClick={() => this.openCollapsFun(`${i}`)}>
                    <span>
                      <img src={downPolygon} alt="img" />
                    </span>
                    <strong>
                      {titleKey ? row[titleKey] : `Collapse ${i}`}
                    </strong>
                  </div>
                  {fields.map((val, index) => {
                    return val.is_action ? (
                      <div className="action">{val.mobRender(row, val)}</div>
                    ) : (
                      ""
                    );
                  })}
                </div>
                <div
                  className={`collapse-body ${
                    this.isCollapseOpen(`${i}`) ? "open" : ""
                  }`}
                >
                  {fields.map((val, index) => {
                    if (titleKey !== val.field && !val.is_action) {
                      return (
                        <div key={`index-${index}`} className="flex">
                          <strong>{val.label} :&nbsp;</strong>
                          {typeof val.renderer === "function" ? (
                            <span style={val.style} className={"aq-cp"}>
                              {val.renderer(row, val)}
                            </span>
                          ) : (
                            <span style={val.style} className={"aq-cp"}>
                              {row[val.field]} {row["projectId"]}
                            </span>
                          )}
                          {/* {row[val.field]} */}
                        </div>
                      );
                    }
                    return "";
                  })}
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  render(): React.ReactNode {
    return (
      <AqTableWrapper>
        <div className="table-view">{this.getTable()}</div>
        <p />
        <div className="mobile-view">{this.getCollapse()}</div>
      </AqTableWrapper>
    );
  }
}
