import {
  ADD_COMPLANY_INFO,
  ADD_DOCUMENT_TO_PROJECT,
  ADD_EXPERIENCE_INFO,
  ADD_NEW_COMPANY_PROJECT,
  ADD_NEW_VESSEL,
  ADD_VESSEL_EMPLOYMENT,
  CLEAR_IMO_SELECTION,
  DELETE_DOCUMENT_FROM_PROJECT,
  DELETE_VESSEL_EMPLOYMENT,
  GET_EXPERIENCE_INFO,
  IS_LOADING,
  ON_DELETE_VESSEL,
  ON_DUPLICATE_VESSEL,
  ON_EDIT_VESSEL,
  ON_ERROR,
  ON_EXCEPTION,
  ON_NAVIGATE,
  ON_SIGN_UP,
  ON_VESSEL_SELECT,
  ON_VESSELS_FETCH,
  ON_VESSELS_FORM_SUBMIT,
  ON_VESSELS_OPEX_FORM_SAVE,
  ON_VESSELS_OPEX_FORM_SUBMIT,
  RESET_VESSEL,
  SELECT_PROFILE,
  SET_CONTEXT,
  UPDATE_PAGE,
  UPDATE_VESSEL_EMPLOYMENT,
  ADD_NEW_VESSEL_HEAD,
  BOOKIED_MEETING,
  EXISTING_DOC,
  LOOKUP_DOC_TYPE_LIST,
  COUNTRYFLAGS_LISTS,
  USER_INFO,
  PROJECT_PROGRESS,
} from "../../constants/action-types";
import {
  addCompanyExperienceHttp,
  addCompanyHttp,
  updateCompanyInfoHttp,
  addVesselEmploymentHttp,
  addVesselOpexHttp,
  addVesselsHttp,
  deleteVesselHttp,
  downloadBusinessPlanHttp,
  fetchVesselsHttp,
  getExperienceHttp,
  getVesselHttp,
  getVesselOpexHttp,
  getVesselsHttp,
  getProjectVesselsHttp,
  getProjectlist,
  getBookedMeeting,
  getExistingDoc,
  getCountryFlagsHttp,
  geLookuptDocTypeList,
  submitNewDocument,
  uploadDocumentFile,
  editDocument,
  getUserInfo,
  setUserProfile,
  getProjectAllVesselsHttp,
} from "../https";
import {
  validObjectWithParameterKeys,
  strictValidArrayWithLength,
  strictValidString,
  strictValidObject,
} from "../utils/commonUtils";

const vesselNoSessionKey = "__vesselNo";

export function clearImoSelection() {
  return (dispatch: any) => {
    dispatch({ type: CLEAR_IMO_SELECTION, payload: {} });
  };
}

// when user is new, get returns 404, then just create one
export const getUserData = () => {
  const ensureUserExists = async () => {
    let response = await getUserInfo();
    if (response.ok) {
      return await response.json();
    } else if (response.status !== 404) {
      throw new Error("Failed to get user info");
    }

    response = await setUserProfile("Borrower");
    if (!response.ok) {
      throw new Error("Failed to set new user details");
    }

    response = await getUserInfo();
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Failed to get user info");
    }
  };

  return (dispatch: any) => {
    return ensureUserExists().then(
      (userInfo) => {
        if (userInfo) {
          return dispatch({ type: USER_INFO, payload: userInfo });
        } else {
          return dispatch({
            type: ON_ERROR,
            payload: "Failed to get user details",
          });
        }
      },
      (error) => {
        return dispatch({ type: ON_ERROR, payload: error });
      }
    );
  };
};

export function projectProgress(payload: any) {
  return (dispatch: any) => dispatch({ type: PROJECT_PROGRESS, payload });
}

export function projectVessels(projectId: any) {
  return async (dispatch: any) => {
    const payload = await getProjectAllVesselsHttp(projectId);
    return dispatch({ type: ON_VESSELS_FETCH, payload });
  }
}

export function addVessel(projectId: any, payload: any) {
  return (dispatch: any) => {
    dispatch({
      payload: {},
      type: ADD_NEW_VESSEL,
    });
    sessionStorage.removeItem(vesselNoSessionKey);
    // onNavigate({});
    if (payload && payload.history) {
      if (projectId === "new") {
        sessionStorage.setItem("__projectId", "");
        dispatch({
          payload: {},
          type: ADD_COMPLANY_INFO,
        });

        // dispatch({
        //   payload: "clear",
        //   type: ADD_NEW_VESSEL_HEAD,
        // });
        payload.history.push(`/projects/new/company-info`);
      } else {
        dispatch({
          payload: "",
          type: ADD_NEW_VESSEL_HEAD,
        });
        payload.history.push(`/projects/${projectId}/vessels/new/search`);
      }
    }
  };
}

export const addNewCompanyProject = (payload: any) => {
  return (dispatch: any) =>
    dispatch({ type: ADD_NEW_COMPANY_PROJECT, payload });
};

export const doSignup = (payload: any, history: any) => {
  return (dispatch: any) => {
    dispatch({ type: ON_SIGN_UP, payload });
    history.push("/projects");
  };
};

export const addDocumentToProject = (payload: any) => {
  return (dispatch: any) =>
    dispatch({ type: ADD_DOCUMENT_TO_PROJECT, payload });
};
export const deleteDocumentFromProject = (payload: any) => {
  return (dispatch: any) =>
    dispatch({ type: DELETE_DOCUMENT_FROM_PROJECT, payload });
};
export const onNavigate = (payload: any) => {
  return { type: ON_NAVIGATE, payload };
};

export const updatePage = (payload: any) => {
  return (dispatch: any) => {
    dispatch({ type: UPDATE_PAGE, payload });
    if (payload.resetVessel) {
      dispatch({ type: RESET_VESSEL });
    }
  };
};
export const selectProfile = (payload: any, history: any) => {
  return (dispatch: any) => {
    setUserProfile(payload.profile).then((response) => {
      if (response.ok) {
        getProjectlist("").then(
          (res: any) => {
            // console.log("project list res", res);
            if (
              validObjectWithParameterKeys(res, ["items"]) &&
              strictValidArrayWithLength(res.items) &&
              res.total > 0
            ) {
              dispatch({ type: SELECT_PROFILE, payload });
              history.push("/dashboard");
            } else if (
              validObjectWithParameterKeys(res, ["items"]) &&
              !strictValidArrayWithLength(res.items) &&
              res.total === 0
            ) {
              dispatch({ type: SELECT_PROFILE, payload });
              history.push("/profile/company-info");
            } else {
              dispatch({ type: ON_ERROR, payload: { message: res.Message } });
            }
          },
          (error) => {
            // console.log("project list error", error);
            dispatch({ type: ON_ERROR, payload: error });
          }
        );
      } else {
        dispatch({ type: ON_ERROR, payload: "Failed to save user profile" });
      }
    });
  };
};

export const submitVesselForm = (projectId: any, vesselNo: any, payload: any, history: any) => {
  return (dispatch: any) => {
    return addVesselsHttp(projectId, vesselNo, payload).then(
      (res: any) => {
        log("[success]", "submitVesselForm");
        if (res && res.vesselNo) {
          sessionStorage.setItem("__vesselNo", res.vesselNo);
          sessionStorage.setItem("__duplicate", "");
          dispatch({ type: ON_VESSELS_FORM_SUBMIT, payload });
          history.push(`/projects/${projectId}/vessels/${res.vesselNo}/opex`);
        } else if (res.status === 202) {
          dispatch({ type: ON_VESSELS_FORM_SUBMIT, payload });
          history.push(`/projects/${projectId}/vessels/${vesselNo}/opex`);
        } else {
          dispatch({
            type: ON_ERROR,
            payload: { message: res.Message || res.title },
          });
        }
      },
      (error) => {
        log("[ERROR]: submitVesselForm", error);
        dispatch({ type: ON_ERROR, payload: error });
      }
    );
  };
};

function log(p1: any, p2?: any) {
  console.log(p1, p2);
}

function showLoading(dispatch: any) {
  dispatch({
    payload: true,
    type: IS_LOADING,
  });
}

function hideLoading(dispatch: any) {
  dispatch({
    payload: false,
    type: IS_LOADING,
  });
}

export const addCompanyInfo = (payload: any, history: any) => {
  return (dispatch: any) => {
    log("addCompanyInfo payload ", payload);
    if (payload.projectId) {
      sessionStorage.setItem("__projectId", payload.projectId);
      history.push(`/projects/${payload.projectId}/vessels/new/search`);
    } else {
      showLoading(dispatch);
      return addCompanyHttp(payload).then(
        (res: any) => {
          log("[LOG], success addCompanyHttp", res);
          hideLoading(dispatch);
          if (res.projectId) {
            sessionStorage.setItem("__projectId", res.projectId);
            dispatch({
              payload: { ...payload, projectId: res.projectId },
              type: ADD_COMPLANY_INFO,
            });
            dispatch({
              payload: "clear",
              type: ADD_NEW_VESSEL_HEAD,
            });
            history.push(`/projects/${res.projectId}/vessels/new/search`);
          } else {
            dispatch({
              type: ON_ERROR,
              payload: {
                message:
                  res.Message ||
                  "Oops! Something went wrong while saving company information [server error]",
              },
            });
          }
        },
        (error) => {
          log("[LOG], error addCompanyHttp", error);
          hideLoading(dispatch);
          dispatch({
            type: ON_ERROR,
            payload: {
              message:
                "Oops! Something went wrong while saving company information [server error]",
            },
          });
        }
      );
    }
  };
};

export const updateCompanyInfo = (payload: any, history: any) => {
  return (dispatch: any) => {
    if (!payload.projectId) {
      dispatch({
        type: ON_ERROR,
        payload: {
          message:
            "Oops! Something went wrong while saving company information [server error]",
        },
      });
      return;
    }
    showLoading(dispatch);
    try {
      updateCompanyInfoHttp(payload).then((response) => {
        if (response.ok) {
          dispatch({
            payload: { ...payload, projectId: payload.projectId },
            type: ADD_COMPLANY_INFO,
          });
          getProjectVesselsHttp(payload.projectId).then((response) => {
            if (response.ok) {
              response.json().then((vessels) => {
                // project has any vessels?
                if (vessels && vessels.length > 0) {
                  let recentVesselNo = sessionStorage.getItem("__vesselNo");
                  if (!!!recentVesselNo) {
                    recentVesselNo = (vessels.length - 1).toString();
                  }
                  hideLoading(dispatch);
                  history.push(`/projects/${payload.projectId}/funding`);
                }
                else
                {
                  // no project vessels
                  dispatch({
                    payload: "clear",
                    type: ADD_NEW_VESSEL_HEAD,
                  });
                  hideLoading(dispatch);
                  history.push(`/projects/${payload.projectId}/vessels/new/search`);
                }
              });
            }
            else {
              dispatch({
                type: ON_ERROR,
                payload: {
                  message:
                    "Failed to get the list of project vessels",
                },
              });
            }
          });
        }
      });
    } catch (error) {
      log("[LOG], error updateCompanyInfoHttp", error);
      dispatch({
        type: ON_ERROR,
        payload: {
          message:
            "Oops! Something went wrong while saving company information [server error]",
        },
      });
    }
    hideLoading(dispatch);
  };
};

export const addVesselEmployment = (payload: any) => {
  return (dispatch: any) => {
    return addVesselEmploymentHttp(payload).then(
      (res: any) => {
        log("[success] addVesselEmployment", res);
        return res;
        // dispatch({type: ADD_VESSEL_EMPLOYMENT, payload});
      },
      (error) => {
        log("[ERROR]: addVesselEmployment", error);
        dispatch({ type: ON_ERROR, payload: error });
      }
    );
  };
};
export const selectVesselType = (payload: any) => {
  return {
    payload,
    type: ON_VESSEL_SELECT,
  };
};
export const updateVesselEmployment = (payload: any) => {
  return {
    payload,
    type: UPDATE_VESSEL_EMPLOYMENT,
  };
};
export const deleteVesselEmployment = (payload: any) => {
  return {
    payload,
    type: DELETE_VESSEL_EMPLOYMENT,
  };
};
export const addDuplicateVessel = (payload: any) => {
  return {
    type: ON_DUPLICATE_VESSEL,
    payload,
  };
};
export const editVessel = (payload: any) => {
  return {
    type: ON_EDIT_VESSEL,
    payload,
  };
};

export const deleteVessel = (payload: any = {}) => {
  return (dispatch: any) => {
    return deleteVesselHttp().then((res) => {
      if (res.error) {
      } else if (res.message) {
        // vessel deleted successfully, clear from memory
        sessionStorage.removeItem("__vesselNo");
        if (payload.history) {
          payload.history.push("/projects/search");
        }
      }
    });
  };
};

export const addCompanyExperience = (payload: any, isLast: boolean) => {
  return (dispatch: any) => {
    return addCompanyExperienceHttp(payload).then(
      (res) => {
        dispatch({
          payload,
          type: ADD_EXPERIENCE_INFO,
        });
      },
      (error) => {
        dispatch({
          type: ON_ERROR,
          payload: {
            message:
              "Oops! Something went wrong while saving company experience information [server error]",
          },
        });
      }
    );
  };
};

function downloadBusinessPlan(projectId: any, history: any) {
  return (dispatch: any) => {
    if (projectId) {
      return downloadBusinessPlanHttp(projectId).then(
        (res: any) => {
          if (res.error === 200) {
            log("[downloadBusinessPlanHttp] error ", res.error);
            dispatch({
              type: ON_ERROR,
              payload: {
                message: res.message,
              },
            });
          } else {
            log("[downloadBusinessPlanHttp] success ", res.message);
          }
        },
        (error) => {
          log("[downloadBusinessPlanHttp] error ", error);
          dispatch({
            type: ON_ERROR,
            payload: {
              message:
                "Oops! Something went wrong in downloadBusinessPlanHttp [server error]",
            },
          });
        }
      );
    } else {
      dispatch({
        type: ON_ERROR,
        payload: {
          message: "ProProject Id is not valid",
        },
      });
      return false;
    }
  };
}

const submitVesselOpexForm = (projectId: any, vesselNo: any, payload: any, history: any) => {
  return (dispatch: any) => {
    return addVesselOpexHttp(projectId, vesselNo, payload)
      .then(
        (res: any) => {
          if (res.status === 202) {
            log("submitVesselOpexForm [SUCCESS] ", res);
            history.push(`/projects/${projectId}/funding`);
          }
          if (res.status === 510) {
            dispatch({
              type: ON_ERROR,
              payload: {
                message: res.statusText,
              },
            });
            return false;
          }
        },
        (error) => {
          log("submitVesselOpexForm [ERROR] ", error);
          dispatch({ type: ON_ERROR, payload: error });
        }
      );
  };
};

export const fetchVessels = (payload: any, createNew?: boolean) => {
  return (dispatch: any, getState: any, { config }: any) => {
    const vesselNo = sessionStorage.getItem("__vesselNo");
    if (!createNew && vesselNo) {
      return getVesselHttp()
        .then(
          (response) => {
            if (response.error) {
              return dispatch({
                type: ON_ERROR,
                payload: { message: response.error },
              });
            }
            const result: any = {
              ...response.vesselInfo,
              vesselType: response.vesselType,
              purposeInfo: {
                loanPurpouse: response.loanPurpouse,
                declaredValue: response.declaredValue,
              },
            };

            if (!!result.name) {
              result.imoNumber = payload;
            }
            // dispatch({type: ON_VESSELS_FETCH, payload: result});
            return result;
          },
          (error) => {
            dispatch({ type: ON_ERROR, payload: error });
          }
        )
        .catch((err: any) => {
          dispatch({ type: ON_EXCEPTION, payload: err });
        });
    } else {
      return (
        fetchVesselsHttp(payload)
          // http(`${config.basePath}${config.paths.vessels}?text=${payload}`)
          .then(
            (res) => {
              if (res.error) {
                return dispatch({
                  type: ON_ERROR,
                  payload: { message: res.error },
                });
              }
              const result: any = { ...res };

              if (!!result.name) {
                result.imoNumber = payload;
              }
              // dispatch({type: ON_VESSELS_FETCH, payload: result});
              return result;
            },
            (error) => {
              dispatch({ type: ON_ERROR, payload: error });
            }
          )
          .catch((err: any) => {
            dispatch({ type: ON_EXCEPTION, payload: err });
          })
      );
    }
  };
};

function getVessels(text: string) {
  return (dispatch: any) => {
    return getVesselsHttp(text);
  };
}

function getCompanyExperience() {
  return (dispatch: any) => {
    return getExperienceHttp().then(
      (res) => {
        try {
          if (res) {
            let payload: any = {
              hasShip: res.shipsOwnership || "Yes, Currently",
              hasTechnicalManagement:
                res.technicalManagement && res.technicalManagement.isOwning
                  ? "yes"
                  : "no",
              commercialManagement:
                res.commercialManagement && res.commercialManagement.name,
              technicalManagementBy:
                res.technicalManagement && res.technicalManagement.name,
              totalShoredEmployeeStrength: res.shoreBasedEmployeesNumber,
              totalEmployeeStrength: res.crewNumber,
            };
            if (res.shipsOwnership !== "No") {
              payload = {
                dryShips: res.shipNumbers.dry,
                isDryShips: res.shipNumbers.dry > 0,
                tankShips: res.shipNumbers.tank,
                isTankShips: res.shipNumbers.tank > 0,
                contShips: res.shipNumbers.cont,
                isContShips: res.shipNumbers.cont > 0,
                otherShips: res.shipNumbers.other,
                isOtherShips: res.shipNumbers.other > 0,
                ...payload,
              };
            }
            return payload;
          }
        } catch (err) {
          dispatch({
            type: ON_ERROR,
            payload: { message: "server error [Error Exception]" },
          });
          return { error: "server error" };
        }
        return {};
      },
      (error) => {
        log("getExperienceHttp [error] ", error);
        dispatch({ type: ON_ERROR, payload: error });
      }
    );
  };
}

function ShowError(msg: string) {
  return (dispatch: any) => {
    return dispatch({ type: ON_ERROR, payload: { message: msg } });
  };
}

function fakeHttp(payload: any) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, 500);
  });
}

function http(api: any) {
  return fetch(api).then((res) => res.json());
}

function setContext(payload: any) {
  return (dispatch: any) => {
    dispatch({
      type: SET_CONTEXT,
      payload,
    });
  };
}

export const prevBookedMeeting = (payload: any, projectId: any) => {
  return (dispatch: any) => {
    return getBookedMeeting(payload, projectId).then(
      (res: any) => {
        // console.log("project list res", res);
        if (res && res.items && res.total) {
          dispatch({ type: BOOKIED_MEETING, payload: res });
          return true;
        }
      },
      (error) => {
        // console.log("project list error", error);
        dispatch({ type: ON_ERROR, payload: error });
      }
    );
  };
};

export const existingDoc = (payload: any, projectId: any) => {
  return (dispatch: any) => {
    return getExistingDoc(payload, projectId).then(
      (res: any) => {
        // console.log("project list res", res);
        if (res && res.items && res.total) {
          dispatch({ type: EXISTING_DOC, payload: res });
          return true;
        }
      },
      (error) => {
        // console.log("project list error", error);
        dispatch({ type: ON_ERROR, payload: error });
      }
    );
  };
};

export const getDocTypeList = (projectId: any) => {
  return (dispatch: any) => {
    return geLookuptDocTypeList(projectId).then(
      (res: any) => {
        // console.log("project list res", res);
        if (res) {
          dispatch({ type: LOOKUP_DOC_TYPE_LIST, payload: res });
          return res;
        }
      },
      (error) => {
        // console.log("project list error", error);
        dispatch({ type: ON_ERROR, payload: error });
      }
    );
  };
};

export const getCountryList = (text: string) => {
  return (dispatch: any) => {
    return getCountryFlagsHttp(text).then(
      (res: any) => {
        // console.log("project list res", res);
        if (res) {
          dispatch({ type: LOOKUP_DOC_TYPE_LIST, payload: res });
          return res;
        }
      },
      (error) => {
        // console.log("project list error", error);
        dispatch({ type: ON_ERROR, payload: error });
      }
    );
  };
};

export const onDocumentSubmit = async (payload: any, projectId: any) => {
  let values = { ...payload };
  delete values.document;
  return await submitNewDocument(values, projectId).then(
    async (res: any) => {
      if (!res.Message) {
        return await uploadDocumentFile(
          payload.document,
          projectId,
          res.id
        ).then(
          (res: any) => {
            console.log("err1", res);
            if (res.status) {
              return { status: true };
            }
          },
          (err: any) => {
            console.log("err", err);
            return { error: err };
          }
        );
      } else return { error: res.Message };
    },
    (error) => {
      return { error };
    }
  );
};

export const onEditDocument = async (
  payload: any,
  projectId: any,
  docId: any
) => {
  let values = { ...payload };
  if (strictValidString(payload.document)) {
    values.fileName = payload.document;
  }
  delete values.document;
  return await editDocument(values, projectId, docId).then(
    async (res: any) => {
      if (res.status) {
        if (strictValidObject(payload.document)) {
          return await uploadDocumentFile(
            payload.document,
            projectId,
            docId
          ).then(
            (res: any) => {
              if (res.status) {
                return { status: true };
              }
            },
            (err: any) => {
              console.log("err", err);
              return { error: "getting something wrong!!" };
            }
          );
        } else return true;
      } else return { error: "getting something wrong!!" };
    },
    (error) => {
      return { error };
    }
  );
};

export default {
  addCompanyInfo,
  updateCompanyInfo,
  addCompanyExperience,
  addDocumentToProject,
  addVessel,
  addNewCompanyProject,
  addVesselEmployment,
  addDuplicateVessel,
  clearImoSelection,
  deleteVesselEmployment,
  deleteDocumentFromProject,
  deleteVessel,
  doSignup,
  downloadBusinessPlan,
  editVessel,
  getCompanyExperience,
  getVessels,
  updateVesselEmployment,
  onNavigate,
  updatePage,
  fetchVessels,
  submitVesselForm,
  selectProfile,
  selectVesselType,
  submitVesselOpexForm,
  setContext,
  ShowError,
  prevBookedMeeting,
  existingDoc,
  getDocTypeList,
  getCountryList,
  getUserData,
  // onDocumentSubmit,
  projectProgress,
};
