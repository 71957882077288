import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { AqFooter } from "../../components/footer/aq-footer";
import { AqForm, AqInput } from "../../components/seeds";
import AqBadge from "../../components/seeds/aq-badge.component";
import AqDateComponent from "../../components/seeds/aq-date.component";
import AqFormComponent from "../../components/seeds/aq-form.component";
import AqRadioComponent from "../../components/seeds/aq-radio.component";
import AqVesselCardComponents from "../../components/vessel-card/aq-vessel-card.components";
import actionDispatcher from "../../controller/actions";
import { getVesselsHttp } from "../../controller/https";
import { VesselTypes } from "../../controller/models";
import { VESSEL_TYPES } from "../../helpers/constants";
import { getSelectedVessel } from "../../helpers/utils";
import { FontSize14, Primary, White } from "../../Styles";
import { currancyToNumber } from "../../controller/utils/commonUtils";

// fspl
import AqSelectComponent from "../../components/seeds/aq-select.component";
import { getCountryFlagList } from "../../controller/actions/countryflag";
// fspl

const AqVesselWrapper = styled.div`
  .vessels-container {
    display: flex;
    margin-top: 24px;
    justify-content: center;
    flex-wrap: wrap;

    .vessel-card {
      margin-bottom: 24px;
    }
  }

  .imo-form-content {
    position: relative;

    .suggestion-box {
      padding: 0;
      margin: 0;
      position: absolute;
      background: white;
      border: 1px solid #dedede;
      top: 86px;
      min-width: 200px;
      text-align: left;
      border-radius: 4px;
      z-index: 1;
      max-height: 150px;
      overflow-y: auto;
      box-shadow: 1px 6px 9px 0 #dcdcdc;

      .suggestion-item {
        list-style-type: none;
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
          background-color: lightgray;
        }
      }
      @media screen and (max-width: 677px) {
        top: auto;
        bottom: 55px;
        width: 100%;
      }
    }
  }

  .aq-vessel-form-wrapper {
    width: 60%;
    margin: 24px auto 44px;
    position: relative;

    .aq-tick {
      position: absolute;
      width: 25px;
      height: 24px;
      right: -34px;
      top: 39px;
      background-image: url(/icons/tick.svg);
      background-repeat: no-repeat;
    }
  }

  .aq-vessel-footer {
    font-size: 12px;
    line-height: 12px;
    margin-top: -16px;
    text-align: left;
  }

  .aq-vessel-form-container {
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: 0 auto;

    .aq-vessel-form {
      width: 412px;
      border: 2px solid ${Primary};
      padding: 16px;
      box-sizing: border-box;
      border-radius: 22px;
      position: relative;

      .aq-corner-tic {
        position: absolute;
        width: 51px;
        height: 58px;
        right: -3px;
        top: -3px;
        background: ${White};
        border-top-right-radius: 22px;

        span {
          width: 11px;
          height: 11px;
          background-color: ${Primary};
          display: block;
          border-radius: 50%;
          top: -3px;
          position: relative;
          left: 4px;
        }
      }
    }

    .aq-vessel-purpose-form {
      padding: 16px;

      label > span {
        font-size: ${FontSize14};
      }
    }
  }

  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }

  @media screen and (max-width: 677px) {
    padding: 10px;
    padding-bottom: 78px;

    .mobile {
      display: block;
    }

    .desktop {
      display: none;
    }

    .vessels-container {
      display: block;
      margin-top: 0;
    }

    .aq-vessel-form-wrapper {
      width: 100%;
    }

    .span-6 {
      padding: 0;
    }

    .aq-vessel-form-container {
      display: block;

      .aq-vessel-form {
        border: none;
        padding: 0;
        width: 100%;

        .span-6 {
          width: 100%;
        }

        .aq-corner-tic {
          display: none;
        }
      }

      .aq-vessel-purpose-form {
        padding: 0;
      }
    }
  }
`;

class AqVesselSearchComponent extends Component<any, any> {
  debounceRef: any;
  imoFormSubmit: any;
  vesselFormSubmit: any;
  purposeFormSubmit: any;
  formsData: any = {};
  vesselForm: any = {
    initialValues: {},
    fields: {
      vesselName: { name: "vesselName", label: "Vessel Name", required: true },
      buildYard: {
        name: "buildYard",
        label: "Built Yard",
        span: 6,
        required: true,
      },
      buildDate: {
        name: "buildDate",
        label: "Built Date",
        type: "date",
        span: 6,
        noPadding: true,
        required: true,
      },
      ldt: {
        name: "ldt",
        label: "LDT",
        // meta: {icon: 'currency'},
        min: 100,
        max: 50000000,
        step: 1000000,
        span: 6,
        required: true,
        type: "number",
      },
      dwt: {
        name: "dwt",
        label: "DWT",
        // meta: {icon: 'currency'},
        min: 100,
        max: 50000000,
        step: 1000000,
        span: 6,
        noPadding: true,
        required: true,
        type: "number",
      },
      flag: {
        label: "Flag Country",
        name: "flag",
        required: true,
        span: 12,
        type: "select",
      },

      features: { name: "features", label: "Features" },
    },
  };
  purposeForm: any = {
    initialValues: { loanPurpouse: "Acquisition" },
    fields: {
      loanPurpouse: {
        required: true,
        active: true,
        name: "loanPurpouse",
        label: "Please specify the purpose of this loan",
        type: "radio",
      },
      declaredValue: {
        name: "declaredValue",
        step: 100000,
        min: 0,
        required: true,
        label: "What is your acquisition cost for the vessel ?",
        meta: { icon: "currency" },
      },
    },
  };
  foundVessel = false;
  selectedVesselCard: any;
  disableContinue = true;
  projectId: any;
  vesselNo: any;

  constructor(props: any) {
    super(props);
    this.projectId = this.props.match.params.id;
    this.vesselNo = this.props.match.params.vesselNo;
    this.state = {
      vesselFormValue: undefined,
      suggestions: [],
      dataList: [],
      vessels: [
        {
          label: "bulk carrier",
          type: VesselTypes.bulk,
          id: VESSEL_TYPES.CARRIERS,
        },
        { label: "tanker", type: VesselTypes.tanker, id: VESSEL_TYPES.TANKERS },
        {
          label: "chemical tanker",
          type: VesselTypes.chemical,
          id: VESSEL_TYPES.CHEMICALS,
        },
        {
          label: "container vessel",
          type: VesselTypes.container,
          id: VESSEL_TYPES.CONTAINERS,
        },
        { label: "other", type: VesselTypes.other, id: VESSEL_TYPES.OTHERS },
      ],
      initialValues: { imoNumber: "" },
      fields: {
        imoNumber: {
          name: "imoNumber",
          label: "Search by IMO number or vessel name",
          placeholder: "IMO number/ Vessel Name",
        },
      },
      value: "",
    };
  }

  countryListOptions = () => {
    return this.state.dataList.map((item: any) => ({
      label: item,
      value: item,
    }));
  };

  FetchCountryFlag = async (val: any) => {
    const res = await this.props.getCountryList();
    if (res) {
      this.setState({ dataList: res });
    }
  };

  componentDidMount(): void {
    this.FetchCountryFlag("");

    this.props.updatePage({
      name: "vessels",
      title: "Welcome: MSC - Global Shipping Company",
      showActions: true,
      step: 2,
      subStep: 1,
    });
    const { vessel, imoNumber } = this.props;
    this.setState({ initialValues: { imoNumber } });

    const { vessels } = this.state;
    if (vessel && vessel.selected) {
      vessels.forEach((item: any) => {
        item.selected = vessel.selected.context === item.id;
      });
      this.setState({ vessels });
    }
    const savedImo = this.props.imoNumber;
    const vesselNo = this.props.vesselNo;
    if (savedImo || vesselNo) {
      this.getVesselFromImo(savedImo);
    }

    this.props.projectProgress(50);
  }

  handleCardChange = (id: any) => {
    const { vessels } = this.state;
    vessels.forEach((item: any) => {
      item.selected = item.id === id;
    });
    this.setState({ vessels }, () => {
      this.props.selectVesselType({ context: id });
    });
  };

  /**
   * Render vessel cards
   */
  getVesselsCard = () => {
    const { vessels } = this.state;
    const { vessel } = this.props;
    return vessels.map((item: any, i: number) => {
      item.selected = vessel.selected && vessel.selected.context === item.id;
      if (item.selected) {
        this.selectedVesselCard = item;
      }
      return (
        <>
          <div key={`v-card--badge${i}`} className={"vessel-card mobile"}>
            <AqBadge
              label={item.label}
              selected={item.selected}
              name={item.id}
              onChange={this.handleCardChange}
              icon={item.type}
            />
          </div>
          <div key={`v-card-${i}`} className={"vessel-card desktop"}>
            <AqVesselCardComponents
              id={item.id}
              label={item.label}
              inverted={i % 2 !== 0}
              selected={item.selected}
              onCardSelected={this.handleCardChange}
              type={item.type}
            />
          </div>
        </>
      );
    });
  };

  onSubmit = () => {};

  handleInputChange = (ev: any) => {
    const newState: any = { value: ev };
    if (ev.value.length > 3) {
      this.debounce(ev.value, 300);
    } else {
      newState.suggestions = [];
    }
    this.setState(newState);
  };

  hideSuggestion = () => {
    setTimeout(() => {
      this.setState({ suggestions: [] });
    }, 1000);
  };

  debounce = (val: any, timeout: number) => {
    if (this.debounceRef) {
      clearTimeout(this.debounceRef);
    }
    this.debounceRef = setTimeout(() => {
      // this.props.fetchVessels(val);
      getVesselsHttp(val).then(
        (res: any) => {
          this.setState({ suggestions: res });
        },
        (error) => {
          this.setState({ suggestions: [] });
        }
      );
    }, timeout);
  };

  clearHandler = (onChange: any) => {
    this.props.clearImoSelection();
    this.setState({ value: "" });
    onChange("imoNumber", "");
  };

  renderer = (state: any) => {
    const { formState, onFocus, onBlur, onChange, onSubmit } = state;
    this.imoFormSubmit = onSubmit;
    const { fields, suggestions } = this.state;
    const field = fields.imoNumber;
    const initialValues = formState;
    if (!formState) {
      return null;
    }
    return (
      <div className={"imo-form-content"}>
        <AqInput
          key={"imo_input"}
          name={field.name}
          autoComplete={"off"}
          placeholder={field.placeholder}
          value={initialValues["imoNumber"] && initialValues["imoNumber"].value}
          error={
            initialValues["imoNumber"] && initialValues["imoNumber"]._errors
          }
          onFocus={onFocus}
          label={field.label}
          onBlur={this.hideSuggestion}
          onClear={() => this.clearHandler(onChange)}
          meta={{ clear: true }}
          onChange={onChange}
        />
        {this.foundVessel && <span className={"aq-tick"} />}
        {suggestions && this.renderVesselsSuggestion(suggestions, onChange)}
      </div>
    );
  };

  renderVesselsSuggestion(items: Array<any>, onChange: any) {
    if (!items || !items.length) {
      return null;
    }
    return (
      <ul className={"suggestion-box"}>
        {items.map((item: any, i: number) => {
          let imNo = item.imoNumber;
          return (
            <li
              className={"suggestion-item"}
              key={`${i}_vessel`}
              onClick={() => {
                this.handleVesselSuggestionClick(item);
                onChange("imoNumber", { value: imNo });
              }}
            >
              {item.imoNumber} - {item.vesselName}
            </li>
          );
        })}
      </ul>
    );
  }

  handleVesselSuggestionClick = (suggestion: any) => {
    this.setState({ value: suggestion.vesselName });
    this.getVesselFromImo(suggestion.imoNumber, true);
  };

  getVesselFromImo(imoNumber: string, createNew?: boolean) {
    this.setState(
      {
        imoNumber,
        vesselFormValue: undefined,
        foundVessel: false,
        suggestions: [],
      },
      () => {
        this.props.fetchVessels(imoNumber, createNew).then(
          (res: any) => {
            this.setState({
              vesselFormValue: res,
              foundVessel: true,
              imoNumber,
              initialValues: { imoNumber },
            });
            // console.log("dads", res, this.state);
            if (res.vesselType) {
              this.handleCardChange(res.vesselType);
            }
          },
          (error: any) => {
            this.setState({
              vesselFormValue: undefined,
              imoNumber,
              initialValues: { imoNumber },
            });
          }
        );
      }
    );
  }

  onFormSubmit = (formData: any, id: string) => {
    this.formsData[id] = formData.formState;
  };

  /**
   * Submit all forms data
   */
  combineSubmit = () => {
    const { vesselFormValue } = this.state;
    const imoNumber = this.props.imoNumber || vesselFormValue?.imoNumber;
    const data: any = this.formsData;
    const payload: any = {
      selectedVesselCard: this.selectedVesselCard,
    };
    if (data.vesselForm) {
      payload.vesselForm = { imoNumber };
      Object.keys(data.vesselForm).forEach((key: any) => {
        if (data.vesselForm[key].value) {
          payload.vesselForm[key] = data.vesselForm[key].value;
        }
      });
    }
    if (data.purposeForm) {
      payload.purposeForm = {};
      Object.keys(data.purposeForm).forEach((key: any) => {
        if (data.purposeForm[key].value) {
          payload.purposeForm[key] = data.purposeForm[key].value;
        }
      });
      if (payload.purposeForm && payload.purposeForm.declaredValue) {
        payload.purposeForm.declaredValue = currancyToNumber(
          payload.purposeForm.declaredValue
        );
      }
    }

    let currtonumber = ["ldt", "dwt"];

    currtonumber.forEach((item) => {
      payload.vesselForm[item] = Number(
        currancyToNumber(data.vesselForm[item].value)
      );
    });

    this.props.submitVesselForm(this.projectId, this.vesselNo, payload, this.props.history);
  };

  /**
   * Handles the validations on fields
   * @param field
   * @param value
   * @param form
   */

  handleValidation = (field: any, value: any, form: any, other: any) => {
    if (field.regex && value) {
      if (!field.regex.test(value)) {
        return {
          error: "Invalid value",
        };
      }
    }
    if (/ldt|dwt/.test(field.name)) {
      if (Number(currancyToNumber(value)) < field.min) {
        return {
          error: `Value must be >${field.min}`,
        };
      } else if (Number(currancyToNumber(value)) > field.max) {
        return {
          error: `Value must be <${field.max}`,
        };
      }

      if (
        (field.name === "ldt" &&
          Number(currancyToNumber(value)) >=
            Number(currancyToNumber(form.dwt.value))) ||
        (field.name === "dwt" &&
          Number(currancyToNumber(value)) <=
            Number(currancyToNumber(form.ldt.value)))
      ) {
        return {
          error: "Invalid value: LDT < DWT",
        };
      }
    }

    if (field.name === "dwt") {
      delete form.ldt._errors;
    }
    if (field.name === "ldt") {
      delete form.dwt._errors;
    }

    if (field.required && !value) {
      return {
        error: field.error || "field is required",
      };
    }
    return undefined;
  };

  getVesselForm = (key: string) => {
    const vesselForm = this.vesselForm;
    const { vesselFormValue } = this.state;
    if (!vesselFormValue) {
      return null;
    }
    return (
      <AqFormComponent
        id={"vesselForm"}
        key={key}
        fields={vesselForm.fields}
        initialValues={vesselFormValue}
        onSubmit={this.onFormSubmit}
        validator={this.handleValidation}
        renderer={this.vesselFormFieldRenderer}
      />
    );
  };

  /**
   * Populates and return the fields template
   * @param state
   */
  vesselFormFieldRenderer = (state: any) => {
    const { onFocus, onBlur, onChange, onSubmit, formState } = state;
    this.vesselFormSubmit = onSubmit;
    if (!formState) {
      return null;
    }
    const vesselForm = this.vesselForm;
    const initialValues = formState;
    const { fields } = vesselForm;
    return (
      <div className={"field-wrapper field-container grid"}>
        {Object.keys(fields).map((key: any, index: number) => {
          let span = fields[key].span ? `span-${fields[key].span}` : "span-12";
          if (fields[key].noPadding) {
            span += " no-padding";
          }
          if (fields[key].type === "date") {
            return (
              <div key={`${index}_input`} className={span}>
                <AqDateComponent
                  name={fields[key].name || key}
                  label={fields[key].label}
                  startDate={initialValues[key] && initialValues[key].value}
                  placeholder={fields[key].placeholder}
                  error={initialValues[key] && initialValues[key]._errors}
                  onBlur={onBlur}
                  onChange={onChange}
                />
              </div>
            );
          }

          if (fields[key].type === "select") {
            return (
              <div key={`${index}_input`} className={span}>
                <AqSelectComponent
                  name={fields[key].name || key}
                  label={fields[key].label}
                  defaultValue={fields[key].placeholder}
                  value={initialValues[key] && initialValues[key].value}
                  error={initialValues[key] && initialValues[key]._errors}
                  options={this.countryListOptions()}
                  onChange={onChange}
                />
              </div>
            );
          }
          // console.log("fields", fields);
          if (fields[key].name === "ldt" || fields[key].name === "dwt") {
            return (
              <div key={`${index}_input`} className={span}>
                <AqInput
                  name={fields[key].name || key}
                  label={fields[key].label}
                  min={fields[key].min}
                  max={fields[key].max}
                  step={fields[key].step}
                  placeholder={fields[key].placeholder}
                  meta={fields[key].meta}
                  value={initialValues[key] && initialValues[key].value}
                  error={initialValues[key] && initialValues[key]._errors}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  type={fields[key].type}
                  onChange={onChange}
                  currancy
                />
              </div>
            );
          }

          return (
            <div key={`${index}_input`} className={span}>
              <AqInput
                name={fields[key].name || key}
                label={fields[key].label}
                min={fields[key].min}
                max={fields[key].max}
                step={fields[key].step}
                placeholder={fields[key].placeholder}
                meta={fields[key].meta}
                value={initialValues[key] && initialValues[key].value}
                error={initialValues[key] && initialValues[key]._errors}
                onFocus={onFocus}
                onBlur={onBlur}
                type={fields[key].type}
                onChange={onChange}
              />
            </div>
          );
        })}
      </div>
    );
  };

  purposeFieldRenderer = (state: any) => {
    const { onFocus, onBlur, onChange, formState, onSubmit } = state;
    this.purposeFormSubmit = onSubmit;
    const { fields } = this.purposeForm;
    const initialValues = formState;
    if (!formState) {
      return null;
    }
    return (
      <div className={"field-wrapper field-container grid"}>
        {Object.keys(fields).map((key: string, i: number) => {
          const field = fields[key];
          if (field.type === "radio") {
            return (
              <div key={`${i}_input`} className={"aq-txt-l"}>
                <label>{field.label}</label>
                <div className={"flex"}>
                  <div className={"flexible"}>
                    <AqRadioComponent
                      name={field.name}
                      value={"Acquisition"}
                      checked={
                        formState && formState[key].value === "Acquisition"
                      }
                      onChange={onChange}
                      label={"New Purchase"}
                    />
                  </div>
                  <div className={"flexible"}>
                    <AqRadioComponent
                      name={field.name}
                      value={"Refinance"}
                      checked={
                        formState && formState[key].value === "Refinance"
                      }
                      onChange={onChange}
                      label={"Re-Finance"}
                    />
                  </div>
                </div>
              </div>
            );
          }
          return (
            <div key={`${i}_input`}>
              <AqInput
                value={initialValues && initialValues[key].value}
                name={field.name}
                label={field.label}
                meta={field.meta}
                min={field.min}
                step={field.step}
                onBlur={onBlur}
                error={initialValues[key] && initialValues[key]._errors}
                onFocus={onFocus}
                onChange={onChange}
                type={"number"}
                currancy
              />
            </div>
          );
        })}
      </div>
    );
  };

  handleFieldChange = (field: any) => {
    if (field && field.fieldName === "loanPurpouse" && field.value) {
      this.ensureDefaultValue(field);
      this.setState({ purposeForm: this.ensureDefaultValue(field) });
    }
  };

  ensureDefaultValue = (field: any) => {
    if (field && field.value) {
      const value = field.value.value;
      const purposeForm = this.purposeForm;
      if (value === "Acquisition") {
        purposeForm.fields.declaredValue.label =
          "What is your acquisition cost for the vessel ?";
      } else {
        purposeForm.fields.declaredValue.label =
          "What is the fair market value for the vessel ?";
      }
      return purposeForm;
    }
  };

  getPurposeForm = () => {
    const purposeForm = this.purposeForm;
    const defaultValue = purposeForm.initialValues || {
      loanPurpouse: "Acquisition",
    };
    return (
      <AqFormComponent
        id={"purposeForm"}
        fields={purposeForm.fields}
        initialValues={defaultValue}
        onSubmit={this.onFormSubmit}
        onFieldChange={this.handleFieldChange}
        renderer={this.purposeFieldRenderer}
      />
    );
  };

  handleFooterAction = (ev: any) => {
    if (ev === "back") {
      this.props.history.push(`/projects/${this.projectId}/funding`);
    } else {
      this.vesselFormSubmit();
      this.purposeFormSubmit();
      setTimeout(() => {
        if (this.formsData.vesselForm && this.formsData.purposeForm) {
          this.combineSubmit();
        }
      }, 10);
    }
  };

  getFormSection = () => {
    // const {vesselInfo = {}, purposeInfo} = this.props;
    const { vesselFormValue } = this.state;
    if (!vesselFormValue) {
      this.disableContinue = true;
      return null;
    }
    this.vesselForm.initialValues = vesselFormValue;
    this.purposeForm.initialValues = vesselFormValue.purposeInfo;

    // this.foundVessel = vesselFormValue.foundVessel;
    if (vesselFormValue || this.state.showBlankForm) {
      this.disableContinue = false;
      return (
        <div className={"aq-vessel-form-container"}>
          <div className={"aq-vessel-form"}>
            <div className={"aq-corner-tic"}>
              <span></span>
            </div>
            {this.getVesselForm(this.getVesselFormId())}
          </div>
          <div className={"aq-vessel-purpose-form"}>
            {this.getPurposeForm()}
          </div>
        </div>
      );
    }
    this.disableContinue = true;
    return null;
  };

  getVesselFormId = (): string => {
    return this.foundVessel ? "vesselForm" : "blank_vesselForm";
  };

  enableVesselForm = () => {
    this.setState({ showBlankForm: true, vesselFormValue: {} });
  };

  render(): React.ReactNode {
    const formElm = this.getFormSection();
    const { fields, vesselFormValue } = this.state;
    const { vesselInfo = {}, isVesselTypeSelected, vesselNo } = this.props;

    if (vesselNo && !vesselFormValue) {
      return null;
    }
    const imoNumber = vesselFormValue?.imoNumber;
    return (
      <AqVesselWrapper>
        <div className={"vessels-container"}>{this.getVesselsCard()}</div>
        {isVesselTypeSelected && (
          <div className={"aq-vessel-form-wrapper"}>
            <AqForm
              id={"imo-form"}
              fields={fields}
              onFieldChange={this.handleInputChange}
              initialValues={{ imoNumber }}
              onSubmit={this.onSubmit}
              renderer={this.renderer}
            ></AqForm>
            <div className={"aq-vessel-footer"}>
              <span>
                Type first 4 digit of IMO number or first 4 characters of vessel
                name
              </span>
              {!!vesselInfo && !this.foundVessel && (
                <span className={"right"}>
                  <a onClick={this.enableVesselForm}>Not able to find</a>
                </span>
              )}
            </div>
          </div>
        )}
        {formElm}
        <AqFooter
          progress={50}
          disableContinue={this.disableContinue}
          onButtonClick={this.handleFooterAction}
        />
      </AqVesselWrapper>
    );
  }
}

const mapStateToProps = (state: any) => {
  const selectedVessel = getSelectedVessel(state);
  const isVesselTypeSelected =
    state.vessel.selected && state.vessel.selected.context;
  return {
    ...selectedVessel,
    vesselNo: sessionStorage.getItem("__vesselNo"),
    vessel: state.vessel,
    isVesselTypeSelected,
  };
};
const mapDispatchToProps = { ...actionDispatcher, getCountryFlagList };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AqVesselSearchComponent);
