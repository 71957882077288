import React, { Component } from "react";
import styled from "styled-components";
import {
  NeutralLight,
  SteelGrey,
  Line,
  Neutral,
  FontSize14,
  White,
} from "../../Styles";
import AqButtonComponent from "../seeds/aq-button.component";
import AqStepComponent from "../step/aq-step.component";

const AqHeaderWrapper = styled.div`
  .aq-company-header {
    position: relative;

    .aq-company-header-title {
      background-color: ${NeutralLight};
      padding: 6px;
    }

    .aq-header-title2 {
      padding: 9px 0;
      background-color: ${SteelGrey};
      display: flex;
      justify-content: center;
    }
  }

  .header-logo {
    position: absolute;
    left: 0;
    top: 30px;
    max-width: 15em;
  }
  .progress-bar {
    height: 2px;
    background: ${Line};
    position: relative;
    display: none;

    .progress-value {
      height: 4px;
      background: ${Neutral};
      transition: width 0.2s ease-in-out;
      top: -1px;
      position: relative;

      .progress-label {
        color: ${Neutral};
        font-size: ${FontSize14};
        position: absolute;
        right: 0;
        top: -18px;
      }
    }
  }

  @media screen and (max-width: 677px) {
    .aq-company-header {
      .header-logo {
        display: none;
      }
      .aq-company-header-title {
        background-color: #dfe6f4;
        text-align: left;
      }
      .aq-header-title2 {
        justify-content: start;
        padding: 0 9px;
        padding-top: 10px;
        background-color: ${White};
        button {
          background-color: #dfe6f4;
          border-radius: 34px;
        }
      }
    }
    .progress-bar {
      display: block;
    }
  }
`;

interface IAqHeaderProps {
  label: any;
  actions?: Array<any>;
  steps: Array<any>;
  onStepChange: any;
  stepWidth?: number;
  progress?: any;
}

export default class AqHeaderComponent extends Component<IAqHeaderProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  /**
   * Handles action click
   * @param action
   */
  onActionClick = (action: any) => {
    this.props.onStepChange(action);
  };

  /**
   * Returns label for action
   * @param action
   * @param i
   */
  getLabel = (action: any, i: number) => {
    return action.label ? action.label : `Vessel ${i + 1}`;
  };

  /**
   * Return react action element
   */
  getAction = () => {
    const { actions } = this.props;
    return (
      actions &&
      actions.map((action: any, i: number) => (
        <AqButtonComponent
          key={`header-action-${i}`}
          label={this.getLabel(action, i)}
          style={{ marginRight: 10, padding: "0 20px" }}
          className={"btn-default small"}
          onClick={() => this.onActionClick(action)}
        />
      ))
    );
  };

  render(): React.ReactNode {
    const {
      steps,
      onStepChange,
      stepWidth = 500,
      label,
      progress = 0,
    } = this.props;
    return (
      <AqHeaderWrapper>
        <div className={"aq-company-header"}>
          <img className={"header-logo"} src={"/icons/logo.png"} alt="logo" />
          <AqStepComponent
            steps={steps}
            onStepChange={onStepChange}
            width={stepWidth}
          />
          {progress && (
            <div className={"progress-bar"}>
              <div
                className={"progress-value"}
                style={{ width: `${progress}%` }}
              >
                <span className={"progress-label"}>{progress}%</span>
              </div>
            </div>
          )}
          <div className={"aq-company-header-title"}>
            <label>{label}</label>
          </div>
        </div>
      </AqHeaderWrapper>
    );
  }
}
