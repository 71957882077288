import styled, { css } from 'styled-components'
import { Dark, FontFamily, FontSize14, H1Size, TxtLineHeight } from '../../../Styles';


const form = css`
  width: 100%;
  font-family: ${FontFamily};

  h1 {
    font-size: ${H1Size};
    font-style: normal;
    font-weight: normal;
    padding-bottom: 24px;
  }

  form {
    font-size: ${FontSize14};
    line-height: ${TxtLineHeight};
    color: ${Dark};
  }
`

export default styled.div`
  ${form}
`
