import { ON_SIGN_UP } from '../../constants/action-types';
import { IAction, IRootState } from '../models';
import { RootState } from '../states';
import { onSignup } from './reducer-helper/login-helpers';

const loginSessionKey = '__login__';
const getInitialState = () => {
  try {
    const persistState = sessionStorage.getItem(loginSessionKey);
    if (persistState) {
      return JSON.parse(persistState);
    } else {
      return RootState
    }
  } catch (e) {
    return RootState
  }
};

const initialState = getInitialState();

function login(state: IRootState = initialState, action: IAction) {
  let newState;
  switch (action.type) {
    case ON_SIGN_UP:
      newState = onSignup(action, state);
      break;
    default:
      newState = {...state, error: undefined};
  }
  sessionStorage.setItem(loginSessionKey, JSON.stringify(newState));
  return newState;
}

export default login;