import {
  ADD_COMPLANY_INFO,
  ADD_EXPERIENCE_INFO,
  GET_EXPERIENCE_INFO,
  IS_LOADING,
  ON_ERROR,
  ON_EXCEPTION,
  SELECT_PROFILE,
} from "../../constants/action-types";
import { IAction, IRootState } from "../models";
import { RootState } from "../states";
import {
  onAddComplanyInfo,
  onGetExperience,
} from "./reducer-helper/vessel-helpers";

const rootSessionKey = "__root__";
const getInitialState = () => {
  try {
    const persistState = sessionStorage.getItem(rootSessionKey);
    if (persistState) {
      return JSON.parse(persistState);
    } else {
      return RootState;
    }
  } catch (e) {
    return RootState;
  }
};

const initialState = getInitialState();

function root(state: IRootState = initialState, action: IAction) {
  let newState;
  switch (action.type) {
    case GET_EXPERIENCE_INFO:
      newState = onGetExperience(action, state);
      break;
    case ADD_COMPLANY_INFO:
      newState = onAddComplanyInfo(action, state);
      break;
    case IS_LOADING:
      newState = { ...state, isLoading: action.payload };
      break;
    case ON_ERROR:
      newState = { ...state, error: action.payload };
      break;
    case ON_EXCEPTION:
      newState = { ...state, error: action.payload };
      break;
    case SELECT_PROFILE:
      newState = { ...state, context: action.payload };
      break;
    default:
      newState = { ...state, error: undefined };
  }
  sessionStorage.setItem(rootSessionKey, JSON.stringify(newState));
  return newState;
}

export default root;
