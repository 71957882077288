import React, { PureComponent } from 'react';

import { AqButton, AqForm, AqInput } from '../seeds/index';
import SignupStyles from './signup.styles';

class LandingSignupComponent extends PureComponent<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      initialValues: {},
      step: 'email',
      steps: ['email', 'otp', 'signup'],
      fields: {
        email: {
          name: 'email',
          required: true,
          type: 'email',
          regex: /^[^@ ]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9]+$/,
          error: '*This is required field',
          placeholder: 'Enter Email'
        }
      }
    }
  }

  /**
   * Handles the validations on fields
   * @param field
   * @param value
   */
  handleValidation = (field: any, value: any) => {
    if (field.regex && value) {
      if (!field.regex.test(value)) {
        return {
          error: 'Invalid value'
        }
      }
    }
    if (field.required && !value) {
      return {
        error: field.error || 'field is required'
      }
    }
    return undefined;
  }

  /**
   * handles form submit event
   * @param formValue
   */
  handleSubmit = (formValue: any) => {
    const {step, steps} = this.state;
    // put logic here for otp or signin route
    if (step === 'email') {
      // call backend to get email verified and then update step to signin
      sessionStorage.setItem('userEmail', formValue.formState.email.value);
      window.location.replace('/#/signin');
    } else if (step === 'otp') {
      this.setState({step: steps[2]})
    }
    console.log('handle submit called ', formValue);
  }

  /**
   * Populates and return the fields template
   * @param state
   */
  getFields = (state: any) => {
    const {formState, onFocus, onBlur, onChange} = state;
    const fields = this.state.fields;
    const initialValues = formState;
    return <div className={'field-wrapper'}>
      {Object.keys(fields).map((key: any, index: number) => {
        if (!fields[key].hidden) {
          return (
            <AqInput key={`${index}_input`}
                     name={fields[key].name || key}
                     placeholder={fields[key].placeholder}
                     meta={fields[key].meta}
                     value={initialValues[key] && initialValues[key].value}
                     error={initialValues[key] && initialValues[key]._errors}
                     onFocus={onFocus}
                     onBlur={onBlur}
                     type={fields[key].type}
                     onChange={onChange}/>)
        }
      })}
    </div>
  }

  /**
   * Custom renderer for Form component
   * @param state
   */
  renderer = (state: any = {}) => {
    const {formState, onSubmit} = state;
    if (!formState) {
      return null;
    }
    return <div>
      {this.getFields(state)}
      <p>We will send you verification code (OTP)</p>
      <br/>
      <AqButton label={'Continue'} onClick={onSubmit}/>
    </div>
  }

  /**
   * Returns the form template
   */
  getForm() {
    const {initialValues} = this.state;
    const fields = this.state.fields;
    return <AqForm
      title='Sign In /Sign Up'
      submitTxt={'Continue'}
      footerText={'We will send you verification code (OTP)'}
      onSubmit={this.handleSubmit}
      fields={fields}
      initialValues={initialValues}
      validator={this.handleValidation}
      renderer={this.renderer}
    />
  }

  /**
   * Default render fn
   */
  render() {
    return <SignupStyles>
      <div className={'signup-form'}>
        <div className={'panel'}>
          <div className={'bg-1'}/>
          <div className={'bg-2'}/>
          <div className={'bg-3'}/>
          <div className={'bg-cover'}>
            {this.getForm()}
            <div className={'footer'}>
              <p className={'footer-txt'}>
                <span className={'safe-shield'}></span>Secure Login
              </p>
            </div>
          </div>
        </div>
      </div>
    </SignupStyles>
  }
}

export default LandingSignupComponent;
