import React, { PureComponent } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  validObjectWithParameterKeys,
  strictValidString,
  fullAddress,
  strictValidArray,
  strictValidArrayWithLength,
  strictValidObjectWithKeys,
} from "../../controller/utils/commonUtils";
import Styles from "./styles/input";

const addressState = {
  coordinates: null,
  timezone: null,
  location: null,
  zipcode: null,
  city: null,
  state: null,
  county: null,
  country: null,
};

interface IInputProps {
  value: any;
  name: string;
  placeholder?: string;
  error?: any;
  label?: string;
  inputStyle?: any;
  // meta?: IInputMetaProps;
  onChange?: any;
  onFocus?: any;
  onBlur?: any;
  footer?: any;
  onClear?: any;
  className?: string;
  autoComplete?: string;
  disabled?: boolean;
  customValue: any;
  centerPointValue?: any;
}
class AqPlacement extends PureComponent<any> {
  constructor(props: IInputProps) {
    super(props);
    this.state = {
      placeAddress: { ...addressState },
      address: "",
      centerPoint: {},
      func1: this.getAddressInfo.bind(this),
    };
  }

  /**
   * Returns the class name based on the icon value
   */
  getClassNames() {
    const { error, meta = {} } = this.props;
    const { icon, unit } = meta;
    const clasess = {
      wrapper: "input-field",
      input: "",
    };
    if (error) {
      clasess.input += " error";
      clasess.wrapper += " has-error";
    }
    if (icon) {
      clasess.input += " has-icon";
      if (icon === "currency" && !unit) {
        clasess.input += " txt-right";
      }
    }
    if (unit) {
      clasess.input += " has-unitTxt";
    }
    return clasess;
  }

  /**
   * Derives the states form props values
   * @param props
   */
  static async getDerivedStateFromProps(props: IInputProps, state: any) {
    if (
      strictValidString(props.value) &&
      !strictValidObjectWithKeys(state.centerPoint)
    ) {
      // console.log("props.value", props);
      state.func1(props.value);
      return {};
    }
  }

  filterSuggestion = (option = {}) => {
    const { filtersOptions = [] } = this.props;
    if (!strictValidArrayWithLength(filtersOptions)) return true;
    const { types } = { types: [], ...option };
    if (!strictValidArrayWithLength(types)) return false;

    const isValidLocation = types.some((val: any) =>
      filtersOptions.some((res: any) => res === val)
    );
    return isValidLocation;
  };

  getAddressValue = (
    addressResult: any,
    keyword: any,
    toSearch = "long_name"
  ) => {
    const object = validObjectWithParameterKeys(addressResult, [
      "address_components",
    ])
      ? addressResult.address_components.find((v: any) =>
          v.types.includes(keyword)
        )
      : {};
    if (validObjectWithParameterKeys(object, [toSearch])) {
      return object[toSearch];
    }
    return "";
  };

  getAddressInfo = async (address: any) => {
    // console.log("handel select", address);
    try {
      const addressResults = await geocodeByAddress(address);
      if (strictValidArrayWithLength(addressResults)) {
        const addressResult = addressResults[0];
        const zipcode = this.getAddressValue(addressResult, "postal_code");
        const streetNumber = this.getAddressValue(
          addressResult,
          "street_number"
        );
        const route = this.getAddressValue(addressResult, "route");
        const premise = this.getAddressValue(addressResult, "premise");
        const city =
          this.getAddressValue(addressResult, "postal_town") ||
          this.getAddressValue(addressResult, "locality") ||
          this.getAddressValue(addressResult, "administrative_area_level_3") ||
          this.getAddressValue(addressResult, "administrative_area_level_4") ||
          this.getAddressValue(addressResult, "sublocality");
        const _state = this.getAddressValue(
          addressResult,
          "administrative_area_level_1",
          "long_name" //"short_name"
        );
        const country = this.getAddressValue(
          addressResult,
          "country",
          "long_name" // "short_name"
        );
        const latLng = await getLatLng(addressResult);

        const location =
          (streetNumber || route) && streetNumber
            ? `${streetNumber} ${route}`
            : route || premise;

        let newAddress = {
          coordinates: latLng,
          location: location,
          zipcode,
          city,
          state: _state,
          country,
        };
        if (validObjectWithParameterKeys(latLng, ["lng", "lat"])) {
          this.props.onChange(this.props.name, address);
          this.props.centerPointValue(latLng);
        }
        this.setState({
          address: addressResult?.formatted_address || address,
          placeAddress: newAddress,
          centerPoint: latLng,
        });
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  handleChange = (plcAddress: any) => {
    // console.log("handel change", address);
    this.setState({
      address: strictValidString(plcAddress) ? plcAddress : "",
      placeAddress: addressState,
    });
    if (!strictValidString(plcAddress)) {
      this.props.onChange(this.props.name, "");
    }
  };

  handleSelect = async (plc: any, placeId: any) => {
    if (placeId) {
      this.getAddressInfo(plc);
    }
  };

  onBlur = (plc: any) => {
    if (!!!plc) {
      this.setState({ address: "" });
      this.props.onChange(this.props.name, "");
    }
  };

  /*Main Render*/
  render() {
    const state: any = this.state;
    const {
      error,
      placeholder = "Search Places ...",
      label,
      inputStyle,
      name,
      footer,
      className,
      disabled,
    } = this.props;
    const clasess = this.getClassNames();
    return (
      <PlacesAutocomplete
        value={state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Styles className={className}>
              {label && <label>{label}</label>}
              <div className={clasess.wrapper}>
                <input
                  {...getInputProps({
                    placeholder: placeholder,
                    className: "location-search-input",
                  })}
                  onBlur={this.onBlur}
                  id={name}
                  className={clasess.input}
                  style={inputStyle}
                  autoComplete={"false"}
                  disabled={disabled}
                />
                <div
                  className={`autocomplete-dropdown-container ${
                    suggestions.length > 0 && state.address.length > 0
                      ? "showinglist"
                      : ""
                  }`}
                >
                  <div className="dropdown">
                    {loading && <div>Loading...</div>}
                    {suggestions
                      .filter(this.filterSuggestion)
                      .map((suggestion, index) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };

                        const placeAddressInfoList = suggestion.description
                          .split(", ")
                          .reverse()
                          .map(
                            (v, k) =>
                              (k === 1 && v.replace(/\d+/g, "").trim()) || v
                          );
                        if (!strictValidArray(placeAddressInfoList)) {
                          return <div />;
                        }
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                            key={index}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                  </div>
                </div>

                {error && <div className={"error-txt"}>{error.error}</div>}
                {footer && <div className={"footer-txt"}>{footer}</div>}
              </div>
            </Styles>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}
export default AqPlacement;
