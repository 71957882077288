import React, { PureComponent } from 'react';

import styled from 'styled-components';
import { Line, NegativeChange, Neutral, Primary } from '../../Styles';

const CheckboxContainer = styled.div`
  position: relative;
  margin-right: 16px;
  
  label {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .error-txt {
    color: ${NegativeChange};
    position: absolute;
    top: 32px;
  }
  .rdo {
    width: 24px;
    height: 24px;
    border: 1px solid ${Line};
    appearance: none;
    border-radius: 50%;
    margin: 5px 8px 5px 2px;
    cursor: pointer;
    
    &.has-error {
      border-color: ${NegativeChange};
    }
    
    &.checked {
      background-image: url(${process.env.PUBLIC_URL + '/icons/radio.png'});
      background-repeat: no-repeat;
      background-position: 6px;
      border-color: ${Primary};
      background-size: 11px;
    }
    &:focus {
      outline: 1px solid ${Neutral};
    }
  }
`

const RadioInput = (props: any) => {
  return (
    <input type={'radio'} {...props} className={`rdo ${props.className}`}/>
  )
}

interface ICheckboxProps {
  name: string;
  label: any;
  value?: any;
  error?: any;
  checked?: boolean;
  onChange?: any;
}

export default class AqRadioComponent extends PureComponent<ICheckboxProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      checked: props.checked
    }
  }

  handleCheckboxChange = (event: any) => {
    console.log('onchange called >>> ', this.props.name, event.target.checked);
    this.setState({checked: event.target.checked});
    this.props.onChange && this.props.onChange(this.props.name, {value: this.props.value, checked: event.target.checked, field: this.props});
  }

  getClass = (): string => {
    let cls = '';
    if (this.props.checked) {
      cls = 'checked';
    }
    if (this.props.error) {
      cls += ' has-error';
    }
    return cls;
  }

  render() {
    const {label, error, name, checked, value} = this.props;
    const clsName = this.getClass();
    return (
      <CheckboxContainer>
        <label>
          <RadioInput
            name={name}
            className={clsName}
            checked={checked}
            value={value}
            onChange={this.handleCheckboxChange}
          />
          <span>{label}</span>
        </label>
        {error && <div className={'error-txt'}>{error.error}</div>}
      </CheckboxContainer>
    )
  }
}