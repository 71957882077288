import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import styled from "styled-components";
import AqHeaderComponent from "../../components/header/aq-header.component";
import dispatchers from "../../controller/actions";
import { DarkBlue, White } from "../../Styles";
import AqCompanyComponent from "../pages/aq-company.component";
import AqProfileComponent from "../pages/aq-profile.component";
import AqVesselSearchComponent from "../pages/aq-vessel-search.component";
import AqCompanyExperienceComponent from "../pages/company-experience/aq-company-experience.component";
import AqDownloadPlanComponent from "../pages/download/aq-download-plan.component";
import AqVesselFundindComponent from "../pages/vessel-funding/aq-vessel-fundind.component";
import AqVesselDetails from "../pages/vessel-opex/aq-vessel-details.component";
import {
  validObjectWithParameterKeys,
} from "../../controller/utils/commonUtils";

const AqPanelWrapper = styled.div`
  .aq-app-root {
    padding: 100px;
    background-color: ${DarkBlue};
    text-align: center;
    height: 100vh;
    box-sizing: border-box;
    overflow: auto;

    .aq-bg-1 {
      position: absolute;
      background-image: url(${process.env.PUBLIC_URL + "/icons/group_149.png"});
      background-repeat: no-repeat;
      width: 120px;
      height: 120px;
      left: 28%;
      top: 40px;
    }

    .aq-bg-2 {
      position: absolute;
      background-image: url(${process.env.PUBLIC_URL + "/icons/group_148.png"});
      background-position: -85px -120px;
      background-repeat: no-repeat;
      width: 135px;
      height: 136px;
      left: 30px;
      top: 330px;
    }

    .aq-bg-3 {
      position: absolute;
      background-image: url(${process.env.PUBLIC_URL + "/icons/group_149.png"});
      background-repeat: no-repeat;
      width: 120px;
      height: 120px;
      right: 34px;
      bottom: 30%;
    }

    .aq-bg-cover {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 90px 22px 55px;
      background-color: ${White};
      border-radius: 5px;
    }
    .aq-app-panel {
      background-color: ${White};
      position: absolute;
      left: 90px;
      right: 90px;
      top: 90px;
      bottom: 140px;
      min-height: 400px;
      border-radius: 10px;
      padding: 30px;

      .aq-app-content {
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
      }
    }
  }

  @media screen and (max-width: 677px) {
    .aq-app-root {
      padding: 0;

      .aq-app-panel {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 0;
        border-radius: 0;
      }
      .aq-bg-1,
      .aq-bg-2,
      .aq-bg-3 {
        display: none;
      }

      .welcome-title {
        display: block;
        line-height: 0px;
        margin-top: 10px;
        font-size: 0.8rem;
        font-family: "Rubik";
      }
    }
  }
`;

const Routes = [
  {
    path: `/projects/profile`,
    component: AqProfileComponent,
  },
  {
    path: `/projects/:id/company-info`,
    component: AqCompanyComponent,
  },
  {
    path: `/projects/:id/vessels/:vesselNo/search`,
    component: AqVesselSearchComponent,
  },
  {
    path: `/projects/:id/vessels/:vesselNo/opex`,
    component: AqVesselDetails,
  },
  {
    path: `/projects/:id/funding`,
    component: AqVesselFundindComponent,
  },
  {
    path: `/projects/:id/experience`,
    component: AqCompanyExperienceComponent,
  },
  {
    path: `/projects/:id/done`,
    component: AqDownloadPlanComponent,
  },
];

class AqDashboardComponent extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentRoute: props.location.pathname,
    };
  }

  static getDerivedStateFromProps(props: any) {
    return {
      currentRoute: props.location.pathname,
      header: props.header,
      page: props.page,
    };
  }

  /**
   * handle action click
   * @param action
   */
  handleActionClick = (action: any = {}) => {
    if (action.insert) {
      this.props.addVessel();
    }
  };

  onButonClick = (ev: any) => {
    console.log("buttonc clicked >>> ", ev);
  };

  getHeaderLabel = (label: string) => {
    return (
      <>
        <span className={"welcome-title"}>Welcome: </span>
        <span className={"welcome-content"}>{label}</span>
      </>
    );
  };

  /**
   * Returns header elm
   */
  getHeader = () => {
    const { header, page } = this.state;
    const { steps, actions } = header;
    const { vessels = [], companyInfo = {}, progress = 0 } = this.props;
    
    if (vessels && vessels.length && vessels.length < 100) {
      actions.push({ label: "+ Add another vessel", insert: true });
    }

    const headerLabel = companyInfo.companyName
      ? this.getHeaderLabel(companyInfo.companyName)
      : "We need few details about your company";
    // console.log("actions", actions, this.props);
    return (
      <AqHeaderComponent
        actions={page.showActions && actions}
        label={headerLabel}
        steps={steps}
        onStepChange={this.handleActionClick}
        progress={progress}
      />
    );
  };

  getRoutesComponent = () => {
    const { history, match } = this.props;
    const projectId = validObjectWithParameterKeys(match.params, ["id"])
      ? match.params.id
      : null;
    if (!projectId) {
      return history.push("/");
    }
    return Routes.map((route: any, i: number) => {
      const { component: Component, ...rest } = route;
      return (
        <Route
          {...rest}
          key={`dash-route${i}`}
          render={(props) => <Component {...props} />}
        />
      );
    });
  };

  render(): React.ReactNode {
    const { currentRoute } = this.state;
    const { error } = this.props;
    return (
      <AqPanelWrapper>
        <div className={"aq-app-root"}>
          <div className={"aq-bg-1"} />
          <div className={"aq-bg-2"} />
          <div className={"aq-bg-3"} />
          <div className={"aq-app-panel"}>
            <div className={"aq-app-content"}>
              {error && <div className={"aq-error-panel"}>{error.message}</div>}
              {currentRoute !== "/projects/profile" && this.getHeader()}
              <Switch>{this.getRoutesComponent()}</Switch>
            </div>
          </div>
        </div>
      </AqPanelWrapper>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { dashboard, root, done, vessel } = state;
  return {
    ...dashboard,
    error: root.error,
    vessels: done.vessels,
    progress: vessel.progress,
    companyInfo: root.companyInfo,
  };
};

const mapDispatchToProps = { ...dispatchers };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AqDashboardComponent);