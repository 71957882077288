import React, { FC, ReactElement, useState } from "react";
import styled from "styled-components";
import { EsfHelpForm } from "../../containers/leftpanel/help-form";
import { FontSize14, Line, Neutral, White } from "../../Styles";
import AqButtonComponent from "../seeds/aq-button.component";

interface IAqFooterProps {
  onButtonClick: any;
  progress?: number;
  disableContinue?: boolean;
}

const AqFooterWrapper = styled.div`
  .footer {
    position: fixed;
    bottom: 0;
    height: 127px;
    left: 0;
    right: 0;
    background: ${White};
    padding: 30px 0 20px 0;
    box-sizing: border-box;
    z-index: 1;

    .progress-bar {
      height: 2px;
      background: ${Line};
      position: relative;

      .progress-value {
        height: 4px;
        background: ${Neutral};
        transition: width 0.2s ease-in-out;
        top: -1px;
        position: relative;

        .progress-label {
          color: ${Neutral};
          font-size: ${FontSize14};
          position: absolute;
          right: 0;
          top: -18px;
        }
      }
    }

    .action-bar {
      display: flex;
      margin: 0 auto;
      justify-content: center;
      margin-top: 16px;
    }

    .footer-badge {
      cursor: pointer;
      padding: 16px 10px 17px 45px;
      border: 1px solid ${Line};
      border-radius: 6px;
      box-sizing: border-box;
      width: 186px;
      position: absolute;
      right: 120px;
      top: 44px;
      font-size: ${FontSize14};
      background-image: url(${process.env.PUBLIC_URL +
      "/icons/question-circle.png"});
      background-repeat: no-repeat;
      background-position: 14px 14px;
      text-align: left;
    }
  }

  @media screen and (max-width: 677px) {
    .footer {
      height: auto;
      padding: 15px;
      box-shadow: -1px -2px 9px #c7c7c7;

      .progress-bar {
        display: none;
      }

      .footer-badge {
        display: none;
      }

      .action-bar {
        margin: 0;
        justify-content: space-between;
      }
    }
  }
`;
export const AqFooter: FC<IAqFooterProps> = ({
  onButtonClick,
  progress,
  disableContinue,
}): ReactElement => {
  const [isHelpFormVisible, setIsHelpFormVisible] = useState(false);

  return (
    <AqFooterWrapper>
      <div className={"footer"}>
        {progress && (
          <div className={"progress-bar"}>
            <div className={"progress-value"} style={{ width: `${progress}%` }}>
              <span className={"progress-label"}>{progress}%</span>
            </div>
          </div>
        )}
        <div className={"action-bar"}>
          <AqButtonComponent
            style={{ marginRight: 20 }}
            className={"btn-primary invert medium"}
            label={"< Back"}
            onClick={() => onButtonClick("back")}
          />
          <AqButtonComponent
            disabled={disableContinue}
            className={"btn-primary medium"}
            label={"Continue >"}
            onClick={() => onButtonClick("continue")}
          />
        </div>
        <div
          className={"footer-badge"}
          onClick={() => setIsHelpFormVisible(true)}
        >
          <span className={"medium"}>Need help</span>
          <br />
          <span>Talk to our expert</span>
        </div>
      </div>
      {isHelpFormVisible && (
        <EsfHelpForm
          initialValues={{}}
          onClose={() => setIsHelpFormVisible(false)}
          onSend={() => setIsHelpFormVisible(false)}
        />
      )}
    </AqFooterWrapper>
  );
};
