const BaseUrl = process.env.REACT_APP_ESFAPI_BASEURL;

function getHeaders() {
  return new Headers({
    "Content-Type": "application/json",
    "api-version": "2",
    "Access-Control-Allow-Origin": "no-cors",
    Authorization: `${sessionStorage.getItem("Authorization")}`,
  });
}
function getHeaders1() {
  return new Headers({
    "api-version": "2",
    "Access-Control-Allow-Origin": "no-cors",
    Authorization: `${sessionStorage.getItem("Authorization")}`,
  });
}

// Get Projects List
export function getProjectlist(payload: any) {
  const endPoint = "api/projects";
  return fetch(`${BaseUrl}${endPoint}?${payload}`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

// User Info
export function getUserInfo() {
  const endPoint = "api/users/me";
  return fetch(`${BaseUrl}${endPoint}`, {
    method: "GET",
    headers: getHeaders(),
  });
}

export function setUserProfile(profile: string): Promise<Response> {
  return fetch(`${BaseUrl}api/users/me`, {
    method: "PATCH",
    headers: getHeaders(),
    body: JSON.stringify({ roles: [profile] }),
  });
}

// Get Company Info
export const getCompanyInfoHttp = async (projectId: string) => {
  const response = await fetch(`${BaseUrl}api/projects/${projectId}`, {
    method: "GET",
    headers: getHeaders(),
  });
  return response;
};

export function addCompanyHttp(payload: any) {
  const endPoint = "api/projects";
  return fetch(`${BaseUrl}${endPoint}`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  }).then((res) => res.json());
}

export const updateCompanyInfoHttp = async (payload: any) => {
  const response = await fetch(`${BaseUrl}api/projects/${payload.projectId}`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  });
  return response;
};

/**
 * Fetch vessel details
 * @param payload
 */
export function fetchVesselsHttp(payload: any) {
  const endPoint = `${BaseUrl}api/vessels/${payload}`;
  return fetch(endPoint, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => {
    if (res.status === 200) {
      return res.json();
    }
    return {
      error: res.statusText,
    };
  });
}

export const getProjectVesselsHttp = async (projectId: any) => {
  const response = await fetch(`${BaseUrl}api/projects/${projectId}/vessels`, {
    method: "GET",
    headers: getHeaders(),
  });
  return response;
};

export function addVesselsHttp(projectId: any, vesselNo: any, payload: any) {
  const { vesselForm, selectedVesselCard, purposeForm } = payload;
  const submitPayload: any = {
    loanPurpouse: purposeForm.loanPurpouse,
    declaredValue: purposeForm.declaredValue,
    vesselType: selectedVesselCard.label ? selectedVesselCard.label : "",
    vesselInfo: vesselForm,
  };
  if (vesselNo && vesselNo !== "new") {
    return updateVesselHttp(submitPayload, projectId, vesselNo);
  } else {
    const endPoint = `${BaseUrl}api/projects/${projectId}/vessels`;
    return fetch(endPoint, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(submitPayload),
    }).then((res) => res.json());
  }
}

export function deleteVesselHttp(vesselNo?: string | null) {
  const projectId = sessionStorage.getItem("__projectId");
  if (!vesselNo) {
    vesselNo = sessionStorage.getItem("__vesselNo");
  }
  const endPoint = `${BaseUrl}api/projects/${projectId}/vessels/${vesselNo}`;
  return fetch(endPoint, {
    method: "DELETE",
    headers: getHeaders(),
  }).then((res) => {
    if (res.status === 202) {
      return {
        message: "Vessel deleted successfully",
      };
    }
    return {
      error: res.statusText,
      message: "Something went wrong while deleting vessel",
    };
  });
}

export function updateVesselHttp(payload: any, projectId: any, vesselNo: any) {
  const endPoint = `${BaseUrl}api/projects/${projectId}/vessels/${vesselNo}`;
  return fetch(endPoint, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  });
}

export function getVesselHttp() {
  const projectId = sessionStorage.getItem("__projectId");
  const vesselNo = sessionStorage.getItem("__vesselNo");
  const endPoint = `${BaseUrl}api/projects/${projectId}/vessels/${vesselNo}`;
  return fetch(endPoint, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => {
    if (res.status === 200) {
      return res.json();
    }
    return {
      error: res.statusText,
    };
  });
}

export function getVesselOpexHttp(projectId: any, vesselNo: any) {
  const endPoint = `${BaseUrl}api/projects/${projectId}/vessels/${vesselNo}/opex`;
  return fetch(endPoint, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => {
    if (res.status === 200) {
      return res.json();
    }
    return {
      error: res.statusText,
    };
  });
}

export function addVesselOpexHttp(projectId: any, vesselNo: any, payload: any) {
  const submitPayload = {
    opexAmountPerDay: payload.opexAmount,
    commercialManagenentType: payload.managementType || "Inhouse",
    commercialManagenentFeeAmountPerDay:
      payload.managementType === "Outsourced" ? payload.opexFee : null,
    regulatoryImplementation: {
      date: payload.implementationDate, // "2021-05-24T19:59:46.883Z",
      budget: payload.implementationBudget,
    },
    intermediateSurveyDryDock: {
      date: payload.intermediateSurveyDate, // "2021-05-24T19:59:46.883Z",
      budget: payload.intermediateSurveyBudget,
    },
    specialSurveyDryDock: {
      date: payload.specialSurveyDate, // "2021-05-24T19:59:46.883Z",
      budget: payload.specialSurveyBudget,
    },
    charter: {
      type: payload.vesselEmployment,
      employmentStrategy: null,
      timeCharterEquivalentAmount: null,
    },
  };

  if (payload.vesselEmployment === "Estimate") {
    submitPayload.charter.employmentStrategy = payload.strategy;
    submitPayload.charter.timeCharterEquivalentAmount = payload.estimatedTime;
  }

  const endPoint = `${BaseUrl}api/projects/${projectId}/vessels/${vesselNo}/opex`;
  return fetch(endPoint, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(submitPayload),
  });
}

export function getFundingHttp(projectId: any) {
  const endPoint = `${BaseUrl}api/projects/${projectId}/funding-requirements`;
  return fetch(endPoint, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => {
    if (res) {
      return res.json();
    }
    return res;
  });
}

export async function addFundingHttp(payload: any) {
  const projectId = sessionStorage.getItem("__projectId");
  const endPoint = `${BaseUrl}api/projects/${projectId}/funding-requirements`;
  const res = await fetch(endPoint, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  });
  return res;
}

export function getExperienceHttp() {
  const projectId = sessionStorage.getItem("__projectId");
  const endPoint = `${BaseUrl}api/projects/${projectId}/experience`;
  return fetch(endPoint, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => {
    if (res) {
      return res.json();
    }
    return res;
  });
}

export function addCompanyExperienceHttp(payload: any) {
  /**
     * {
          "shipsOwnership": "string",
          "shipNumbers": {
            "dry": 0,
            "tank": 0,
            "cont": 0,
            "other": 0
          },
          "commercialManagement": {
            "isOwning": true,
            "name": "string"
          },
          "technicalManagement": {
            "isOwning": true,
            "name": "string"
          },
          "shoreBasedEmployeesNumber": 0,
          "crewNumber": 0
        }
     */
  const projectId = sessionStorage.getItem("__projectId");
  const endPoint = `${BaseUrl}api/projects/${projectId}/experience`;
  return fetch(endPoint, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  });
}

function fileStreamReader(res: any) {
  const reader = res.getReader();
  return new ReadableStream({
    start(controller) {
      // The following function handles each data chunk
      function push() {
        // "done" is a Boolean and value a "Uint8Array"
        reader.read().then(({ done, value }: any) => {
          // If there is no more data to read
          if (done) {
            controller.close();
            return;
          }
          // Get the data and send it to the browser via the controller
          controller.enqueue(value);
          push();
        });
      }

      push();
    },
  });
}

/**
 * Download business plan
 */
export function downloadBusinessPlanHttp(projectId: any) {
  // const projectId = sessionStorage.getItem("__projectId");
  const endPoint = `${BaseUrl}api/projects/${projectId}/documents/business-plan`;
  return fetch(endPoint, {
    method: "GET",
    headers: getHeaders(),
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Business-Plan-${projectId}.pdf`);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      document.body.removeChild(link);
      return {
        message: "",
      };
    });
}

/**
 * Download business plan
 */
export function downloadDocumentHttp(
  projectId: any,
  docId: any,
  fileName: string
) {
  // const projectId = sessionStorage.getItem("__projectId");
  const endPoint = `${BaseUrl}api/projects/${projectId}/documents/${docId}/content`;
  return fetch(endPoint, {
    method: "GET",
    headers: getHeaders(),
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      document.body.removeChild(link);
      return {
        message: "",
      };
    });
}

/**
 * add vessel employment
 * @return [
 {
    "employmentNo": 0,
    "employment": {
      "vesselEmployment": "string",
      "charterer": "string",
      "startDate": "2021-05-31T20:40:17.696Z",
      "endDate": "2021-05-31T20:40:17.696Z",
      "commissionPercent": 0,
      "timeCharterEquivalentPerDayRate": 0,
      "contract": {
        "id": "string",
        "fileName": "string"
      }
    }
  }
 ]
 */
export function getVesselEmploymentHttp() {
  const projectId = sessionStorage.getItem("__projectId");
  const vesselNo = sessionStorage.getItem("__vesselNo");
  const endPoint = `${BaseUrl}api/projects/${projectId}/vessels/${vesselNo}/employments`;
  return fetch(endPoint, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => {
    if (res) {
      return res.json();
    }
    return res;
  });
}

/**
 * add vessel employment
 * @param payload
 * {
      "vesselEmployment": "string",
      "charterer": "string",
      "startDate": "2021-05-31T20:39:42.775Z",
      "endDate": "2021-05-31T20:39:42.776Z",
      "commissionPercent": 0,
      "timeCharterEquivalentPerDayRate": 0
    }
 */
export function addVesselEmploymentHttp(payload: any, employmentNo?: string) {
  const projectId = sessionStorage.getItem("__projectId");
  const vesselNo = sessionStorage.getItem("__vesselNo");
  const endPoint = `${BaseUrl}api/projects/${projectId}/vessels/${vesselNo}/employments`;
  const submitPayload = {
    vesselEmployment: payload.vesselEmployment,
    charterer: payload.charterer,
    startDate: payload.startDate,
    endDate: payload.endDate,
    commissionPercent: payload.commissionPercent,
    timeCharterEquivalentPerDayRate: payload.timeCharterEquivalentPerDayRate,
  };

  if (employmentNo) {
    const updateEndPoint = `${BaseUrl}api/projects/${projectId}/vessels/${vesselNo}/employments/${employmentNo}`;
    return fetch(updateEndPoint, {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify(submitPayload),
    }).then((res) => {
      if (res.status === 202) {
        return { message: "updated" };
      }
      return { error: res.statusText };
    });
  } else {
    return fetch(endPoint, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(submitPayload),
    }).then((res) => {
      if (res) {
        return res.json();
      }
      return res;
    });
  }
}

export function deleteVesselEmploymentHttp(employmentNo: string) {
  const projectId = sessionStorage.getItem("__projectId");
  const vesselNo = sessionStorage.getItem("__vesselNo");
  const endPoint = `${BaseUrl}api/projects/${projectId}/vessels/${vesselNo}/employments/${employmentNo}`;
  return fetch(endPoint, {
    method: "DELETE",
    headers: getHeaders(),
  }).then((res) => {
    if (res.status === 202) {
      return { message: "deleted" };
    }
    return {
      error: res.statusText,
    };
  });
}

/**
 * Vessel lookup
 * @param text
 */
export function getVesselsHttp(text: string) {
  const endPoint = `${BaseUrl}api/lookups/imo-numbers?search=${text}`;
  return fetch(endPoint, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => {
    if (res) {
      return res.json();
    }
    return res;
  });
}

/**
 * fetch project's all vessels
 */
export function getProjectAllVesselsHttp(projectId: any) {
  const endPoint = `${BaseUrl}api/projects/${projectId}/vessels`;
  return fetch(endPoint, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => {
    if (res.status === 200) {
      return res.json();
    }
    return {
      error: res.statusText,
    };
  });
}

export function uploadSingleFile(file: any, subUrl: string) {
  const url = BaseUrl + subUrl;
  const formData = new FormData();
  formData.append("file", file, file.name);
  return fetch(url, {
    method: "PUT",
    headers: getHeaders1(),
    body: formData,
  }).then((res) => {
    console.log("uploaded file", res);
    if (res.status > 399) {
      return { status: false, message: res.statusText };
    }
    return { status: true, data: res };
  });
}

export function requestMeeting(payload: any, projectId: any) {
  const endPoint = `${BaseUrl}api/projects/${projectId}/meetings`;
  return fetch(endPoint, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.Message) {
        return { status: false, ...res };
      }
      return { status: true, ...res };
    });
}

// Get Booked Meeting List
export function getBookedMeeting(payload: any, projectId: any) {
  const endPoint = `${BaseUrl}api/projects/${projectId}/meetings`;
  return fetch(`${endPoint}?${payload}`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

// Existing Documents List
export function getExistingDoc(payload: any, projectId: any) {
  const endPoint = `${BaseUrl}api/projects/${projectId}/documents`;
  return fetch(`${endPoint}?${payload}`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

// Existing Documents Type List
export function geLookuptDocTypeList(projectId: any) {
  const endPoint = `${BaseUrl}api/lookups/document-types?projectId=${projectId}`;
  return fetch(endPoint, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export async function submitNewDocument(payload: any, projectId: any) {
  const endPoint = `${BaseUrl}api/projects/${projectId}/documents`;
  return await fetch(endPoint, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  }).then((res) => {
    if (res.status === 200) {
      return res.json();
    }
    return res.json();
  });
}
export async function editDocument(payload: any, projectId: any, docId: any) {
  const endPoint = `${BaseUrl}api/projects/${projectId}/documents/${docId}`;
  return await fetch(endPoint, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  }).then((res) => {
    if (res.status === 202) {
      return { status: true };
    }
    return { status: false };
  });
}

export async function deleteProjectDocument(projectId: any, docId: any) {
  const url = BaseUrl + `api/projects/${projectId}/documents/${docId}`;
  return await fetch(url, {
    method: "DELETE",
    headers: getHeaders(),
  }).then((res) => {
    if (res.status === 202) {
      return {
        status: true,
      };
    }
    return {
      status: false,
      message: "Something went wrong while deleting project Document",
    };
  });
}

export async function uploadDocumentFile(
  file: any,
  projectId: any,
  docId: any
) {
  const url = BaseUrl + `/api/projects/${projectId}/documents/${docId}/content`;
  const formData = new FormData();
  formData.append("files", file, file.name);
  return await fetch(url, {
    method: "PUT",
    headers: getHeaders1(),
    body: formData,
  }).then((res) => {
    if (res.status > 399) {
      return { status: false, message: res.statusText };
    }
    return { status: true, data: res };
  });
}

/**
 * Country Flags
 * @param text
 */

export function getCountryFlagsHttp(text: string) {
  const endPoint = `${BaseUrl}api/lookups/flags`;
  //const endPoint = `${BaseUrl}api/lookups/flags?search=${text}`;
  return fetch(endPoint, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => {
    if (res) {
      return res.json();
    }
    return res;
  });
}

export const askForHelpHttp = async (subject: string, body: string) => {
  const response = await fetch(`${BaseUrl}api/help`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ subject, body }),
  });
  return response;
};
