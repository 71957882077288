import styled, { css } from "styled-components";
import {
  // BtnWidth,
  // Dark,
  // DarkBlue,
  ErrorCode,
  FieldBottomMargin,
  FontFamily,
  FontSize12,
  FontSize14,
  H1Size,
  Light,
  Line,
  NegativeChange,
  // Neutral,
  Primary,
  // Secondary,
  SteelGrey,
  // TxtLineHeight,
  // TxtSize,
  // White,
} from "../../../Styles";

const Input = css`
  font-family: ${FontFamily};
  text-align: left;

  h1 {
    font-size: ${H1Size};
    font-style: normal;
    font-weight: normal;
    padding-bottom: 24px;
  }

  label {
    font-family: Rubik-Medium;
  }

  .input-field {
    position: relative;
    margin-bottom: ${FieldBottomMargin};
    display: flex;

    .error {
      color: ${ErrorCode};
      border: 2px solid ${ErrorCode};
      padding-top: 17px;
      padding-bottom: 17px;
    }

    .error-txt {
      color: ${NegativeChange};
      margin-top: 5px;
      text-align: left;
      position: absolute;
      bottom: -20px;
      font-size: ${FontSize12};

      &:before {
        background-image: url(${process.env.PUBLIC_URL +
        "/icons/error-circle.svg"});
        background-repeat: no-repeat;
        padding-right: 16px;
        content: " ";
      }
    }

    .footer-txt {
      font-family: "Rubik-Light";
      position: absolute;
      top: 60px;
      font-size: ${FontSize12};

      &:before {
        content: url(${process.env.PUBLIC_URL +
        "/icons/exclamation-circle.svg"});
      }
    }

    .input-left-txt {
      background-color: ${SteelGrey};
    }

    .input-right-txt {
      background-color: ${SteelGrey};
    }

    .input-right-txt {
      background-color: ${SteelGrey};
      text-align: center;
      position: absolute;
      right: 1px;
      top: 1px;
      bottom: 1px;
      width: 90px;
      padding: 18px 0;
      font-size: ${FontSize14};
      border: 1px solid ${SteelGrey};
      color: ${Light};
      border-left: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    span[class^="icon-"] {
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
    }
    span[class^="icon-r-"] {
      right: 5px;
      top: 23px;
      left: auto;
      text-align: center;
      cursor: pointer;
    }

    .icon-currency {
      background-image: url(${process.env.PUBLIC_URL + "/icons/currency.png"});
      background-position: center;
      background-repeat: no-repeat;
      transform: none !important;
      width: 38px !important;
      height: auto !important;
      left: 1px !important;
      bottom: 1px !important;
      top: 1px !important;
      background-color: ${SteelGrey};
      border: 1px solid ${SteelGrey};
      border-right: 0;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    .icon-user {
      background-image: url(${process.env.PUBLIC_URL + "/icons/user.png"});
    }
    .icon-mobile {
      background-image: url(${process.env.PUBLIC_URL + "/icons/mobile.png"});
    }
    .icon-lock {
      background-image: url(${process.env.PUBLIC_URL + "/icons/lock.png"});
    }
    .icon-date {
      background-image: url(${process.env.PUBLIC_URL + "/icons/date.png"});
    }
    .icon-r-eye {
      background-image: url(${process.env.PUBLIC_URL + "/icons/eye.png"});
    }
    .icon-r-close {
      cursor: pointer;
      font-size: 30px;
    }

    &.has-error {
      .input-right-txt {
        border: 1px solid ${ErrorCode};
        border-left: 0;
      }

      .icon-currency {
        border: 1px solid ${ErrorCode};
        border-right: 0;
      }
    }

    input,
    select,
    textarea {
      width: 100%;
      flex: 1;
      font-family: ${FontFamily};
      color: ${Light};
      padding: 18px 16px;
      font-size: 1em;
      font-style: normal;
      font-weight: normal;
      font-size: ${FontSize14};
      border: 1px solid ${Line};
      border-radius: 4px;
      height: 55px;
      box-sizing: border-box;

      &.has-icon {
        padding-left: 45px;
      }
      &.txt-right {
        text-align: right;
      }

      &.has-unitTxt {
        padding-right: 90px;
      }

      &:not(.error):focus {
        outline: none;
        border: 1px solid ${Primary};
      }
      &.error:focus {
        outline: none;
        border: 2px solid ${ErrorCode};
      }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    input[type="checkbox"] {
      margin-top: 7px;
    }

    // input-field ends here

    //map dropdown style
    .autocomplete-dropdown-container {
      width: 100%;
      position: absolute;
      bottom: 0;
      top: 100%;
      z-index: 2;
      display: none;
      > div {
        padding: 5px;
      }
      &.showinglist {
        display: block;
        & > .dropdown {
          border: 1px solid #ccc;
          padding: 5px;
          background: #fff;
          box-sizing: border-box;

          .suggestion-item,
          .suggestion-item:hover,
          .suggestion-item--active {
            padding: 5px;
            box-sizing: border-box;
          }
        }
      }
    }
  }
`;

export default styled.div`
  ${Input}
`;
