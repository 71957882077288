import React, { PureComponent } from "react";
import styled from "styled-components";
import { strictValidArray } from "../../controller/utils/commonUtils";
import {
  ErrorCode,
  FontFamily,
  FontSize12,
  FontSize14,
  Light,
  Line,
  NegativeChange,
  Primary,
} from "../../Styles";

const SelectContainer = styled.div`
  text-align: left;

  .input-field {
    position: relative;
    margin-bottom: 22px;

    .error {
      color: ${ErrorCode};
      border: 2px solid ${ErrorCode};
      padding-top: 17px;
      padding-bottom: 17px;
    }

    .error-txt {
      color: ${NegativeChange};
      margin-top: 5px;
      text-align: left;
      position: absolute;
      bottom: -20px;
      font-size: ${FontSize12};

      &:before {
        background-image: url(${process.env.PUBLIC_URL +
        "/icons/error-circle.svg"});
        background-repeat: no-repeat;
        padding-right: 16px;
        content: " ";
      }
    }

    select {
      width: 100%;
      flex: 1;
      font-family: ${FontFamily};
      color: ${Light};
      padding: 18px 16px;
      font-size: 1em;
      font-style: normal;
      font-weight: normal;
      font-size: ${FontSize14};
      border: 1px solid ${Line};
      border-radius: 3px;
      appearance: none;
      background-image: url(${process.env.PUBLIC_URL +
      "/icons/arrow-down.png"});
      background-repeat: no-repeat;
      background-position: calc(100% - 10px) 50%;

      &:not(.error):focus {
        outline: none;
        border: 1px solid ${Primary};
      }
      &.error:focus {
        outline: none;
        border: 2px solid ${ErrorCode};
      }
    }
    label {
      font-family: Rubik-Medium;
      line-height: 25px;
      text-transform: capitalize;
    }
  }
`;

interface ISelectOptions {
  value: any;
  label: string;
  selected?: boolean;
}

interface AqSelectProps {
  label?: string;
  value?: any;
  name?: string;
  id?: string;
  error?: any;
  defaultValue?: string;
  options: Array<ISelectOptions>;
  onChange: any;
  className?: string;
}

/**
 * Aquaman custom select component
 */
export default class AqSelectComponent extends PureComponent<
  AqSelectProps,
  any
> {
  selectedValue = "";
  constructor(props: AqSelectProps) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  static getDerivedStateFromProps(props: AqSelectProps) {
    // console.log("valueinput", props);
    return { value: props.value };
  }

  /**
   * Returns select options
   */
  getOptions = () => {
    const { options } = this.props;
    return (
      strictValidArray(options) &&
      options.map((option: ISelectOptions) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))
    );
  };

  componentDidMount(): void {
    const { value } = this.props;
    if (value) {
      this.selectedValue = value;
      this.setState({ value });
    }
  }

  /**
   * Returns the class name based on the icon value
   */
  getClassNames = () => {
    const { error } = this.props;
    const clasess = {
      wrapper: "input-field",
      input: "",
    };
    if (error) {
      clasess.input += " error";
      clasess.wrapper += " has-error";
    }
    return clasess;
  };

  componentWillUpdate(
    nextProps: Readonly<AqSelectProps>,
    nextState: Readonly<any>,
    nextContext: any
  ): void {
    const value = nextProps.value;
    if (value) {
      this.selectedValue = value;
    }
  }

  handleChange = (ev: any) => {
    this.selectedValue = ev.target.value;
    this.setState({ value: ev.target.value });
    this.props.onChange(
      this.props.name,
      ev.target.value === "__Default" ? undefined : ev.target.value
    );
  };

  /**
   * Default render fn
   */
  render() {
    const {
      name,
      id,
      label,
      defaultValue = "Select value...",
      error,
      className,
    } = this.props;
    const cls = this.getClassNames();
    return (
      <SelectContainer className={className}>
        {label && <label htmlFor={name}>{label}</label>}
        <div className={cls.wrapper}>
          <select
            className={cls.input}
            name={name}
            id={id}
            onChange={this.handleChange}
            value={this.state.value}
          >
            <option value={"__Default"}>{defaultValue}</option>
            {this.getOptions()}
          </select>
          {error && <div className={"error-txt"}>{error.error}</div>}
        </div>
      </SelectContainer>
    );
  }
}
